import {
  Col,
  Dropdown,
  Menu,
  notification,
  Pagination,
  Row,
  Spin,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useMemo, useState } from "react";
import { DataListCampaignTable } from "../redux/apiTypes";
import UpdateCampaign from "./UpdateCampaign";
import { useUpdateStatusMutation } from "../redux/recheckCampaignApi";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { ReactComponent as Manipulation } from "../../../assets/logos/action.svg";
import { checkPermission } from "../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";

type Props = {
  total: number;
  isLoading: boolean;
  filter: {
    campaignName?: string;
    description?: string;
    page: number;
    size: number;
  };
  onChangeTable: (pageNum?: number, pageSize?: number) => void;
  dataSource: DataListCampaignTable[];
  refetch: () => void;
};

const STATUS = {
  ACTIVE: 0,
  INACTIVE: 1,
  EXPRIRE: 2,
};

const RecheckCompaignList = ({
  total,
  isLoading,
  filter,
  onChangeTable,
  dataSource,
  refetch,
}: Props) => {
  const [updateCampaignVisible, setUpdateCampaignVisible] = useState<{
    isOpen: boolean;
    key: string;
  }>({
    isOpen: false,
    key: "",
  });

  const canEdit = checkPermission(
    PERMISSION_KEYS.CHIEN_DICH_DOI_CHIEU.CHINH_SUA
  );

  const [currentCampaign, setCurrentCampaign] =
    useState<DataListCampaignTable>();

  const [triggerStatusCampaign, { isLoading: isLoadingUpdate }] =
    useUpdateStatusMutation();

  const currentPageNum = useMemo(() => {
    return filter?.page + 1;
  }, [filter?.page]);

  const currentPageSize = useMemo(() => {
    return filter?.size;
  }, [filter?.size]);

  const currentDataSource = useMemo(() => {
    return dataSource;
  }, [dataSource]);

  const totals = useMemo(() => {
    return total;
  }, [total]);

  const StatusElement = ({ value }: { value: number }) => {
    const statusStyle = {
      padding: "4px",
      borderRadius: "4px",
    };
    const STATUS = {
      ACTIVE: 0,
      INACTIVE: 1,
    };

    const statusMapping = {
      [STATUS.INACTIVE]: {
        color: "#E11D48",
        text: "Không hoạt động",
        bg: "#FFE4E6",
      },
      [STATUS.ACTIVE]: {
        color: "green",
        text: "Hoạt động",
        bg: "#CCFBF1",
      },
    };
    const status = statusMapping[value] || { color: "gray", text: "Unknown" };

    return (
      <span
        style={{
          ...statusStyle,
          color: status.color,
          backgroundColor: status.bg,
        }}
      >
        {status.text}
      </span>
    );
  };

  const onClickItem = (item: DataListCampaignTable, key: string) => {
    // console.log("item", item, key);
    setCurrentCampaign(item);
    setUpdateCampaignVisible({
      isOpen: true,
      key: key,
    });
  };

  const getContent = (value: number, record: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        {canEdit && (
          <Menu>
            <Menu.Item key="activate" onClick={() => onClickItem(record, "1")}>
              {value === STATUS.ACTIVE ? (
                <>
                  <LockOutlined style={{ marginRight: "4px" }} />
                  Khóa
                </>
              ) : (
                <>
                  <UnlockOutlined style={{ marginRight: "4px" }} />
                  Kích hoạt
                </>
              )}
            </Menu.Item>
          </Menu>
        )}
      </div>
    );
  };

  const columns: ColumnsType<DataListCampaignTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, item, index) =>
        (currentPageNum - 1) * currentPageSize + index + 1,
    },
    {
      title: "Thao tác",
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (value, record) => {
        return (
          <Dropdown placement="bottomLeft" overlay={getContent(value, record)}>
            <Manipulation
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: "pointer" }}
            />
          </Dropdown>
        );
      },
    },
    {
      title: "Tên chiến dịch",
      dataIndex: "campaignName",
      key: "campaignName",
      align: "left",
      render: (value, item, index) => (
        <div style={{ color: "#1890ff", cursor: "pointer" }}>{value}</div>
      ),
    },
    {
      title: "Số lượng THHT",
      dataIndex: "totalTHHT",
      key: "totalTHHT",
      align: "center",
      render: (value, record) => (
        <div>{record.totalTHHTComplete + "/" + value}</div>
      ),
    },
    {
      title: "Phần trăm hoàn thành",
      dataIndex: "percentComplete",
      key: "percentComplete",
      align: "center",
      render: (value) => <div>{value}%</div>,
    },
    {
      title: "Nhóm người dùng",
      dataIndex: "listGroupUsers",
      key: "listGroupUsers",
      align: "left",
      render: (value) => <div>{value}</div>,
    },
    {
      title: "Trạng thái",
      key: "status",
      dataIndex: "status",
      render: (value) => <StatusElement value={value} />,
      align: "center",
    },
    {
      title: "Mô tả",
      key: "description",
      dataIndex: "description",
      render: (value) => <div>{value}</div>,
      align: "left",
    },
    {
      title: "Ngày tạo",
      key: "createdDateStr",
      dataIndex: "createdDateStr",
      align: "left",
      render: (value) => <div>{value}</div>,
    },
  ];

  const changePage = (currentPage: number, pageSize: number) => {
    onChangeTable(pageSize === currentPageSize ? currentPage : 1, pageSize);
  };

  const resetState = () => {
    setCurrentCampaign(undefined);
    setUpdateCampaignVisible({
      isOpen: false,
      key: "",
    });
  };

  const handleActivate = () => {
    if (!canEdit) return;
    triggerStatusCampaign({
      id: currentCampaign?.id,
      status:
        currentCampaign?.status === STATUS.INACTIVE
          ? STATUS.ACTIVE
          : STATUS.INACTIVE,
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: `${
            currentCampaign?.status === STATUS.ACTIVE ? "Khóa" : "Kích hoạt"
          } chiến dịch thành công!`,
        });
        resetState();
        refetch();
      })
      .catch(() => {
        notification.error({ message: "Có lỗi xảy ra!" });
      });
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <Col span={6}>
          <div>
            Tổng số: <strong>{totals}</strong> bản ghi
          </div>
        </Col>
      </Row>

      <Table<DataListCampaignTable>
        loading={isLoading}
        dataSource={currentDataSource}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
        rowKey="id"
        onRow={(record) => {
          return {
            onClick: () => onClickItem(record, "2"),
          };
        }}
      />

      <Spin spinning={isLoading} tip="Đang tải...">
        <Pagination
          total={totals}
          defaultPageSize={10}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={currentPageNum}
        />
      </Spin>

      <UpdateCampaign
        visible={
          updateCampaignVisible.isOpen && updateCampaignVisible.key === "2"
        }
        onClose={resetState}
        campaign={currentCampaign}
        resetState={resetState}
      />

      {canEdit && (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Đóng"
          okText="Đồng ý"
          showCancel={true}
          showOk={true}
          type={
            currentCampaign?.status === STATUS.ACTIVE ? "warning" : "success"
          }
          centered
          title={`${
            currentCampaign?.status === STATUS.ACTIVE ? "Khóa" : "Kích hoạt"
          } chiến dịch`}
          content={`Bạn có chắc chắn muốn ${
            currentCampaign?.status === STATUS.ACTIVE ? "khóa" : "kích hoạt"
          } chiến dịch này hay không?`}
          visible={
            updateCampaignVisible.isOpen && updateCampaignVisible.key === "1"
          }
          onOk={handleActivate}
          loadingBtnOk={isLoadingUpdate}
        />
      )}
    </div>
  );
};

export default RecheckCompaignList;
