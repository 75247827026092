import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isTokenExpired } from "../modules/common/assets/CommonFunctions";
import { logout, setUser } from "../modules/login/redux/UserSlice";
import { useLazyGetUserInfoQuery } from "../redux/api/AccountApi";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";

function PrivateRoute({
  children,
  hasPermissions,
}: {
  children: JSX.Element;
  hasPermissions?: boolean;
}) {
  const isLogin = !isTokenExpired(localStorage.getItem("accessToken") || "");
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const getProfile = useRef(false);

  let location = useLocation();

  const [trigger] = useLazyGetUserInfoQuery();

  useEffect(() => {
    // if (!Object.keys(user.user)?.length && isLogin) {
    if (
      isLogin &&
      !getProfile.current &&
      user.user &&
      Object.keys(user.user).length
    ) {
      getProfile.current = true;
      // trigger({}).unwrap().then(res => {
      //     dispatch(setUser(res))
      // })
      dispatch(setUser(user.user));
    } 
    // else if (!isLogin) {
    //   dispatch(setUser({}));
    // }
  }, [user.user, isLogin, trigger, dispatch]);

  const [isIdle, setIsIdle] = useState(false);
  const idleTimeoutMs = 60000 * 30; // 30p

  useEffect(() => {
    let idleTimer: ReturnType<typeof setTimeout>;

    const activityEvents = [
      "click",
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
    ];

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      setIsIdle(false);
      idleTimer = setTimeout(() => {
        setIsIdle(true);
      }, idleTimeoutMs);
    };

    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, resetIdleTimer);
    });

    resetIdleTimer();

    return () => {
      activityEvents.forEach(function (eventName) {
        document.removeEventListener(eventName, resetIdleTimer);
      });
      clearTimeout(idleTimer);
    };
  }, [idleTimeoutMs]);

  if (isIdle && isLogin) {
    dispatch(logout());
  }

  if (!isLogin) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  if (isLogin && hasPermissions === false && user.user?.username !== "admin") {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;
