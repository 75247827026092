export const STATUS = {
  DEACTIVE: 0,
  ACTIVE: 1,
};

export const STATUS_ENTRY_CAMPAIGN = {
  ACTIVE: 0,
  DEACTIVE: 1,
};

export const STATUS_LABEL: Record<number, string> = {
  0: "Không hoạt động",
  1: "Hoạt động",
};

export const TYPE_DATA: Record<string, string> = {
  KS: "Khai sinh",
  KT: "Khai tử",
  KH: "Kết hôn",
  HN: "Xác nhận hôn nhân",
  CMC: "Cha mẹ con",
  CC: "Cải chính hộ tịch",
  CN: "Nhận con nuôi ",
  GH: "Giám hộ",
  LH: "Ly Hôn",
};

export const TYPE_ACTION: Record<string, string> = {
  // "1": "Nhập liệu",
  "2": "Tự chỉnh sửa",
  "3": "Kiểm tra dữ liệu",
  "5": "Chỉnh sửa bổ sung",
};

export const DEFAULT_FIELD_CONFIG: Record<string, any> = {
  tinhThanh: "tqni",
  noiDangKy: "noiDangKy",
  noiCap: "noiCap",
  HN: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
    nxnNgaySinh: "nxnNgaySinh",
  },
  CC: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "hoSoSo",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
  },
  KS: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    loaiDangKy: "loaiDangKy",
    nksNgaySinh: "nksNgaySinh",
    tenfilepdf: "tenfilepdf",
  },
  CMC: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
  },
  CN: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
  },
  GH: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
  },
  KH: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
  },
  KT: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    loaiDangKy: "loaiDangKy",
    nktNgayChet: "nktNgayChet",
    tenfilepdf: "tenfilepdf",
  },
  LH: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "hoSoSo",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
  },
  CDGH: {
    tinhThanh: "tinhThanh",
    quanHuyen: "quanHuyen",
    phuongXa: "phuongXa",
    so: "so",
    quyenSo: "quyenSo",
    ngayDangKy: "ngayDangKy",
    tenfilepdf: "tenfilepdf",
  },
};

export const TYPE_FIELD = [
  {
    id: "string",
    name: "Ký tự",
  },
  {
    id: "number",
    name: "Số",
  },
  // {
  //     id: "boolean",
  //     name: "True/False"
  // }
];

// Type file pdf
export const TYPE_FILE_PDF: Record<string, string> = {
  A4: "A4", // > 2005, < 1976: KS
  A3: "A3", // Từ 1976 - 2005: 1976-1998: A3 theo chiều dọc, 1999 - 2005: A3 theo chiều ngang
};

// Danh sách nơi cấp UBND của tỉnh quảng ngãi
export const QN_UBND: Record<string, string> = {
  // "qn-thanhphoquangngai-sotuphap": "UBND Tỉnh Quảng Ngãi",
  // "qn-thanhphoquangngai-phongtuphap": "UBND thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongchanhlo": "UBND phường Chánh Lộ, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuonglehongphong": "UBND phường Lê Hồng Phong, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongnghiachanh": "UBND phường Nghĩa Chánh, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongnghialo": "UBND phường Nghĩa Lộ, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongnguyennghiem": "UBND phường Nguyễn Nghiêm, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongquangphu": "UBND phường Quảng Phú, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongtranhungdao": "UBND phường Trần Hưng Đạo, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongtranphu": "UBND phường Trần Phú, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-phuongtruongquangtrong": "UBND phường Trương Quang Trọng, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xanghiaan": "UBND xã Nghĩa An, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xanghiadong": "UBND xã Nghĩa Dõng, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xanghiadung": "UBND xã Nghĩa Dũng, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xanghiaha": "UBND xã Nghĩa Hà, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xanghiaphu": "UBND xã Nghĩa Phú, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhandong": "UBND xã Tịnh Ấn Đông, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhantay": "UBND xã Tịnh Ấn Tây, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhan": "UBND xã Tịnh An, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhchau": "UBND xã Tịnh Châu, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhhoa": "UBND xã Tịnh Hòa, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhkhe": "UBND xã Tịnh Khê, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhky": "UBND xã Tịnh Kỳ, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhlong": "UBND xã Tịnh Long, thành phố Quảng Ngãi",
  // "qn-thanhphoquangngai-xatinhthien": "UBND xã Tịnh Thiện, thành phố Quảng Ngãi",

  "dn-qcl-phongtuphap": "UBND quận Cẩm Lệ, Thành phố Đà Nẵng",
  "dn-qcl-phuonghoaan": "UBND phường Hoà An, quận Cẩm Lệ, Thành phố Đà Nẵng",
  "dn-qcl-phuonghoaphat":
    "UBND phường Hòa Phát, quận Cẩm Lệ, Thành phố Đà Nẵng",
  "dn-qcl-phuonghoathodong":
    "UBND phường Hòa Thọ Đông, quận Cẩm Lệ, Thành phố Đà Nẵng",
  "dn-qcl-phuonghoathotay":
    "UBND phường Hòa Thọ Tây, quận Cẩm Lệ, Thành phố Đà Nẵng",
  "dn-qcl-phuonghoaxuan":
    "UBND phường Hòa Xuân, quận Cẩm Lệ, Thành phố Đà Nẵng",
  "dn-qcl-phuongkhuetrung":
    "UBND phường Khuê Trung, quận Cẩm Lệ, Thành phố Đà Nẵng",

  "dn-qhc-phongtuphap": "UBND quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuongbinhhien":
    "UBND phường Bình Hiên, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuongbinhthuan":
    "UBND phường Bình Thuận, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuonghaichau1":
    "UBND phường Hải Châu I, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuonghaichau2":
    "UBND phường Hải Châu II, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuonghoacuongbac":
    "UBND phường Hòa Cường Bắc, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuonghoacuongnam":
    "UBND phường Hòa Cường Nam, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuonghoathodong":
    "UBND phường Hòa Thuận Đông, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuonghoathuantay":
    "UBND phường Hòa Thuận Tây, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuongnamduong":
    "UBND phường Nam Dương, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuongphuocninh":
    "UBND phường Phước Ninh, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuongthachthang":
    "UBND phường Thạch Thang, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuongthanhbinh":
    "UBND phường Thanh Bình, quận Hải Châu, Thành phố Đà Nẵng",
  "dn-qhc-phuongthuanphuoc":
    "UBND phường Thuận Phước, quận Hải Châu, Thành phố Đà Nẵng",

  "dn-qlc-phongtuphap": "UBND quận Liên Chiểu, Thành phố Đà Nẵng",
  "dn-qlc-phuonghoahiepbac":
    "UBND phường Hòa Hiệp Bắc, quận Liên Chiểu, Thành phố Đà Nẵng",
  "dn-qlc-phuonghoahiepnam":
    "UBND phường Hòa Hiệp Nam, quận Liên Chiểu, Thành phố Đà Nẵng",
  "dn-qlc-phuonghoakhanhbac":
    "UBND phường Hòa Khánh Bắc, quận Liên Chiểu, Thành phố Đà Nẵng",
  "dn-qlc-phuonghoakhanhnam":
    "UBND phường Hòa Khánh Nam, quận Liên Chiểu, Thành phố Đà Nẵng",
  "dn-qlc-phuonghoaminh":
    "UBND phường Hòa Minh, quận Liên Chiểu, Thành phố Đà Nẵng",

  "dn-qnhs-phongtuphap": "UBND quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
  "dn-qnhs-phuonghoahai":
    "UBND phường Hòa Hải, quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
  "dn-qnhs-phuonghoaquy":
    "UBND phường Hòa Quý, quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
  "dn-qnhs-phuongkhuemy":
    "UBND phường Khuê Mỹ, quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
  "dn-qnhs-phuongmyan":
    "UBND phường Mỹ An, quận Ngũ Hành Sơn, Thành phố Đà Nẵng",

  "dn-qst-phongtuphap": "UBND quận Sơn Trà, Thành phố Đà Nẵng",
  "dn-qst-phuonganhaibac":
    "UBND phường An Hải Bắc, quận Sơn Trà, Thành phố Đà Nẵng",
  "dn-qst-phuonganhaidong":
    "UBND phường An Hải Đông, quận Sơn Trà, Thành phố Đà Nẵng",
  "dn-qst-phuonganhaitay":
    "UBND phường An Hải Tây, quận Sơn Trà, Thành phố Đà Nẵng",
  "dn-qst-phuongmanthai":
    "UBND phường Mân Thái, quận Sơn Trà, Thành phố Đà Nẵng",
  "dn-qst-phuongnaihiendong":
    "UBND phường Nại Hiên Đông, quận Sơn Trà, Thành phố Đà Nẵng",
  "dn-qst-phuongphuocmy":
    "UBND phường Phước Mỹ, quận Sơn Trà, Thành phố Đà Nẵng",
  "dn-qst-phuongthoquang":
    "UBND phường Thọ Quang, quận Sơn Trà, Thành phố Đà Nẵng",

  "dn-qtk-phongtuphap": "UBND quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongankhe": "UBND phường An Khê, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongchinhgian":
    "UBND phường Chính Gián, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuonghoakhe":
    "UBND phường Hòa Khê, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongtamthuan":
    "UBND phường Tam Thuận, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongtanchinh":
    "UBND phường Tân Chính, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongthacgian":
    "UBND phường Thạc Gián, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongthanhkhedong":
    "UBND phường Thanh Khê Đông, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongthanhkhetay":
    "UBND phường Thanh Khê Tây, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongvinhtrung":
    "UBND phường Vĩnh Trung, quận Thanh Khê, Thành phố Đà Nẵng",
  "dn-qtk-phuongxuanha":
    "UBND phường Xuân Hà, quận Thanh Khê, Thành phố Đà Nẵng",

  "dn-hhv-phongtuphap": "UBND huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoaphong": "UBND xã Hòa Phong, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoabac": "UBND xã Hòa Bắc, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoachau": "UBND xã Hòa Châu, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoakhuong": "UBND xã Hòa Khương, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoalien": "UBND xã Hòa Liên, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoanhon": "UBND xã Hòa Nhơn, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoaninh": "UBND xã Hòa Ninh, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoaphu": "UBND xã Hòa Phú, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoaphuoc": "UBND xã Hòa Phước, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoason": "UBND xã Hòa Sơn, huyện Hòa Vang, Thành phố Đà Nẵng",
  "dn-hhv-xahoatien": "UBND xã Hòa Tiến, huyện Hòa Vang, Thành phố Đà Nẵng",
};

// Danh sách UBND của Thành phố Đà Nẵng
export const DN_UBND: Record<string, string> = {};

export const NUMBER_WORD: Record<number, string> = {
  0: "không",
  1: "một",
  2: "hai",
  3: "ba",
  4: "bốn",
  5: "năm",
  6: "sáu",
  7: "bảy",
  8: "tám",
  9: "chín",
  10: "mười",
  11: "mười một",
  12: "mười hai",
};

export const NUMBER_WORD_UNIT: Record<number, string> = {
  1: "mốt",
  2: "hai",
  3: "ba",
  4: "bốn",
  5: "lăm",
  6: "sáu",
  7: "bảy",
  8: "tám",
  9: "chín",
  10: "mười",
  11: "mười một",
  12: "mười hai",
};

export const MAX_LENGTH: Record<string, number> = {
  nguoiKy: 125,
  nguoiThucHien: 250,
  ghiChu: 300,
  cmHoTen: 125,
  cmQueQuan: 50,
  cmNoiCuTru: 125,
  cmGiayToKhac: 250,
  cmNoiCapGiayToTuyThan: 250,
  ncHoTen: 125,
  ncQueQuan: 50,
  ncNoiCuTru: 125,
  ncNoiCapGiayToTuyThan: 250,
  nycHoTen: 125,
  nycQHNguoiDuocNhan: 250,
  nycQHNguoiNhan: 250,
  nycGiayToKhac: 250,
  nycNoiCapGiayToTuyThan: 250,
  nxnHoTen: 125,
  nxnNoiCuTru: 125,
  nxnGiayToKhac: 250,
  nxnNoiCapGiayToTuyThan: 250,
  nxnThoiGianCuTruTai: 125,
  nxnTinhTrangHonNhan: 1000,
  nxnMucDichSuDung: 1000,
  nycQuanHe: 250,
  chongHoTen: 125,
  chongNoiCuTru: 125,
  chongGiayToKhac: 250,
  chongNoiCapGiayToTuyThan: 250,
  voHoTen: 125,
  voNoiCuTru: 125,
  voGiayToKhac: 250,
  voNoiCapGiayToTuyThan: 250,
  nktHoTen: 125,
  nktNoiCuTru: 125,
  nktNoiChet: 1000,
  nktNguyenNhanChet: 1000,
  gbtCoQuanCap: 250,
  nksSoDinhDanh: 12,
  nksHoTen: 125,
  nksNoiSinh: 125,
  nksQueQuan: 125,
  meSoDinhDanhCaNhan: 12,
  meHoTen: 125,
  meNoiCuTru: 1000,
  chaSoDinhDanhCaNhan: 12,
  chaHoTen: 125,
  chaNoiCuTru: 1000,
};

export const USER_ROLES = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  MEMBER: "MEMBER"
}
