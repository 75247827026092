import { Button, Form, Modal, Progress, Select } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import axios, { AxiosRequestConfig } from "axios";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Commune, District } from "../../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
} from "../../../redux/api/commonApi";
import { useGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import { ReactComponent as UploadIcon } from "../assets/img/upload.svg";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

interface Props {
  visible: boolean;
  handleClose: () => void;
}

const UploadPdf = ({ visible, handleClose }: Props) => {
  const [form] = Form.useForm();
  const { data: provinces = [] } = useGetAllProvinceNoAuthQuery({});
  const [districtTrigger, districts] = useLazyGetDistrictQuery();
  const [communeTrigger] = useLazyGetCommuneQuery();

  const [districtList, setDistrictList] = useState<District[]>([]);
  const [communeList, setCommuneList] = useState<Commune[]>([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<Record<string, number>>({});
  const [successFiles, setSuccessFiles] = useState<string[]>([]);
  const [errorFiles, setErrorFiles] = useState<Record<string, string>>({});
  const [fileCount, setFileCount] = useState<number>(0);

  // flag để clear file list khi muốn upload list mới
  // sau khi đã upload list trước lên server
  const shouldClearFileList = useRef<string[]>([]);

  const uploadFileList: UploadFile[] = Form.useWatch("files", form);

  // Danh sách file lỗi hoặc đang upload
  const uploadingList = useMemo(() => {
    return (
      uploadFileList?.filter((item) => !successFiles.includes(item.uid)) || []
    );
  }, [successFiles, uploadFileList]);

  // Danh achs file upload thành công
  const uploadSuccessList = useMemo(() => {
    return (
      uploadFileList?.filter((item) => successFiles.includes(item.uid)) || []
    );
  }, [successFiles, uploadFileList]);

  const onValuesChange = (values: any) => {
    if (Object.keys(values)[0] === "province" && values?.province) {
      setDistrictList([]);
      setCommuneList([]);

      let provinceId = provinces?.find(
        (item) => item.provinceCode === values?.province
      )?.id;

      if (provinceId) {
        districtTrigger(provinceId)
          .unwrap()
          .then((res) => setDistrictList(res));
      }
    } else if (Object.keys(values)[0] === "district" && values?.district) {
      setCommuneList([]);

      let districtId = districts?.data?.find(
        (item) => item.districtCode === values?.district
      )?.id;

      if (districtId) {
        communeTrigger(districtId)
          .unwrap()
          .then((res) => setCommuneList(res));
      }
    }
  };

  const normFile = (e: any) => {
    // Reset state
    setUploading(false);
    setErrorFiles({});
    setSuccessFiles([]);
    setFileCount(0);
    setProgress({});

    if (Array.isArray(e)) {
      return e?.filter(
        (item) =>
          !item?.uid || !shouldClearFileList?.current?.includes(item.uid)
      );
    }

    return e?.fileList?.filter(
      (item: any) =>
        !item?.uid || !shouldClearFileList?.current?.includes(item.uid)
    );
  };

  const validateFile = (file: UploadFile) => {
    if (!file) {
      return "File không hợp lệ";
    }

    if (file.type !== "application/pdf") {
      return "File đã chọn không đúng định dạng cho phép (*.pdf)";
    }

    if (file?.size && file.size > 5 * 1024 * 1024) {
      return "File đã chọn vượt quá 5MB";
    }

    let pattern =
      /[a-zA-z]{2,3}\.[0-9]{4}\.[0-9]{1,}\.[0-9]{5}_[0-9]{1,}\.pdf/g;
    if (!pattern.test(file.name)) {
      return "Tên file sai định dạng";
    }

    return "";
  };

  const itemRender = useCallback(
    (file: UploadFile) => {
      if (!file) {
        return <></>;
      }

      let borderColor = "#E3E3E3";

      const error = !uploading ? validateFile(file) : "";
      const currentFileList: UploadFile[] = form.getFieldValue("files");

      if (error || Object.keys(errorFiles)?.includes(file.uid)) {
        // Update status file lỗi
        let errorItemIndex = currentFileList?.findIndex(
          (item) => item.uid === file.uid
        );

        if (errorItemIndex !== -1) {
          let newFileList = [...currentFileList];
          newFileList[errorItemIndex] = {
            ...newFileList[errorItemIndex],
            status: "error",
          };

          form.setFieldValue("files", newFileList);
        }

        // Đổi border sang đỏ
        borderColor = "#E41D1D";
      }

      if (successFiles?.includes(file.uid)) {
        // Đổi border sang xanh nếu upload thành công lên server
        borderColor = "#11AF22";
      }

      const newDiv = (
        <React.Fragment key={file.uid}>
          <div
            className="upload-file-item"
            style={{
              border: `1px solid ${borderColor}`,
            }}
          >
            <div style={{ color: "black" }}>{file.name}</div>
            {!uploading && (
              <DeleteIcon
                height={30}
                width={30}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  form.setFieldValue(
                    "files",
                    currentFileList?.filter((item) => item.uid !== file.uid)
                  );
                }}
              />
            )}

            {uploading && Object.keys(errorFiles)?.includes(file.uid) && (
              <CloseCircleFilled style={{ fontSize: 17, color: "#E41D1D" }} />
            )}

            {uploading && successFiles?.includes(file.uid) && (
              <CheckCircleFilled style={{ fontSize: 17, color: "#E41D1D" }} />
            )}
            {progress?.[file.uid] &&
            progress?.[file.uid] !== 100 &&
            progress?.[file.uid] > 0 ? (
              <Progress
                percent={50}
                strokeColor="#2563EB"
                showInfo={false}
                strokeWidth={4}
                className="upload-progress"
              />
            ) : (
              <></>
            )}
          </div>
          {error || Object.keys(errorFiles)?.includes(file.uid) ? (
            <div style={{ color: "#E41D1D", fontWeight: 500, fontSize: 12 }}>
              {error || errorFiles[file.uid]}
            </div>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
      return newDiv;
    },
    [errorFiles, form, progress, successFiles, uploading]
  );

  const handleSubmit = () => {
    if (uploadFileList?.some((item) => item.status === "error")) {
      return;
    }

    const token = localStorage.getItem("accessToken");
    if (!token) {
      return;
    }

    setUploading(true);

    let promiseList: Promise<any>[] = [];

    uploadFileList.forEach((file, index) => {
      const formData = new FormData();
      formData.append("file", file.originFileObj as Blob);
      formData.append("provinceCode", form.getFieldValue("province"));
      formData.append("districtCode", form.getFieldValue("district"));
      formData.append("communeCode", form.getFieldValue("commune"));
      formData.append("pageSize", form.getFieldValue("pageSize"));
      const config: AxiosRequestConfig<FormData> = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          if (event.loaded && event.total) {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress((prev) => ({ ...prev, [file.uid]: percent }));
          }
        },
      };
      promiseList.push(
        axios
          .post(
            process.env.REACT_APP_API_URL + "/api/v1/file/pdf/upload",
            formData,
            config
          )
          .then((res) => {
            if (res?.data?.code === 200) {
              setSuccessFiles((prev) => [...prev, file.uid]);
            } else {
              setErrorFiles((prev) => ({
                ...prev,
                [file.uid]: res?.data?.message || "",
              }));
            }
            setFileCount((prev) => prev + 1);
          })
          .catch((err) => {
            setErrorFiles((prev) => ({
              ...prev,
              [file.uid]: err?.response?.data?.message || "Đã có lỗi xảy ra",
            }));
            setFileCount((prev) => prev + 1);
          })
      );
    });

    Promise.allSettled(promiseList).then((values) => {
      shouldClearFileList.current = uploadFileList.map((item) => item.uid);
    });
  };

  return (
    <Modal
      open={visible}
      title="Tải file pdf"
      onCancel={handleClose}
      width={800}
      maskClosable={false}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
      }}
      footer={[
        <React.Fragment key="footer">
          <Button
            //   loading={isLoading || responseUpload.isLoading}
            htmlType="button"
            type="primary"
            onClick={() => form.submit()}
            className="upload-pdf-button"
            disabled={
              uploading ||
              uploadFileList?.some((item) => item.status === "error") ||
              uploadFileList?.length === 0
            }
          >
            Tải file pdf
          </Button>
        </React.Fragment>,
      ]}
    >
      <Form
        name="uploadPdf"
        autoComplete="off"
        form={form}
        labelWrap
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        labelAlign="left"
        requiredMark={false}
        colon={false}
        onValuesChange={onValuesChange}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Tỉnh/thành"
          name="province"
          rules={[{ required: true, message: "Trường dữ liệu bắt buộc." }]}
        >
          <Select
            allowClear
            placeholder="Chọn Tỉnh/thành"
            optionFilterProp="label"
            options={provinces.map((item: any) => ({
              label: item.provinceName,
              value: item.provinceCode,
            }))}
            showSearch
            showArrow
          ></Select>
        </Form.Item>

        <Form.Item
          label="Quận/huyện"
          name="district"
          rules={[{ required: true, message: "Trường dữ liệu bắt buộc." }]}
        >
          <Select
            allowClear
            placeholder="Chọn Quận/huyện"
            optionFilterProp="label"
            options={districtList?.map((item: any) => ({
              label: item.districtName,
              value: item.districtCode,
            }))}
            showSearch
            showArrow
          ></Select>
        </Form.Item>

        <Form.Item
          label="Phường/xã"
          name="commune"
          rules={[{ required: true, message: "Trường dữ liệu bắt buộc." }]}
        >
          <Select
            allowClear
            placeholder="Chọn Phường/xã"
            optionFilterProp="label"
            options={communeList?.map((item: any) => ({
              label: item.communeName,
              value: item.communeCode,
            }))}
            showSearch
            showArrow
          ></Select>
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 10 }}
          label="Kích thước sổ"
          name="pageSize"
          rules={[{ required: true, message: "Trường dữ liệu bắt buộc." }]}
        >
          <Select
            allowClear
            placeholder="Chọn loại kích thước sổ"
            optionFilterProp="label"
            options={[
              { id: "A3", name: "A3" },
              { id: "A4", name: "A4" },
            ].map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            showSearch
            showArrow
          ></Select>
        </Form.Item>

        <Form.Item
          name="files"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          style={{ marginTop: 20 }}
          rules={[
            { required: true, message: "Trường dữ liệu bắt buộc." },
            () => ({
              validator(_: any, value: any) {
                if (uploadFileList?.some((item) => item.status === "error")) {
                  return Promise.reject();
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Dragger
            accept="application/pdf"
            name="files"
            multiple={true}
            maxCount={50}
            beforeUpload={() => {
              return false;
            }}
            progress={{
              strokeWidth: 3,
              strokeColor: "#2563EB",
            }}
            itemRender={() => {
              return null;
            }}
          >
            <p>
              <UploadIcon width={76} />
            </p>
            <p style={{ marginTop: 20 }}>
              <strong>
                Kéo và thả tập tin hoặc{" "}
                <span style={{ color: "#2563EB", textDecoration: "underline" }}>
                  Browse
                </span>
              </strong>
            </p>
            <p
              style={{
                color: "#676767",
                fontSize: 12,
                marginTop: 8,
                marginBottom: 20,
              }}
            >
              (Dung lượng tối đa là 5Mb và phải có 1 trong các định dạng .pdf)
            </p>
          </Dragger>
        </Form.Item>

        {!uploading &&
          uploadFileList?.map((item, index) => {
            return itemRender(item);
          })}

        {uploading && (
          <>
            <p style={{ color: "#676767", fontWeight: 700 }}>
              Đang tải lên - {fileCount || 0}/{uploadFileList?.length || 0}{" "}
              files
            </p>

            {uploadingList?.map((item) => itemRender(item))}

            {uploadSuccessList?.length ? (
              <>
                <p style={{ color: "#676767", fontWeight: 700, marginTop: 10 }}>
                  Đã tải lên
                </p>

                {uploadSuccessList?.map((item) => itemRender(item))}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </Form>
    </Modal>
  );
};

export default UploadPdf;
