import { FormInstance, notification, Pagination, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLazyGetSyncReportQuery } from "../../../../redux/api/commonApi";
import ConfirmModal from "../../../common/components/ConfirmModal";
import {
  useExportDataMutation,
  useGetReportQuery,
} from "../redux/nangXuatNhapLieuReportApi";
import { CommonTableParams } from "../../../../redux/api/apiTypes";
import { USER_ROLES } from "../../../../assets/ts/constant";
import { useAppSelector } from "../../../../redux/Hooks";

type Props = {
  form: FormInstance<any>;
  searchParam: CommonTableParams<any>;
  setSearchParam: React.Dispatch<React.SetStateAction<CommonTableParams<any>>>;
};

const ReportList = ({ form, searchParam, setSearchParam }: Props) => {
  const pageSizeRef = useRef(10);
  const user = useAppSelector((state) => state.user);

  const { data: responseReport, isFetching } = useGetReportQuery(searchParam, {
    refetchOnMountOrArgChange: true,
  });
  // const [triggerReportTotal, responseReportTotal] = useLazyGetReportTotalQuery();
  const [triggerSyncReport, responseSyncReport] = useLazyGetSyncReportQuery();
  const [triggerExport] = useExportDataMutation();
  const [tableData, setTableData] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (responseReport?.content) {
      let newTableData: any[] = [];
      responseReport.content.forEach((item: any, index: any) => {
        newTableData.push({
          key: index + 1,
          ...item,
        });
      });
      setTableData(newTableData);
    } else {
      setTableData([]);
    }
  }, [responseReport]);

  const convertDateFormat = (dateString: any) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${day}/${month}/${year}`;
    }
    return dateString; // Trả về nguyên gốc nếu không phải định dạng "dd-mm-yyyy"
  };

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, record, index) =>
        searchParam.page * pageSizeRef.current + index + 1,
      width: 70,
    },
    {
      title: "Ngày nhập liệu",
      dataIndex: "createdDateStr",
      key: "created_date_str",
      render: (createdDateStr) => convertDateFormat(createdDateStr),
    },
    {
      title: "Tài khoản quản lý",
      // dataIndex: "createBy",
      key: "create_by",
      render: (value, record, index) => {
        return record?.managers?.join(", ");
      },
    },
    {
      title: "Tài khoản nhập liệu",
      dataIndex: "createBy",
      key: "full_name",
    },
    {
      title: "Loại hộ tịch",
      dataIndex: "typeId",
      key: "type_id",
      align: "center",
    },
    {
      title: "Kích thước sổ",
      dataIndex: "typePdf",
      key: "type_pdf",
      align: "center",
    },
    {
      title: "Số THHT đã nhập liệu",
      dataIndex: "totalThht",
      key: "total_thht",
      align: "center",
    },
    {
      title: "Số trường sai sót",
      dataIndex: "totalFieldError",
      key: "total_field_error",
      align: "center",
    },
    // {
    //     title: "Số trường đã sửa",
    //     dataIndex: "total_field_edit",
    //     key: "total_field_edit",
    //     align: "center",
    // }
  ];

  const getData = () => {
    //console.log(searchParam);
    setSearchParam((prev) => ({
      searchParam,
      page: 0,
      size: pageSizeRef.current,
    }));
    // triggerReportTotal({ searchParam });
    // setPage(1);
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage;
    if (pageSizeChange) {
      newPage = 1;
    }
    // setPage(newPage);
    pageSizeRef.current = pageSize;

    setSearchParam((prev) => ({
      searchParam: searchParam?.searchParam
        ? {
            ...searchParam?.searchParam,
          }
        : "",
      page: newPage - 1,
      size: pageSizeRef.current,
    }));
    // triggerReportTotal({ searchParam });
  };

  const onExportExcelClick = () => {
    setLoading(true);
    triggerExport({ searchParam })
      .unwrap()
      .then((res) => {
        setLoading(false);
        notification.success({ message: "Xuất excel thành công!" });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        let errorMessage = err.data || "Có lỗi xảy ra!";
        if (err?.originalStatus === 204) {
          errorMessage = err?.error?.split("Error: ")?.[1] || "Có lỗi xảy ra!";
        }
        notification.error({ message: errorMessage });
      });
  };

  const onSyncClick = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Thực hiện đồng bộ ở đây
    notification.success({ message: "Đồng bộ bắt đầu, Vui lòng chờ!" });
    setLoadings(true);
    triggerSyncReport({ rpName: "nang-xuat-nhap-lieu" })
      .unwrap()
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setTimeout(() => {
          notification.success({ message: "Đồng bộ thành công!" });
        }, 500);
        getData();
        setLoadings(false);
      })
      .catch((err) => {
        setLoadings(false);
      });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Ẩn modal nếu người dùng hủy bỏ
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        {/* <Col span={6}></Col> */}
        <div>
          Tổng số: <strong>{responseReport?.totalElements || 0}</strong> bản ghi
        </div>
        {/* <div className="search-box-button">
          <Button
            htmlType="button"
            // type="primary"
            icon={<DownloadOutlined />}
            onClick={onExportExcelClick}
            loading={loading}
          >
            Xuất excel
          </Button>

          <Button htmlType="button" type="primary" onClick={onSyncClick}>
            Đồng bộ
          </Button>
        </div> */}
      </Row>
      <ConfirmModal
        cancelText="Huỷ bỏ"
        okText="Xác nhận"
        classBtnOk="ant-button-dangerous"
        contents={[
          "Đồng bộ sẽ mất thời gian khoảng 5 phút.",
          "Bạn có chắc chắn muốn đồng bộ không?",
        ]}
        handleCancel={handleCancel}
        handleOk={handleOk}
        type={1}
        visible={isModalVisible}
        widthConfig={500}
        okBtnDanger={true}
      />
      <Table<any>
        loading={loadings || isFetching}
        dataSource={tableData}
        columns={columns.filter((item) => {
          // Ẩn một số cột theo role user
          if (
            item.key === "create_by" &&
            user?.user?.role !== USER_ROLES.ADMIN
          ) {
            return false;
          }

          return true;
        })}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <Pagination
        total={responseReport?.totalElements || 0}
        defaultPageSize={pageSizeRef.current}
        defaultCurrent={1}
        locale={{ items_per_page: " dòng" }}
        pageSizeOptions={[10, 20, 50, 100]}
        onChange={changePage}
        showSizeChanger
        current={searchParam.page + 1}
      />
    </div>
  );
};

export default ReportList;
