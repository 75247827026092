import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { ReactComponent as ZoomIn } from "../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../assets/logos/zoom-out-line.svg";
import { CommonTableParams } from "../../redux/api/apiTypes";
import {
  useGetGroupUserInputQuery
} from "../../redux/api/commonApi";
import CampaignList from "./components/ListDataEntryCampaign";
const DataEntryCampaign = () => {
  const [form] = Form.useForm();
  const [searchParam, setSearchParam] = useState<CommonTableParams<any>>({
    searchParam: {},
    page: 0,
    size: 10,
  });
  // const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckQuery();
  const { data: responseGroupUserCheck } = useGetGroupUserInputQuery({});

  const onFinish = (values: any) => {
    setSearchParam((prev) => ({
      searchParam: values,
      page: 0,
      size: prev.size,
    }));
  };

  const resetFormData = () => {
    form.resetFields();
  };
  const [isSearch, setIsSearch] = useState(false);
  return (
    <>
      {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
      {/*    <Breadcrumb.Item href="/home">*/}
      {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
      {/*    </Breadcrumb.Item>*/}
      {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
      {/*    <Breadcrumb.Item href="">*/}
      {/*        <span>Chiến dịch nhập liệu</span>*/}
      {/*    </Breadcrumb.Item>*/}
      {/*</Breadcrumb>*/}

      {/* <div className='title-label' style={{ marginBottom: 20 }}>Chiến dịch nhập liệu</div> */}

      <div className="content-box" style={{ marginBottom: 30 }}>
        <Form
          name="searchBox"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          labelWrap
          labelAlign="left"
          colon={false}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setIsSearch(!isSearch)}
          >
            {isSearch ? (
              <ZoomOut style={{ width: 20, paddingTop: 8 }} />
            ) : (
              <ZoomIn style={{ width: 20, paddingTop: 8 }} />
            )}
            <label className="search-title" style={{ cursor: "pointer" }}>
              TÌM KIẾM
            </label>
          </div>
          {isSearch ? (
            <>
              <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                <Col span={8} xs={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Tên chiến dịch"
                    name="campaignName"
                  >
                    <Input
                      placeholder="Nhập tên chiến dịch"
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Trạng thái"
                    name="status"
                  >
                    <Select allowClear placeholder="Chọn trạng thái" showArrow>
                      <Select.Option value={0}>Hoạt động</Select.Option>
                      <Select.Option value={1}>Không hoạt động</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={8} xs={24} md={12} lg={12}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Người thực hiện"
                                    name="userIds"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn người thực hiện"
                                        options={(responseUserCheck?.data || []).map(item => ({
                                            label: `${item.fullName} (${item.username})`,
                                            value: item.id
                                        }))}
                                        showSearch
                                        mode="multiple"
                                        maxTagCount='responsive'
                                        optionFilterProp="label"
                                    >
                                    </Select>
                                </Form.Item>
                            </Col> */}
                <Col span={8} xs={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Nhóm người thực hiện"
                    name="groupUserIds"
                  >
                    <Select
                      allowClear
                      placeholder="Chọn nhóm người thực hiện"
                      options={(responseGroupUserCheck || []).map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      showSearch
                      maxTagCount="responsive"
                      optionFilterProp="label"
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} md={12} lg={12}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Mô tả"
                    name="description"
                  >
                    <Input
                      placeholder="Nhập mô tả"
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                {/*<Col span={8} xs={24} md={12} lg={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Nguồn dữ liệu"
                                name="type"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn nguồn dữ liệu"
                                    optionFilterProp='label'
                                    options={([{ id: 1, name: "Dữ liệu từ file excel" }, { id: 2, name: "Dữ liệu nhập liệu" }]).map(item => ({
                                        label: item.name,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>*/}
              </Row>
              <div className="search-box-button">
                <Button htmlType="button" onClick={() => resetFormData()}>
                  Xóa điều kiện
                </Button>
                <Button htmlType="submit" type="primary">
                  Tìm kiếm
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </Form>
      </div>
      <CampaignList
        form={form}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />
    </>
  );
};

export default DataEntryCampaign;
