import { Button, Col, Pagination, Row, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMemo, useState } from "react";
import CreateCampaign from "./CreateCampaign";
import { DataListTable, IFilter } from "../redux/apiTypes";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/Hooks";
import moment from "moment";
import { checkPermission } from "../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { USER_ROLES } from "../../../assets/ts/constant";

type Props = {
  total: number;
  isLoading: boolean;
  filter: IFilter;
  onChangeTable: (pageNum?: number, pageSize?: number) => void;
  dataSource: DataListTable[];
};
const STATUS = {
  UN_RECHECK: 2,
  WAIT_RECHECK: 3,
  RECHECKED: 4,
};

const StatusElement = ({ value }: { value: number }) => {
  const statusStyle = {
    padding: "4px",
    borderRadius: "4px",
  };

  const statusMapping = {
    [STATUS.UN_RECHECK]: {
      color: "#E11D48",
      text: "Chưa đối chiếu",
      bg: "#FFE4E6",
    },
    [STATUS.WAIT_RECHECK]: {
      color: "#E1871D",
      text: "Chờ đối chiếu",
      bg: "#FFF7E4",
    },
    [STATUS.RECHECKED]: {
      color: "green",
      text: "Đã đối chiếu",
      bg: "#CCFBF1",
    },
  };
  const status = statusMapping[value] || { color: "gray", text: "Unknown" };

  return (
    <span
      style={{
        ...statusStyle,
        color: status.color,
        backgroundColor: status.bg,
      }}
    >
      {status.text}
    </span>
  );
};

const DataCompearList = ({
  total,
  isLoading,
  filter,
  onChangeTable,
  dataSource,
}: Props) => {
  const [createCampaignVisible, setCreateCampaignVisible] = useState(false);
  const userState = useAppSelector((state) => state.user.user);
  const canCreateCampaign = checkPermission(
    PERMISSION_KEYS.CHIEN_DICH_DOI_CHIEU.THEM_MOI
  );

  const currentPageNum = useMemo(() => {
    return filter?.page + 1;
  }, [filter?.page]);

  const currentPageSize = useMemo(() => {
    return filter?.size;
  }, [filter?.size]);

  const currentDataSource = useMemo(() => {
    return dataSource;
  }, [dataSource]);

  const totals = useMemo(() => {
    return total;
  }, [total]);

  const allColumns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, item, index) =>
        (currentPageNum - 1) * currentPageSize + index + 1,
    },
    {
      title: "File pdf",
      dataIndex: "filePdfName",
      key: "filePdfName",
      align: "left",
      render: (value, item, index) => (
        <div>
          <Link to={`/data/recheck/detail/${item.id}`}>{value || "-"}</Link>
        </div>
      ),
    },
    {
      title: "File pdf 158",
      dataIndex: "filePdf158Name",
      key: "filePdf158Name",
      align: "left",
      render: (value) => <div>{value || "-"}</div>,
    },
    {
      title: "Tài khoản nhập liệu",
      dataIndex: "modifiers",
      key: "modifiers",
      align: "left",
      render: (value) => <div>{value && value?.join(" - ")}</div>,
    },
    {
      title: "Số trường thông tin không khớp nhau",
      key: "numberFailed",
      dataIndex: "numberFailed",
      render: (value) => <div>{value || "-"}</div>,
      align: "center",
    },
    {
      title: "Nhóm người dùng",
      key: "recheckGroupUsers",
      dataIndex: "recheckGroupUsers",
      render: (value) => (
        <div>
          {value?.length > 0 && value?.map((el: any) => el.name).join(", ")}
        </div>
      ),
      align: "left",
    },
    {
      title: "Người thực hiện",
      key: "modifiedBy",
      dataIndex: "modifiedBy",
      render: (value, item) => (
        <div>{item.status == STATUS.RECHECKED ? value : "-"}</div>
      ),
      align: "center",
    },
    {
      title: "Trạng thái",
      key: "status",
      dataIndex: "status",
      render: (value) => <StatusElement value={value} />,
      align: "center",
    },
    {
      title: "Ngày tạo",
      key: "createdDateStr",
      dataIndex: "createdDateStr",
      align: "left",
      render: (value) => (
        <div>
          {value &&
            moment(value, "DD/MM/YYYY HH:mm:ss").format("HH:mm DD/MM/YYYY")}
        </div>
      ),
    },
  ];

  const changePage = (currentPage: number, pageSize: number) => {
    onChangeTable(pageSize === currentPageSize ? currentPage : 1, pageSize);
  };

  const toggleModal = () => {
    setCreateCampaignVisible(!createCampaignVisible);
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <Col span={6}>
          <div>
            Tổng số: <strong>{totals}</strong> bản ghi
          </div>
        </Col>
        {(userState.role === USER_ROLES.ADMIN || canCreateCampaign) && (
          <div className="search-box-button">
            <Button
              disabled={currentDataSource.length === 0}
              htmlType="button"
              type="primary"
              onClick={toggleModal}
            >
              Tạo chiến dịch
            </Button>
          </div>
        )}
      </Row>

      <Table<DataListTable>
        loading={isLoading}
        dataSource={currentDataSource}
        columns={allColumns.filter((item) => {
          // Ẩn một số cột theo role user
          if (
            (item.key === "recheckGroupUsers" &&
              userState?.role !== USER_ROLES.ADMIN) ||
            ((item.key === "modifiedBy" || item.key === "status") &&
              userState?.role === USER_ROLES.MEMBER)
          ) {
            return false;
          }

          return true;
        })}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
        rowKey="id"
      />

      <Spin spinning={isLoading} tip="Đang tải...">
        <Pagination
          total={totals}
          defaultPageSize={10}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={currentPageNum}
        />
      </Spin>

      {(userState.role === USER_ROLES.ADMIN || createCampaignVisible) && (
        <CreateCampaign
          filter={filter}
          visible={createCampaignVisible}
          onClose={toggleModal}
        />
      )}
    </div>
  );
};

export default DataCompearList;
