import {
  CheckCircleOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
// import useResizeObserver from "@react-hook/resize-observer";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  // useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PDFPageProxy } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
// import { useParams } from "react-router-dom";
import SplitPane from "react-split-pane";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { helpers } from "../../../assets/js/helper";
import { ReactComponent as Left } from "../../../assets/logos/layout-left-line.svg";
import { ReactComponent as Right } from "../../../assets/logos/layout-right-line.svg";
import { ReactComponent as Top } from "../../../assets/logos/layout-top-line.svg";
import { ReactComponent as Rotate } from "../../../assets/logos/rotate.svg";
import { ReactComponent as ZoomIn } from "../../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../../assets/logos/zoom-out-line.svg";
import {
  DEFAULT_FIELD_CONFIG,
  MAX_LENGTH,
  TYPE_FILE_PDF,
} from "../../../assets/ts/constant";
import { DataInfoContext } from "../../../layouts/context/DataInfoProvider";
import { Commune, District } from "../../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
} from "../../../redux/api/commonApi";
import { useAppSelector } from "../../../redux/Hooks";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import ConfirmModal from "../../common/components/ConfirmModal";
import { FieldConfig } from "../redux/apiTypes";
import {
  useGetRandomDataPdfQuery,
  useInsertDataInfoInitMutation,
  useLazyGetFieldConfigQuery,
  useLazyGetFilePdfByIdQuery,
  useLazyGetHistoryA3EnteredQuery,
  useLazyGetRandomDataPdfQuery,
  useUpdateSigningPositionMutation,
  useUpdateStatusFilePdfMutation,
} from "../redux/dataInitApi";
import DragAndDropTable from "./DragAndDropTable";
import { isMinimumAge } from "../../common/assets/CommonFunctions";
// import ViewFilePdf from "./ViewFilePdf";

// const useWidth = (target: any) => {
//   const [width, setWidth] = useState(300);

//   useLayoutEffect(() => {
//     setWidth(target.current.getBoundingClientRect().width);
//   }, [target]);

//   useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
//   return width;
// };

const CreateDataInfo = () => {
  const [form] = Form.useForm();
  const user = useAppSelector((state) => state.user);
  // const param = useParams();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [orderNumber, setOrderNumber] = useState(1);
  const [year, setYear] = useState<string>();
  const [displayFlex, setDisplayFlex] = useState(true);
  const [width, setWidth] = useState<any>();
  const [initWidth, setInitWidth] = useState<any>();
  const [height, setHeight] = useState<any>();
  const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
  const [loaiGiayToKeys, setLoaiGiayToKeys] = useState<any[]>([]);

  const [insertData, { isLoading }] = useInsertDataInfoInitMutation();
  const [updateStatusPdf] = useUpdateStatusFilePdfMutation();
  const {
    data: filePdfResponse,
    isFetching,
    refetch,
  } = useGetRandomDataPdfQuery({});
  const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmSuccessVisible, setConfirmSuccessVisible] = useState(false);
  const [clearDataVisible, setClearDataVisible] = useState(false);
  // const [viewPdfVisible, setViewPdfVisible] = useState(false);
  // const [indexCompare, setIndexCompare] = useState<number>();
  const [triggerProvince, responseProvince] =
    useLazyGetAllProvinceNoAuthQuery();
  const [provinceObject, setProvinceObject] = useState();
  const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
  const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();

  const [districts, setDistricts] = useState<District[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [districtObject, setDistrictObject] = useState();
  const [communeObject, setCommuneObject] = useState();
  const [communeAllTrigger, responseCommuneAll] =
    useLazyGetAllCommuneNoAuthQuery();
  const [districtAllTrigger, responseDistrictAll] =
    useLazyGetAllDistrictNoAuthQuery();
  const [pdfFileName, setPdfFileName] = useState("");
  const [listActive, setListActive] = useState<String[]>([]);
  const [triggerGetFieldConfig, fieldConfigResponse] =
    useLazyGetFieldConfigQuery();
  const wrapperDiv = useRef<HTMLInputElement>(null);
  const [triggerUpdate, { isLoading: loadingPosition }] =
    useUpdateSigningPositionMutation();

  const [triggerGetHistoryA3Enter, responseHistoryA3] =
    useLazyGetHistoryA3EnteredQuery();
  const {
    updateTableData,
    tableData: numberTHHTEnter,
    isShowTableData,
  } = useContext(DataInfoContext);

  const [data, setData] = useState<{
    isOpen: boolean;
    type: number;
    payload: any;
  }>({
    isOpen: false,
    type: 0,
    payload: null,
  });

  const [tableData, setTableData] = useState<any[]>([]);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const canvas = useRef<any>();
  const canvas1 = useRef<any>();
  const [clientY, setClientY] = useState<number>(0);
  const [isRendered, setIsRendered] = useState<boolean>(true);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const prevScale = useRef<number>(1);
  const [docKey, setDocKey] = useState(0);
  const [enterManuallyInput, setEnterManuallyInput] = useState<{
    [key: string]: string;
  }>({
    noiDangKy: "",
    chucVuNguoiKy: "",
  });
  const [isOpenEnterManuallyInput, setIsOpenEnterManuallyInput] = useState<{
    [key: string]: boolean;
  }>({
    isRegisterPlaceOpen: false,
    isTitileSignerOpen: false,
  });
  useEffect(() => {
    if (clientY === 0 || !canvas.current || !isRendered) {
      return;
    }
    const context = canvas.current.getContext("2d");
    const { width } = canvas.current;
    context.save();
    context.beginPath();
    context.moveTo(0, clientY);
    context.lineTo(width, clientY);
    context.strokeStyle = "red";
    context.stroke();
    context.restore();
    if (canvas1.current && canvas1.current.getContext("2d")) {
      const context1 = canvas1.current.getContext("2d");
      const width1 = canvas1.current.width;
      const rect1 = canvas1.current.getBoundingClientRect();
      context1.save();
      context1.beginPath();
      context1.moveTo(0, clientY);
      context1.lineTo(width1, clientY);
      context1.strokeStyle = "red";
      context1.stroke();
      context1.restore();
    }
  }, [clientY, isRendered]);
  // console.log('filePdfResponse:',filePdfResponse);
  useEffect(() => {
    if (prevScale.current === scale) {
      return;
    } else {
      transformComponentRef.current.resetTransform(0);
      transformComponentRef.current.centerView(1, 0);
      setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
      setDocKey((s) => ++s);
      setIsRendered(false);
    }
  }, [scale]);
  const onRenderSuccess = (page: PDFPageProxy) => {
    setIsRendered(true);
  };
  const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
    if (!isDrag) {
      const rect = canvas.current.getBoundingClientRect();
      const scaleY = canvas.current.height / rect.height;
      setClientY((event.clientY - rect.top) * scaleY);
      // setIsRendered(false);
    }
  };

  // const width = useWidth(wrapperDiv);
  // const file = useMemo(() => {
  //   if (filePdfResponse?.id)
  //     return {
  //       url:
  //         process.env.REACT_APP_API_URL +
  //         `/api/v1/file/download-pdf/${filePdfResponse?.id}`,
  //       httpHeaders: {
  //         Authorization: "Bearer " + localStorage.getItem("accessToken"),
  //         "Content-Type": "application/json;charset=UTF-8",
  //       },
  //     };
  // }, [filePdfResponse?.id]);

  const file = useMemo(() => {
    if (filePdfResponse?.pathFile) {
      return {
        url:
          process.env.REACT_APP_API_FILE_URL + "/" + filePdfResponse?.pathFile,
        httpHeaders: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
    }
  }, [filePdfResponse?.pathFile]);

  useEffect(() => {
    const slider = document.getElementById("block-view-pdf");
    let isDown = false;
    let startX: any;
    let startY: any;
    let scrollLeft: any;
    let scrollTop: any;
    if (slider) {
      slider.addEventListener("mousedown", (e) => {
        // console.log("here 2", isDown);

        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        startY = e.clientY - slider.offsetTop;
        scrollLeft = slider.scrollLeft;
        scrollTop = slider.scrollTop;
        setIsDrag(false);
      });
      slider.addEventListener("mouseleave", () => {
        // console.log("here 1", isDown);

        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        // console.log("here 2", isDown);

        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        // console.log("here 3", isDown);

        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        setIsDrag(true);
      });
      slider.addEventListener("mousemove", (e) => {
        // console.log("here 5", isDown);

        if (!isDown) return;
        e.preventDefault();
        const x = e.clientY - slider.offsetTop;
        const walk = (x - startY) * 3; //scroll-fast
        slider.scrollTop = scrollTop - walk;
        setIsDrag(true);
      });
    }
  }, []);

  useEffect(() => {
    if (displayFlex) {
      setWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
      setInitWidth(
        (wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 ||
          undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

  // useEffect(() => {
  //   triggerGetHistoryA3Enter({id: "654ca5daa6b5da21e445c97b"})
  // },[triggerGetHistoryA3Enter])

  useEffect(() => {
    if (
      responseHistoryA3?.data &&
      filePdfResponse?.paperSize === "A3"
      // filePdfResponse?.numberTHHTInput &&
    ) {
      const start: number =
        parseInt(filePdfResponse?.numberDataInfo) >
        responseHistoryA3?.data?.length
          ? 0
          : parseInt(filePdfResponse?.numberDataInfo);
      const end: number =
        parseInt(filePdfResponse?.numberDataInfo) >
        responseHistoryA3?.data?.length
          ? parseInt(filePdfResponse?.numberDataInfo)
          : parseInt(filePdfResponse?.numberDataInfo) * 2;

      const listDataKeyMap = responseHistoryA3?.data
        .slice(start, end)
        .map((item: any) => item.dataKeyMap);
      const transformedData = listDataKeyMap.map((innerArray: any) => {
        return innerArray.reduce((acc: any, current: any) => {
          acc[current.dataKeyAscii] = current.dataValue;
          return acc;
        }, {});
      });
      console.log(transformedData);
      setTableData(transformedData);
    }
  }, [
    filePdfResponse?.numberDataInfo,
    filePdfResponse?.numberTHHTInput,
    filePdfResponse?.paperSize,
    responseHistoryA3?.data,
  ]);

  useEffect(() => {
    let typeFile = filePdfResponse?.fileName?.split(".")?.[0];
    let filePdfId = filePdfResponse?.id;
    if (typeFile && !isFetching) {
      triggerGetFieldConfig({
        types: typeFile,
        years: convertYearToTypePdf(typeFile),
      });
    }
    if (filePdfId) {
      // triggerGetHistoryA3Enter({id: "654ca5daa6b5da21e445c97b"})
      triggerGetHistoryA3Enter({ id: filePdfId });
    }

    updateTableData(
      filePdfResponse?.numberTHHTInput ? filePdfResponse?.numberTHHTInput : 0
    );
    if (filePdfResponse?.numberDataInfo) {
      setNumberTHHTRemain(
        filePdfResponse.numberDataInfo - filePdfResponse.numberTHHTInput
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerGetFieldConfig,
    filePdfResponse?.fileName,
    filePdfResponse?.id,
    filePdfResponse?.numberTHHTInput,
    isFetching,
  ]);

  useEffect(() => {
    if (filePdfResponse) {
      triggerProvince({})
        .unwrap()
        .then((res) => {
          setProvinceObject(
            res?.reduce(
              (a: any, v: any) => ({
                ...a,
                [DEFAULT_FIELD_CONFIG.tinhThanh]: v.id,
              }),
              {}
            )
          );
        });
      communeAllTrigger({})
        .unwrap()
        .then((res) => {
          setCommuneObject(
            res?.reduce(
              (a: any, v: any) => ({ ...a, [v.communeCode]: v.id }),
              {}
            )
          );
        });
      districtAllTrigger({})
        .unwrap()
        .then((res) => {
          setDistrictObject(
            res?.reduce(
              (a: any, v: any) => ({ ...a, [v.districtCode]: v.id }),
              {}
            )
          );
        });
    }
  }, [triggerProvince, districtAllTrigger, communeAllTrigger, filePdfResponse]);

  useEffect(() => {
    if (
      fieldConfigResponse?.data?.fieldKeyMap?.length &&
      filePdfResponse?.id &&
      provinceObject &&
      districtObject &&
      responseCommuneAll?.data
    ) {
      setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
    } else {
      setKeyMaps([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldConfigResponse?.data?.type,
    fieldConfigResponse?.data?.year,
    filePdfResponse?.id,
    provinceObject,
    districtObject,
    responseCommuneAll?.data,
  ]);

  const convertYearToTypePdf = (typeFile: string) => {
    // KS.1994.01.--.001.PDF
    let yearStr = filePdfResponse?.fileName?.split(".")?.[1];
    let yearNum = yearStr ? parseInt(yearStr) : 0;
    // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
    // Chuyển từ năm sang file loại file: A3, A4
    if (filePdfResponse?.paperSize) {
      setYear(filePdfResponse?.paperSize);
      return filePdfResponse?.paperSize;
    }
    if (
      typeFile === "KH" &&
      yearNum === 2007 &&
      filePdfResponse?.communeCode === "xatinhantay"
    ) {
      setYear(TYPE_FILE_PDF.A3);
      return TYPE_FILE_PDF.A3;
    }
    if (yearNum === 2007 && filePdfResponse?.communeCode === "xatinhantay") {
      setYear(TYPE_FILE_PDF.A3);
      return TYPE_FILE_PDF.A3;
    }
    if (yearNum > 2006) {
      if (
        typeFile === "KH" &&
        filePdfResponse?.communeCode === "sotuphap" &&
        filePdfResponse?.districtCode === "thanhphoquangngai"
      ) {
        if (yearNum < 2011) {
          setYear(TYPE_FILE_PDF.A3);
          return TYPE_FILE_PDF.A3;
        }
      }
      setYear(TYPE_FILE_PDF.A4);
      return TYPE_FILE_PDF.A4;
    }
    if (yearNum > 1977) {
      setYear(TYPE_FILE_PDF.A3);
      return TYPE_FILE_PDF.A3;
    }
    switch (typeFile) {
      case "KS":
        setYear(TYPE_FILE_PDF.A4);
        return TYPE_FILE_PDF.A4;
      default:
        setYear(TYPE_FILE_PDF.A4);
        return TYPE_FILE_PDF.A4;
    }
  };
  const [numberTHHTRemain, setNumberTHHTRemain] = useState<number>();

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((res) => {
        toggleConfirmModal();
      })
      .catch((err) => {
        form.scrollToField(err.errorFields[0].name[0]);
        notification.error({
          message:
            err?.errorFields?.[0].name?.[0] && err?.errorFields?.[0].errors?.[0]
              ? `${err?.errorFields?.[0].name?.[0]}: ${err?.errorFields?.[0].errors?.[0]}!`
              : "Có lỗi nhập liệu",
        });
      });
  };

  const onSubmitCheckData = (isCheck?: Boolean) => {
    let keyMapsObj = keyMaps.reduce(
      (a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }),
      {}
    );
    if (
      fieldConfigResponse?.data?.type &&
      keyMapsObj?.[
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh
      ] &&
      keyMapsObj?.[
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen
      ] &&
      keyMapsObj?.[
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa
      ]
    ) {
      insertData({
        keyMaps: [keyMaps],
        file_pdf_id: filePdfResponse?.id,
        isCheck: isCheck || false,
        typeId: fieldConfigResponse?.data?.type,
        communeId:
          keyMapsObj?.[
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa
          ],
        districtId:
          keyMapsObj?.[
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen
          ],
        provinceId:
          keyMapsObj?.[
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh
          ],
      })
        .unwrap()
        .then((res: any) => {
          let oldData = form.getFieldsValue();
          notification.success({ message: "Nhập liệu thành công" });
          resetData();
          setConfirmVisible(false);
          if (isCheck) {
            refetch();
            triggerFilePdfId(null);

            // reset bảng các THHT đã nhập
            setTableData([]);
          } else {
            // Lưu và Nhập THHT khác trong file pdf
            setNumberTHHTRemain(res);
            updateTableData(numberTHHTRemain === 1 ? 0 : numberTHHTEnter + 1);
            // Gọi lại file pdf, sau đó lấy lịch sử mới
            triggerFilePdfId(filePdfResponse?.id);
            triggerGetHistoryA3Enter({ id: filePdfResponse?.id });
          }
          setInitData(fieldConfigResponse?.data?.fieldKeyMap, oldData);
          setListActive([]);
          setPdfFileName("");
        })
        .catch((err: any) => {
          notification.error({
            message: err.data?.message || "Cập nhật thất bại",
          });
        });
    } else {
      notification.error({ message: "Có lỗi nhập liệu!" });
    }
  };

  const onAcceptClearData = () => {
    resetData();
    setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
    setListActive([]);
    toggleClearDataModal();
  };

  const changeLayout = (newDisplayFlex: boolean, order: number) => {
    setDisplayFlex(newDisplayFlex);
    setOrderNumber(order);
    if (!newDisplayFlex) {
      setWidth(initWidth * 2);
    }
  };
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const toggleConfirmModal = () => {
    setConfirmVisible(!confirmVisible);
  };

  const toggleConfirmSuccessModal = () => {
    setConfirmSuccessVisible(!confirmSuccessVisible);
  };

  const toggleClearDataModal = () => {
    setClearDataVisible(!clearDataVisible);
  };

  // const toggleViewPdfModal = () => {
  //   setViewPdfVisible(!viewPdfVisible);
  // };

  const setInitData = (dataKeyMaps: any, oldData?: any) => {
    // console.log(oldData);
    // TODO: Thêm trường dữ liệu mặc định trong cấu hình trường dữ liệu
    if (dataKeyMaps?.length) {
      const loaiGiayToKeys = dataKeyMaps.filter((item: any) =>
        item.dataKey.includes("LoaiGiayToTuyThan")
      );
      setLoaiGiayToKeys(loaiGiayToKeys);

      setKeyMaps(
        dataKeyMaps.map((item: any, index: number) => {
          let newItem = setDataItem(item, oldData);
          if (newItem) {
            return newItem;
          } else if (item.defaultValue) {
            form.setFieldsValue({
              [item.dataKeyAscii]: item.defaultValue,
            });
            return {
              ...item,
              dataValue: item.defaultValue,
            };
          }
          return item;
        })
      );
    }
    // Columns của bảng dữ liệu THHT đã nhập
    setColumns([
      ...(fieldConfigResponse?.data?.fieldKeyMap || "")
        .filter(
          (x: any) =>
            // x.isDefault &&
            x.isEdit &&
            x.dataKeyAscii !== "tinhThanh" &&
            x.dataKeyAscii !== "quanHuyen" &&
            x.dataKeyAscii !== "phuongXa"
        )
        .map((item: any, index: number) => {
          return {
            title: item.dataKey,
            dataIndex: item.dataKeyAscii,
            key: item.dataKeyAscii,
            align: "center",
            render: (value: any) => <span>{value || "-"}</span>,
            width:
              item.dataKeyAscii === "so" ||
              item.dataKeyAscii === "quyenSo" ||
              item.dataKeyAscii === "trangSo" ||
              item.type === "date"
                ? 125
                : 250,
            fixed:
              item.dataKeyAscii === "so" || item.dataKeyAscii === "quyenSo"
                ? "left"
                : "",
            // render: (record: any) => {
            //     console.log(record);
            //     return ;
            // },
          };
        }),
    ]);
  };

  // Set dữ liệu ban đầu
  const setDataItem = (item: FieldConfig, oldData?: any) => {
    if (item.dataKeyAscii) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
          districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh])
            .unwrap()
            .then((res) => {
              // console.log('setDistricts:',res);
              setDistricts(res);
            });
          if (item.dataKeyAscii) {
            form.setFieldsValue({
              [item.dataKeyAscii]:
                oldData?.[item.dataKeyAscii] ||
                provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh],
            });
          }
          return {
            ...item,
            dataValue:
              oldData?.[item.dataKeyAscii] ||
              provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh] ||
              "",
          };
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
          if (filePdfResponse?.districtCode) {
            communeTrigger(districtObject?.[filePdfResponse?.districtCode])
              .unwrap()
              .then((res) => {
                // console.log('setCommunes:',res);
                setCommunes(res);
              });
            if (item.dataKeyAscii) {
              form.setFieldsValue({
                [item.dataKeyAscii]:
                  oldData?.[item.dataKeyAscii] ||
                  districtObject?.[filePdfResponse?.districtCode],
              });
            }
            return {
              ...item,
              dataValue:
                oldData?.[item.dataKeyAscii] ||
                districtObject?.[filePdfResponse?.districtCode] ||
                "",
            };
          }
          return null;
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
          if (filePdfResponse?.communeCode && filePdfResponse?.districtCode) {
            // console.log(filePdfResponse);
            // console.log('responseCommuneAll:x',responseCommuneAll);
            let newCommuneObject = responseCommuneAll?.data
              ?.filter(
                (item) =>
                  filePdfResponse?.districtCode &&
                  item.districtId ===
                    districtObject?.[filePdfResponse?.districtCode]
              )
              ?.reduce(
                (a: any, v: any) => ({ ...a, [v.communeCode]: v.id }),
                {}
              );
            // console.log('newCommuneObject',newCommuneObject);
            setCommuneObject(newCommuneObject);
            if (item.dataKeyAscii) {
              form.setFieldsValue({
                [item.dataKeyAscii]:
                  oldData?.[item.dataKeyAscii] ||
                  newCommuneObject?.[filePdfResponse?.communeCode],
              });
            }
            return {
              ...item,
              dataValue:
                oldData?.[item.dataKeyAscii] ||
                newCommuneObject?.[filePdfResponse?.communeCode] ||
                "",
            };
          }
          return null;
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo:
          let pdfFileNames = filePdfResponse?.fileName?.split(".");
          let quyenSo = `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}`;
          if (item.dataKeyAscii) {
            form.setFieldsValue({
              [item.dataKeyAscii]: oldData?.[item.dataKeyAscii] || quyenSo,
            });
          }
          return {
            ...item,
            dataValue:
              oldData?.[item.dataKeyAscii] ||
              `${pdfFileNames?.[2] || ""}/${pdfFileNames?.[1] || ""}` ||
              "",
          };
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf:
          let pdfFileNames1 = filePdfResponse?.fileName?.split(".");
          let type = filePdfResponse?.fileName?.split(".")?.[0];
          let tenFilePdf = `${type}.${pdfFileNames1?.[1] || "--"}.${
            pdfFileNames1?.[2] || "--"
          }.--..pdf`;
          form.setFieldsValue({
            [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]:
              tenFilePdf,
          });
          setPdfFileName(tenFilePdf);
          return {
            ...item,
            dataValue: tenFilePdf,
          };
        case DEFAULT_FIELD_CONFIG.noiDangKy:
        case DEFAULT_FIELD_CONFIG.noiCap:
          if (
            filePdfResponse?.districtCode &&
            filePdfResponse?.communeCode &&
            item?.data?.length
          ) {
            const communeName = responseCommuneAll?.data?.find(
              (commune) => commune.communeCode === filePdfResponse?.communeCode
            )?.communeName;
            const provinceName = responseProvince?.data?.find(
              (provice) =>
                provice.provinceCode === DEFAULT_FIELD_CONFIG.tinhThanh
            )?.provinceName;
            const districtName = responseDistrictAll?.data?.find(
              (commune) =>
                commune.districtCode === filePdfResponse?.districtCode
            )?.districtName;
            let key = `${provinceName}`;
            if (filePdfResponse?.communeCode === "sotuphap") {
              key = `UBND ${provinceName}`;
            } else if (filePdfResponse?.communeCode === "phongtuphap") {
              key = `UBND ${districtName}, ${provinceName}`;
            } else {
              key = `UBND ${communeName}, ${districtName}, ${provinceName}`;
            }
            const keyDataIndex = item.data
              ?.map((data: any) =>
                (data.name || "")
                  .replace(/UBND/g, "")
                  .replace(/\s+/g, " ")
                  .trim()
                  .toLowerCase()
              )
              .findIndex(
                (dataLower: any) =>
                  dataLower === key.replace(/UBND/g, "").trim().toLowerCase()
              );
            let keyData = "";
            if (keyDataIndex > 0) {
              keyData = item.data[keyDataIndex].name;
            }
            if (keyData) {
              key = keyData;
            }
            form.setFieldsValue({
              [item.dataKeyAscii]: key,
            });
            return {
              ...item,
              dataValue: key,
            };
          }
      }
    }
    return null;
  };

  function getPrefix(str: string, postFix?: string) {
    if (!str) return "";
    const prefixLength = str.indexOf(postFix || "LoaiGiayToTuyThan");
    if (prefixLength !== -1) {
      return str.substring(0, prefixLength);
    } else {
      return "";
    }
  }
  const nksLoaiKhaiSinh = Form.useWatch("nksLoaiKhaiSinh", form);

  const disabledField = useCallback(
    (keyData: string) => {
      let disabled: boolean = false;
      let arr: string[] = [];
      if (nksLoaiKhaiSinh && fieldConfigResponse?.data?.type === "KS") {
        if (nksLoaiKhaiSinh === "2") {
          arr = [
            "meHoTen",
            "meNgaySinh",
            "meDanToc",
            "meQuocTich",
            "meQuocTichKhac",
            "meNoiCuTru",
            "meLoaiGiayToTuyThan",
            "meSoGiayToTuyThan",
            "meLoaiCuTru",
            "meSoDinhDanhCaNhan",
          ];
        } else if (nksLoaiKhaiSinh === "3") {
          arr = [
            "chaHoTen",
            "chaNgaySinh",
            "chaDanToc",
            "chaQuocTich",
            "chaQuocTichKhac",
            "chaNoiCuTru",
            "chaLoaiGiayToTuyThan",
            "chaSoGiayToTuyThan",
            "chaLoaiCuTru",
            "chaSoDinhDanhCaNhan",
          ];
        } else if (nksLoaiKhaiSinh === "4") {
          arr = [
            "meHoTen",
            "meNgaySinh",
            "meDanToc",
            "meQuocTich",
            "meQuocTichKhac",
            "meNoiCuTru",
            "meLoaiGiayToTuyThan",
            "meSoGiayToTuyThan",
            "meLoaiCuTru",
            "meSoDinhDanhCaNhan",
            "chaHoTen",
            "chaNgaySinh",
            "chaDanToc",
            "chaQuocTich",
            "chaQuocTichKhac",
            "chaNoiCuTru",
            "chaLoaiGiayToTuyThan",
            "chaSoGiayToTuyThan",
            "chaLoaiCuTru",
            "chaSoDinhDanhCaNhan",
          ];
        }
        disabled = arr.some((item) => item === keyData);
      }

      // Disable giấy tờ khác tùy vào loại giấy tờ tùy thân
      if (keyData?.includes("GiayToKhac")) {
        const fieldName =
          getPrefix(keyData, "GiayToKhac") + "LoaiGiayToTuyThan";
        const fieldValue = form.getFieldValue(fieldName);
        if (fieldValue && fieldValue !== "8") {
          return true;
        }
      }

      // Disable nơi cư trú tùy vào loại cư trú
      if (keyData?.includes("NoiCuTru")) {
        const fieldName = getPrefix(keyData, "NoiCuTru") + "LoaiCuTru";
        const fieldValue = form.getFieldValue(fieldName);
        if (fieldValue && fieldValue === "0") {
          return true;
        }
      }
      return disabled;
    },
    [fieldConfigResponse?.data?.type, form, nksLoaiKhaiSinh]
  );

  useEffect(() => {
    const resetMe = () => {
      form?.setFieldValue("meHoTen", "");
      form?.setFieldValue("meNgaySinh", "");
      form?.setFieldValue("meDanToc", undefined);
      form?.setFieldValue("meQuocTich", undefined);
      form?.setFieldValue("meQuocTichKhac", undefined);
      form?.setFieldValue("meNoiCuTru", "");
      form?.setFieldValue("meLoaiGiayToTuyThan", undefined);
      form?.setFieldValue("meSoGiayToTuyThan", "");
      form?.setFieldValue("meSoDinhDanhCaNhan", "");

      const arr = [
        `meHoTen`,
        `meNgaySinh`,
        `meDanToc`,
        `meQuocTich`,
        `meQuocTichKhac`,
        `meNoiCuTru`,
        `meLoaiGiayToTuyThan`,
        `meSoGiayToTuyThan`,
        `meSoDinhDanhCaNhan`,
        `meLoaiCuTru`,
      ];

      // Bỏ báo lỗi các trường
      form.setFields(
        arr.map((arrItem) => {
          return { name: arrItem, errors: undefined };
        })
      );

      setListActive((prev) => [...prev, ...arr]);
    };

    const resetCha = () => {
      form?.setFieldValue("chaHoTen", "");
      form?.setFieldValue("chaNgaySinh", "");
      form?.setFieldValue("chaDanToc", undefined);
      form?.setFieldValue("chaQuocTich", undefined);
      form?.setFieldValue("chaQuocTichKhac", undefined);
      form?.setFieldValue("chaNoiCuTru", "");
      form?.setFieldValue("chaLoaiGiayToTuyThan", undefined);
      form?.setFieldValue("chaSoGiayToTuyThan", "");
      form?.setFieldValue("chaSoDinhDanhCaNhan", "");

      const arr = [
        `chaHoTen`,
        `chaNgaySinh`,
        `chaDanToc`,
        `chaQuocTich`,
        `chaQuocTichKhac`,
        `chaNoiCuTru`,
        `chaLoaiGiayToTuyThan`,
        `chaSoGiayToTuyThan`,
        `chaSoDinhDanhCaNhan`,
        `chaLoaiCuTru`,
      ];

      // Bỏ báo lỗi các trường
      form.setFields(
        arr.map((arrItem) => {
          return { name: arrItem, errors: undefined };
        })
      );

      setListActive((prev) => [...prev, ...arr]);
    };

    if (nksLoaiKhaiSinh && fieldConfigResponse?.data?.type === "KS") {
      if (nksLoaiKhaiSinh === "2") {
        form?.setFieldValue("meLoaiCuTru", "0");
        form?.setFieldValue("chaLoaiCuTru", undefined);
        resetMe();
      } else if (nksLoaiKhaiSinh === "3") {
        form?.setFieldValue("chaLoaiCuTru", "0");
        form?.setFieldValue("meLoaiCuTru", undefined);
        resetCha();
      } else if (nksLoaiKhaiSinh === "4") {
        form?.setFieldValue("meLoaiCuTru", "0");
        form?.setFieldValue("chaLoaiCuTru", "0");
        resetMe();
        resetCha();
      } else {
        form?.setFieldValue("meLoaiCuTru", undefined);
        form?.setFieldValue("chaLoaiCuTru", undefined);
      }
    }
  }, [fieldConfigResponse?.data?.type, form, nksLoaiKhaiSinh]);

  const changeData = async (value: any, index: number, item: FieldConfig) => {
    // console.log("value", value);
    // Nếu không cho sửa thì không được sửa
    if (!item.isEdit) return;
    if (item?.dataKeyAscii) {
      form.setFieldsValue({ [item.dataKeyAscii]: value });
    }
    switch (item.dataKeyAscii) {
      case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
        form.setFieldsValue({ quanHuyen: undefined, phuongXa: undefined });
        setDistricts([]);
        setCommunes([]);
        districtTrigger(value)
          .unwrap()
          .then((res: any) => setDistricts(res));
        break;
      case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
        form.setFieldsValue({ phuongXa: undefined });
        setCommunes([]);
        communeTrigger(value)
          .unwrap()
          .then((res) => setCommunes(res));
        break;
    }
    // KS.2007.01.2007-07-11.082.pdf
    let changeFilePdf =
      item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so ||
      item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ||
      item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy;
    let tenFilePdf = "";
    if (changeFilePdf) {
      let type = filePdfResponse?.fileName?.split(".")?.[0];
      let so =
        item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so
          ? value
          : form?.getFieldsValue()?.so;
      let quyenSo = (
        (item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo
          ? value
          : form?.getFieldsValue()?.quyenSo) || ""
      ).split("/");
      let ngayDangKy = (
        (item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy
          ? value
          : form?.getFieldsValue()?.ngayDangKy) || ""
      ).split(".");
      // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
      let temp = "";
      if (ngayDangKy?.length === 3) {
        temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${
          ngayDangKy?.[0] || ""
        }`;
      } else if (ngayDangKy?.length === 2) {
        temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
      } else {
        temp = `${ngayDangKy?.[0] || ""}--`;
      }
      if (so.indexOf("/") > 0) so = so.substring(0, so.indexOf("/"));
      tenFilePdf = `${type}.${quyenSo?.[1] || "--"}.${
        quyenSo?.[0] || "--"
      }.${temp}.${so || ""}.pdf`;
      form.setFieldsValue({
        [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf]:
          tenFilePdf,
      });
      setPdfFileName(tenFilePdf);
    }

    let loaiDangKy: any;
    let ngayDangKy1 =
      (item.dataKeyAscii ===
      DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy
        ? value
        : form?.getFieldsValue()?.ngayDangKy) || "";

    // Loại đăng ký KS
    let changeLoaiDangKy =
      item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ||
      item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nksNgaySinh;
    let nksNgaySinh =
      (item.dataKeyAscii ===
      DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nksNgaySinh
        ? value
        : form?.getFieldsValue()?.nksNgaySinh) || "";

    // Loại đăng ký KT
    let changeLoaiDangKyKT =
      item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.ngayDangKy ||
      item.dataKeyAscii ===
        DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nktNgayChet;
    let nktNgayChet =
      (item.dataKeyAscii ===
      DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.nktNgayChet
        ? value
        : form?.getFieldsValue()?.nktNgayChet) || "";

    if (
      changeLoaiDangKy &&
      fieldConfigResponse?.data?.type === "KS" &&
      ngayDangKy1 &&
      nksNgaySinh
    ) {
      loaiDangKy = autoFillLoaiDangKy(ngayDangKy1, nksNgaySinh);
    }

    if (
      changeLoaiDangKyKT &&
      fieldConfigResponse?.data?.type === "KT" &&
      ngayDangKy1 &&
      nktNgayChet
    ) {
      loaiDangKy = autoFillLoaiDangKyKT(ngayDangKy1, nktNgayChet);
    }
    /// new rule check start
    let patternSoGiayToTuyThan: string;
    const isChangeLoaiGiayToTuyThan = loaiGiayToKeys.some(
      (el: any) => el.dataKeyAscii === item.dataKeyAscii
    );

    const loaiGiayToTuyThanThayDoi = loaiGiayToKeys.find(
      (el: any) => el.dataKeyAscii === item.dataKeyAscii
    )?.dataKeyAscii;

    const soGiayToTuyThanThayDoi = loaiGiayToTuyThanThayDoi
      ? getPrefix(loaiGiayToTuyThanThayDoi) + "SoGiayToTuyThan"
      : "";

    const loaiGiayToTuyThanGiaTri =
      value || form?.getFieldsValue(loaiGiayToTuyThanThayDoi);

    if (isChangeLoaiGiayToTuyThan) {
      patternSoGiayToTuyThan = handleChangePaternLoaiGiayTo(
        loaiGiayToTuyThanGiaTri
      );

      // Reset trường số giấy tờ tùy thân khi thay đổi loại
      let soGiayToTuyThanName =
        getPrefix(item.dataKeyAscii) + "SoGiayToTuyThan";
      form.setFieldValue(soGiayToTuyThanName, "");

      // Set active giấy tờ khác khi loại giấy tờ khác 8
      if (loaiGiayToTuyThanGiaTri !== "8") {
        const fieldName = getPrefix(item.dataKeyAscii) + "GiayToKhac";
        form.setFieldValue(fieldName, "");
        setListActive((prev) => [...prev, fieldName]);
      }
    }

    // Set active nơi cư trú khi loại cư trú bằng 0
    if (item.dataKeyAscii?.includes("LoaiCuTru") && value === "0") {
      const fieldName = getPrefix(item.dataKeyAscii, "LoaiCuTru") + "NoiCuTru";
      form.setFieldValue(fieldName, "");
      setListActive((prev) => [...prev, fieldName]);
    }

    // Check khi ngày đăng ký thay đổi
    if (item.dataKeyAscii === "ngayDangKy") {
      form.validateFields([
        "nksNgaySinh",
        "nktNgayChet",
        "chongNgaySinh",
        "voNgaySinh",
        "nxnNgaySinh",
        "ncNgaySinh",
      ]);
    }

    setKeyMaps(
      (keyMaps || []).map((x: any, i: number) => {
        if (x.dataKeyAscii === item.dataKeyAscii)
          return {
            ...x,
            dataValue: value || "",
          };
        if (
          x.dataKeyAscii ===
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf &&
          changeFilePdf
        )
          return {
            ...x,
            dataValue: tenFilePdf,
          };
        // Tự động điền các tường từ năm thành chữ
        if (
          (x.dataKeyAscii || "")
            .toLowerCase()
            .includes((item.dataKeyAscii || "").toLowerCase()) &&
          (x.dataKeyAscii || "").toLowerCase().includes("bangchu")
        ) {
          let txt = helpers.dateToWord(value || "");
          form.setFieldsValue({ [x.dataKeyAscii]: txt });
          return {
            ...x,
            dataValue: txt,
          };
        }
        // Tự động điền trường loại đăng ký cho KS
        if (
          x.dataKeyAscii ===
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.loaiDangKy &&
          (changeLoaiDangKy || changeLoaiDangKyKT)
        ) {
          form.setFieldsValue({
            [x.dataKeyAscii]: loaiDangKy ? loaiDangKy : undefined,
          });
          return {
            ...x,
            dataValue: loaiDangKy ? loaiDangKy : undefined,
          };
        }

        if (x.dataKeyAscii === soGiayToTuyThanThayDoi) {
          return {
            ...x,
            format: patternSoGiayToTuyThan ? patternSoGiayToTuyThan : undefined,
          };
        }

        return x;
      })
    );
  };

  const handleChangePaternLoaiGiayTo = (value: string) => {
    let pattern = "";
    switch (value) {
      // CMND
      case "1":
        pattern = "^[0-9]{9}$";
        break;
      // Ho chieu
      case "2":
        pattern = "^[a-zA-Z]\\d{7}$";
        break;
      // the thuong tru
      case "3":
        pattern = "";
        break;
      // CCCD
      case "4":
        pattern = "^[0-9]{12}$";
        break;
      // Giấy chứng minh quân đội đội nhân dân
      case "5":
        pattern = "^[a-zA-Z0-9]{12}$";
        break;
      // Giấy chứng minh sĩ quan quân đội
      case "6":
        pattern = "^[a-zA-Z0-9]{8}$";
        break;
      // Giấy chứng minh công an nhân dân
      case "7":
        pattern = "^[0-9]{6}$";
        break;
      // Giấy tờ khác
      case "8":
      case "10":
      case "11":
        pattern = "";
        break;
      default:
        // mặc định 12 số
        pattern = "^[0-9]{12}$";
        break;
    }
    return pattern;
  };

  const getMaxLengthPaper = (key: string) => {
    if (key?.includes("SoGiayToTuyThan")) {
      let paperFieldName =
        getPrefix(key, "SoGiayToTuyThan") + "LoaiGiayToTuyThan";
      let type = form.getFieldValue(paperFieldName);

      switch (type) {
        case "1":
          return 9;
        case "2":
        case "6":
          return 8;
        case "3":
        case "8":
        case "10":
        case "11":
          return 0;
        case "4":
        case "5":
        case "9":
          return 12;
        case "7":
          return 6;
        default:
          return 0;
      }
    }

    return 0;
  };

  // Chặn kí tự tùy theo loại giấy tờ
  const handlePaperOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    key: string
  ) => {
    if (key?.includes("SoGiayToTuyThan")) {
      let paperFieldName =
        getPrefix(key, "SoGiayToTuyThan") + "LoaiGiayToTuyThan";
      let type = form.getFieldValue(paperFieldName);

      switch (type) {
        // Chỉ cho phép nhập số
        case "1":
        case "4":
        case "7":
          form.setFieldValue(key, e.target.value?.replace(/\D/g, ""));
          break;
        // Chỉ cho phép nhập số và chữ:
        case "2":
        case "5":
        case "6":
          form.setFieldValue(key, e.target.value?.replace(/[^0-9a-zA-Z]/g, ""));
          break;
        default:
          form.setFieldValue(key, e.target.value);
          break;
      }
    }
  };

  const handleRegisterEnterManually = (value: string, key: string) => {
    setEnterManuallyInput((v) => ({ ...v, [key]: value }));
  };

  const [position, setPosition] = useState([]);
  const refSelectPosition = useRef<any>();

  const isDisabledOkButton = useMemo(() => {
    return data.type === 0
      ? !enterManuallyInput["chucVuNguoiKy"]
      : position.length === 0;
  }, [data.type, enterManuallyInput, position]);

  const handleAddEnterManually = (item: any) => {
    // if (enterManuallyInput[item.dataKeyAscii]) {
    form.setFieldsValue({
      [item.dataKeyAscii]: enterManuallyInput[item.dataKeyAscii] || "",
    });
    setKeyMaps(
      (keyMaps || []).map((x: any, i: number) => {
        if (x.dataKeyAscii === item.dataKeyAscii) {
          let newData: any[] = [];
          if (data.type === 0) {
            newData = [
              ...x.data,
              { id: enterManuallyInput[item.dataKeyAscii].trim(), name: "" },
            ];
          } else if (data.type === 1) {
            newData = x.data.filter((el: any) =>
              position.every((val) => el.id !== val)
            );
          }
          return {
            ...x,
            dataValue: enterManuallyInput[item.dataKeyAscii] || "",
            data: newData,
          };
        }
        return x;
      })
    );
    // }
    if (user?.isAdmin) {
      let payload = {};
      if (data.type === 0) {
        payload = {
          type: fieldConfigResponse?.data?.type,
          year: fieldConfigResponse?.data?.year,
          status: data.type,
          subFieldKeyMap: {
            id: enterManuallyInput[item.dataKeyAscii].trim(),
            name: "",
          },
        };
      } else {
        payload = {
          type: fieldConfigResponse?.data?.type,
          year: fieldConfigResponse?.data?.year,
          status: data.type,
          ids: position.join(","),
        };
      }

      triggerUpdate(payload)
        .unwrap()
        .then((res) => {
          if (res.statusCodeValue === 200) {
            notification.success({
              message: `${
                data.type === 0 ? "Thêm" : "Xóa"
              } Chức vụ người ký thành công!`,
            });
          }
        })
        .catch(() => {
          notification.error({
            message: `${
              data.type === 0 ? "Thêm" : "Xóa"
            } Chức vụ người ký thất bại!`,
          });
        })
        .finally(() => {
          if (data.type === 0) {
            setEnterManuallyInput((v) => ({
              ...v,
              [item.dataKeyAscii]: "",
            }));
          } else {
            form.resetFields([item.dataKeyAscii]);
          }
          setData({ isOpen: false, type: 0, payload: null });
          refSelectPosition.current.focus();
        });
    }
    setIsOpenEnterManuallyInput((v) => ({ ...v, [item.dataKeyAscii]: false }));
  };

  const handleOnSelectHasEnterManually = (key: string) => {
    setEnterManuallyInput((v) => ({ ...v, [key]: "" }));
  };

  // Chuyển trường dữ liệu thành item
  // <ExclamationCircleOutlined title="Đã duyệt qua" style={{ marginLeft: 5, color: "#faad14" }} />
  const convertTypeDataToElement = (item: FieldConfig, index: number) => {
    if (item.data && item.data.length) {
      return (
        <Form.Item
          name={item.dataKeyAscii}
          style={{ marginBottom: 10 }}
          label={
            <div style={{ display: "flex" }}>
              {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                <CheckCircleOutlined
                  title="Đã duyệt qua"
                  color="success"
                  style={{ margin: 5, color: "#52c41a" }}
                />
              ) : (
                <></>
              )}
              <div
                style={{
                  fontWeight: 700,
                  wordBreak: "break-word",
                }}
              >
                {index + 1}.{item.dataKey || ""}
              </div>
            </div>
          }
          rules={createRules(item)}
          validateFirst
          validateTrigger="onChange"
        >
          {[DEFAULT_FIELD_CONFIG.noiDangKy, "chucVuNguoiKy"].includes(
            item.dataKeyAscii
          ) ? (
            <Select
              open={isOpenEnterManuallyInput[item.dataKeyAscii]}
              onDropdownVisibleChange={(visible) =>
                setIsOpenEnterManuallyInput((v) => ({
                  ...v,
                  [item.dataKeyAscii]: visible,
                }))
              }
              ref={refSelectPosition}
              onSelect={() => handleOnSelectHasEnterManually(item.dataKeyAscii)}
              disabled={!item.isEdit}
              allowClear
              placeholder={item.placeholder || "--Lựa chọn--"}
              optionFilterProp="label"
              options={(item.data || []).map((x: any) => {
                return {
                  label: `${x.id ? x.id : ""}`,
                  value: `${x.id ? x.id : ""}`,
                };
              })}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {user?.isAdmin && (
                    <>
                      <Divider style={{ margin: "8px 0" }} />
                      <Modal
                        title={
                          data.type === 0
                            ? "Thêm mới Chức vụ người ký"
                            : "Xóa Chức vụ người ký"
                        }
                        open={data.isOpen}
                        onOk={() => handleAddEnterManually(item)}
                        confirmLoading={loadingPosition}
                        onCancel={() =>
                          setData({ isOpen: false, type: 0, payload: null })
                        }
                        centered
                        okText="Xác nhận"
                        cancelText="Hủy bỏ"
                        okButtonProps={{
                          disabled: isDisabledOkButton,
                        }}
                      >
                        <div style={{ padding: "0 8px 4px", width: "100%" }}>
                          {data.type === 0 ? (
                            <Input
                              style={{ width: "100%" }}
                              allowClear
                              value={enterManuallyInput[item.dataKeyAscii]}
                              placeholder="Nhập Chức vụ"
                              maxLength={100}
                              autoComplete="on"
                              onChange={(e: any) =>
                                handleRegisterEnterManually(
                                  e.target.value,
                                  item.dataKeyAscii
                                )
                              }
                            />
                          ) : (
                            <Select
                              showSearch
                              showArrow
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Chọn Chức vụ để xóa"
                              onChange={(value) => setPosition(value)}
                              options={data.payload?.map((item: any) => ({
                                label: item.id,
                                value: item.id,
                              }))}
                            />
                          )}
                        </div>
                      </Modal>
                      <Button
                        style={{ marginRight: "10px" }}
                        type="primary"
                        onClick={() => {
                          setData({
                            isOpen: !data.isOpen,
                            type: 0,
                            payload: item.data,
                          });
                          setIsOpenEnterManuallyInput((v) => ({
                            ...v,
                            [item.dataKeyAscii]: false,
                          }));
                        }}
                        icon={<PlusOutlined />}
                      >
                        Thêm
                      </Button>
                      <Button
                        danger
                        icon={<CloseOutlined />}
                        onClick={() => {
                          setData({
                            isOpen: !data.isOpen,
                            type: 1,
                            payload: item.data,
                          });
                          setIsOpenEnterManuallyInput((v) => ({
                            ...v,
                            [item.dataKeyAscii]: false,
                          }));
                        }}
                      >
                        Xóa
                      </Button>
                    </>
                  )}
                </>
              )}
              showSearch
              showArrow
              onChange={(value) => changeData(value, index, item)}
              // value={item.dataValue}
              onBlur={() => onBlurItem(item)}
            ></Select>
          ) : (
            <Select
              disabled={!item.isEdit || disabledField(item.dataKeyAscii)}
              allowClear
              placeholder={item.placeholder || "--Lựa chọn--"}
              optionFilterProp="label"
              options={(item.data || []).map((x: any) => ({
                label: `${x.id}${x.name ? " - " + x.name : ""}`,
                value: x.id + "",
              }))}
              showSearch
              showArrow
              onChange={(value) => changeData(value, index, item)}
              // value={item.dataValue}
              onBlur={() => onBlurItem(item)}
            ></Select>
          )}
        </Form.Item>
      );
    }
    if (
      item.isDefault &&
      fieldConfigResponse?.data?.type &&
      (!item.data || !item.data.length)
    ) {
      switch (item.dataKeyAscii) {
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh:
          return (
            <Form.Item
              validateTrigger="onChange"
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              label={
                <div style={{ display: "flex" }}>
                  {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                    <CheckCircleOutlined
                      title="Đã duyệt qua"
                      color="success"
                      style={{ margin: 5, color: "#52c41a" }}
                    />
                  ) : (
                    <></>
                  )}
                  <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                    {index + 1}.{item.dataKey || ""}
                  </div>
                </div>
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                // disabled={!item.isEdit}
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(responseProvince?.data || []).map((item) => ({
                  label: item.provinceName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                onChange={(value) => changeData(value, index, item)}
                // value={item.dataValue}
                onBlur={() => onBlurItem(item)}
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen:
          return (
            <Form.Item
              validateTrigger="onChange"
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              label={
                <div style={{ display: "flex" }}>
                  {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                    <CheckCircleOutlined
                      title="Đã duyệt qua"
                      color="success"
                      style={{ margin: 5, color: "#52c41a" }}
                    />
                  ) : (
                    <></>
                  )}
                  <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                    {index + 1}.{item.dataKey || ""}
                  </div>
                </div>
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                // disabled={!item.isEdit}
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(districts || []).map((item) => ({
                  label: item.districtName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                onChange={(value) => changeData(value, index, item)}
                // value={item.dataValue}
                onBlur={() => onBlurItem(item)}
              ></Select>
            </Form.Item>
          );
        case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa:
          return (
            <Form.Item
              validateTrigger="onChange"
              name={item.dataKeyAscii || ""}
              style={{ marginBottom: 10 }}
              label={
                <div style={{ display: "flex" }}>
                  {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
                    <CheckCircleOutlined
                      title="Đã duyệt qua"
                      color="success"
                      style={{ margin: 5, color: "#52c41a" }}
                    />
                  ) : (
                    <></>
                  )}
                  <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                    {index + 1}.{item.dataKey || ""}
                  </div>
                </div>
              }
              rules={[{ required: true, message: "Trường dữ liệu bắt buộc" }]}
            >
              <Select
                // disabled={!item.isEdit}
                disabled
                allowClear
                placeholder={item.placeholder || "--Lựa chọn--"}
                optionFilterProp="label"
                options={(communes || []).map((item) => ({
                  label: item.communeName,
                  value: item.id,
                }))}
                showSearch
                showArrow
                onChange={(value) => changeData(value, index, item)}
                // value={item.dataValue}
                onBlur={() => onBlurItem(item)}
              ></Select>
            </Form.Item>
          );
      }
    }
    return (
      <Form.Item
        validateTrigger="onChange"
        name={item.dataKeyAscii}
        style={{ marginBottom: 10 }}
        label={
          <div style={{ display: "flex" }}>
            {listActive.indexOf(item.dataKeyAscii) !== -1 ? (
              <CheckCircleOutlined
                title="Đã duyệt qua"
                color="success"
                style={{ margin: 5, color: "#52c41a" }}
              />
            ) : (
              <></>
            )}
            <div style={{ fontWeight: 700, wordBreak: "break-word" }}>
              {index + 1}.{item.dataKey || ""}
            </div>
          </div>
        }
        rules={createRules(item)}
        validateFirst
      >
        <Input.TextArea
          autoComplete="on"
          autoFocus={
            item.dataKeyAscii ===
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.so
          }
          placeholder={item.placeholder || ""}
          disabled={
            !item.isEdit ||
            item.dataKeyAscii ===
              DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo ||
            disabledField(item.dataKeyAscii)
          }
          onFocus={(e) => e.currentTarget.select()}
          maxLength={
            MAX_LENGTH[item.dataKeyAscii] ||
            getMaxLengthPaper(item.dataKeyAscii) ||
            item.maxLength ||
            50
          }
          // onChange={(e) => changeData(e.target.value, index, item)}
          // value={item.dataValue}
          onChange={(e) => handlePaperOnChange(e, item.dataKeyAscii)}
          onBlur={(e) => updateData(e.target.value, index, item)}
        />
      </Form.Item>
    );
  };

  const onBlurItem = (item: any) => {
    if (listActive.indexOf(item.dataKeyAscii) === -1) {
      setListActive([...listActive, item.dataKeyAscii]);
    }

    form.validateFields([item.dataKeyAscii]);
  };

  // Format lại dữ liệu sau khi nhập liệu
  const updateData = (value: any, index: number, item: FieldConfig) => {
    if (item.dataKeyAscii === "so" || item.dataKeyAscii === "trangSo") {
      value = helpers.autoFillSoField(value);
    } else {
      value = helpers.toTitleCaseFirstChar(value);
    }
    if (item.type === "date") {
      value = helpers.customDate(value);
      changeData(value, index, item);
    } else if (item.type === "time") {
      value = helpers.customTime(value, item.format);
      changeData(value, index, item);
    } else if (item.format?.toLowerCase().includes("capitalcase")) {
      value = helpers.toTitleCase(value);
      changeData(value, index, item);
    }
    changeData((value || "").trim(), index, item);
    form.validateFields([item.dataKeyAscii]);
    onBlurItem(item);
  };

  const ngayDangKyValue = Form.useWatch("ngayDangKy", form);

  // Tạo rules validate cho trường dữ liệu
  const createRules = (item: any) => {
    let rules: Object[] = [];
    if (item.isDefault) {
      rules = [
        ...rules,
        { required: true, message: "Trường dữ liệu bắt buộc" },
      ];
    }
    if (item.format && item.format !== "capitalcase") {
      rules = [
        ...rules,
        {
          pattern: new RegExp(item.format),
          message: "Dữ liệu không đúng định dạng",
        },
      ];
    }

    if (item.dataKeyAscii === "so") {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (tableData?.some((tableItem) => tableItem?.so === value)) {
              return Promise.reject(new Error("THHT đã nhập dữ liệu"));
            }

            let pattern = new RegExp(/[0-9]{1,}/g);
            if (!pattern.test(value)) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }

            return Promise.resolve();
          },
        }),
      ];
    }

    // Check ngày không hợp lệ
    if (item.type === "date") {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            let pattern = new RegExp(item.format);

            const arr = [
              "nksNgaySinh",
              "nktNgaySinh",
              "nxnNgayCapGiayToTuyThan",
              "nycNgayCapGiayToTuyThan",
              "gbtNgay",
              "ngayXacLapQuanHeHonNhan",
              "huyKetHonNgayGhiChu",
              "congNhanKetHonNgayGhiChu",
              "chongNgayCapGiayToTuyThan",
              "voNgayCapGiayToTuyThan",
              "cmNgayCapGiayToTuyThan",
              "ncNgayCapGiayToTuyThan",
            ];

            // Check theo 3 format YYYY, MM.YYYY, DD.MM.YYYY
            if (
              arr.includes(item.dataKeyAscii) &&
              value &&
              !moment(value, ["YYYY", "MM.YYYY", "DD.MM.YYYY"]).isValid()
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            } else if (
              !arr.includes(item.dataKeyAscii) &&
              item.type === "date" &&
              value &&
              !moment(value, "DD.MM.YYYY").isValid() &&
              pattern.test(value)
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "nxnNgaySinh" &&
      ngayDangKyValue &&
      fieldConfigResponse?.data?.type === "HN"
    ) {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (
              value &&
              !moment(value, ["YYYY", "MM.YYYY", "DD.MM.YYYY"]).isValid()
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }

            if (value && helpers.isAfterDate(value, ngayDangKyValue, false)) {
              return Promise.reject(
                new Error(
                  "Ngày sinh của người xác nhận không lớn hơn ngày đăng ký"
                )
              );
            }

            if (
              value &&
              form.getFieldValue("nxnGioiTinh") === "1" &&
              !isMinimumAge(value, ngayDangKyValue, 20)
            ) {
              return Promise.reject(
                new Error("Người xác nhận chưa đủ 20 tuổi")
              );
            }

            if (
              value &&
              form.getFieldValue("nxnGioiTinh") === "2" &&
              !isMinimumAge(value, ngayDangKyValue, 18)
            ) {
              return Promise.reject(
                new Error("Người xác nhận chưa đủ 18 tuổi")
              );
            }

            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "nktNgayChet" &&
      ngayDangKyValue &&
      fieldConfigResponse?.data?.type === "KT"
    ) {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (
              value &&
              !moment(value, ["YYYY", "MM.YYYY", "DD.MM.YYYY"]).isValid()
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }

            if (value && helpers.isAfterDate(value, ngayDangKyValue, false)) {
              return Promise.reject(
                new Error(
                  "Ngày chết của người khai tử không lớn hơn ngày đăng ký"
                )
              );
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "nksNgaySinh" &&
      ngayDangKyValue &&
      fieldConfigResponse?.data?.type === "KS"
    ) {
      const meNgaySinh = form.getFieldValue("meNgaySinh");
      const chaNgaySinh = form.getFieldValue("chaNgaySinh");
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (
              value &&
              !moment(value, ["YYYY", "MM.YYYY", "DD.MM.YYYY"]).isValid()
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }

            if (
              value &&
              ngayDangKyValue &&
              helpers.isAfterDate(value, ngayDangKyValue, false)
            ) {
              console.log("1");
              return Promise.reject(
                new Error(
                  "Ngày sinh của người khai sinh không lớn hơn ngày đăng ký"
                )
              );
            }

            if (
              value &&
              meNgaySinh &&
              !helpers.isAfterDate(value, meNgaySinh, false)
            ) {
              console.log("2", "value", value, "meNgaySinh", meNgaySinh);
              return Promise.reject(
                new Error(
                  "Ngày sinh của người khai sinh không nhỏ hơn ngày sinh cha/ngày sinh mẹ"
                )
              );
            }

            if (
              value &&
              chaNgaySinh &&
              !helpers.isAfterDate(value, chaNgaySinh, false)
            ) {
              console.log("2", "value", value, "chaNgaySinh", chaNgaySinh);
              return Promise.reject(
                new Error(
                  "Ngày sinh của người khai sinh không nhỏ hơn ngày sinh cha/ngày sinh mẹ"
                )
              );
            }

            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      item.dataKey === "ncNgaySinh" &&
      ngayDangKyValue &&
      fieldConfigResponse?.data?.type === "CMC"
    ) {
      const cmNgaySinh = form.getFieldValue("cmNgaySinh");
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (value && helpers.isAfterDate(value, ngayDangKyValue, false)) {
              return Promise.reject(
                new Error("Ngày sinh của người con không lớn hơn ngày đăng ký")
              );
            }
            if (value && !helpers.isAfterDate(value, cmNgaySinh, true)) {
              return Promise.reject(
                new Error(
                  "Ngày sinh của người con không nhỏ hơn ngày sinh cha/ngày sinh mẹ"
                )
              );
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    if (
      (item.dataKey === "chongNgaySinh" || item.dataKey === "voNgaySinh") &&
      fieldConfigResponse?.data?.type === "KH"
    ) {
      rules = [
        ...rules,
        () => ({
          validator(_: any, value: any) {
            if (
              value &&
              !moment(value, ["YYYY", "MM.YYYY", "DD.MM.YYYY"]).isValid()
            ) {
              return Promise.reject(new Error("Dữ liệu không đúng định dạng"));
            }

            if (
              value &&
              item.dataKey === "voNgaySinh" &&
              !isMinimumAge(value, ngayDangKyValue, 18)
            ) {
              return Promise.reject(
                new Error("Vợ chưa đủ độ tuổi kết hôn (Chưa đủ 18 tuổi)")
              );
            }

            if (
              value &&
              item.dataKey === "chongNgaySinh" &&
              !isMinimumAge(value, ngayDangKyValue, 20)
            ) {
              return Promise.reject(
                new Error("Chồng chưa đủ độ tuổi kết hôn (Chưa đủ 18 tuổi)")
              );
            }
            return Promise.resolve();
          },
        }),
      ];
    }

    return rules;
  };

  const resetData = () => {
    if (fieldConfigResponse?.data?.fieldKeyMap?.length) {
      setKeyMaps(fieldConfigResponse?.data?.fieldKeyMap);
    } else {
      setKeyMaps([]);
    }
    form.resetFields();
  };

  const onSubmitSuccess = (message?: string) => {
    updateStatusPdf({
      id: filePdfResponse?.id,
      isCheck: true,
      message,
      status:
        (!filePdfResponse?.numberCheck &&
          !detailResponse?.currentData?.numberCheck) ||
        year === "A4",
    })
      .unwrap()
      .then((res: any) => {
        resetData();
        setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
        refetch();
        triggerFilePdfId(null);
        toggleConfirmSuccessModal();
        setListActive([]);
        notification.success({
          message: "Bạn đã báo lỗi thành công!",
        });
      })
      .catch(() => {
        notification.error({
          message: "Bạn đã báo lỗi thất bại!",
        });
      });
  };

  const passFile = () => {
    resetData();
    setInitData(fieldConfigResponse?.data?.fieldKeyMap, undefined);
    refetch();
    setListActive([]);
    if (detailResponse?.currentData?.id) {
      triggerFilePdfId(null);
    }
  };

  const checkActiveSave = () => {
    let check = true;

    let arr: string[] = [];
    if (nksLoaiKhaiSinh && fieldConfigResponse?.data?.type === "KS") {
      if (nksLoaiKhaiSinh === "2") {
        arr = [
          "meHoTen",
          "meNgaySinh",
          "meDanToc",
          "meQuocTich",
          "meQuocTichKhac",
          "meNoiCuTru",
          "meLoaiGiayToTuyThan",
          "meSoGiayToTuyThan",
        ];
      } else if (nksLoaiKhaiSinh === "3") {
        arr = [
          "chaHoTen",
          "chaNgaySinh",
          "chaDanToc",
          "chaQuocTich",
          "chaQuocTichKhac",
          "chaNoiCuTru",
          "chaLoaiGiayToTuyThan",
          "chaSoGiayToTuyThan",
        ];
      } else if (nksLoaiKhaiSinh === "4") {
        arr = [
          "meHoTen",
          "meNgaySinh",
          "meDanToc",
          "meQuocTich",
          "meQuocTichKhac",
          "meNoiCuTru",
          "meLoaiGiayToTuyThan",
          "meSoGiayToTuyThan",
          "chaHoTen",
          "chaNgaySinh",
          "chaDanToc",
          "chaQuocTich",
          "chaQuocTichKhac",
          "chaNoiCuTru",
          "chaLoaiGiayToTuyThan",
          "chaSoGiayToTuyThan",
        ];
      }
    }

    keyMaps
      .filter((x) => x.isEdit && x.isDisplay && !arr.includes(x.dataKeyAscii))
      .forEach((item) => {
        if (
          item.dataKeyAscii !==
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh &&
          item.dataKeyAscii !==
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen &&
          item.dataKeyAscii !==
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa &&
          item.dataKeyAscii !==
            DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo &&
          listActive.indexOf(item.dataKeyAscii) === -1
        )
          check = false;
      });
    return check;
  };

  const changeSize = (size: number) => {
    if (displayFlex) {
      setWidth(size);
      setClientY(clientY * (size / width));
    } else {
      setHeight(size);
      setClientY(clientY * (size / height));
    }
    setIsRendered(false);
  };

  const autoFillLoaiDangKy = (ngayDangKy: string, ngaySinh: string) => {
    let tempMoment = moment("01.01.2016", "DD.MM.YYYY").toDate().getTime();
    let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
    let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
    let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
    // if (ngayDangKyDate < tempMoment) return "5";
    // if (ngayDangKyDate >= tempMoment && ngayDangKyDate <= moment().valueOf())
    //   return "2";
    if (timeCompare < 0 || ngayDangKyDate >= tempMoment) return undefined;
    if (timeCompare <= 60) return "1";
    if (timeCompare / 365 > 10) return "2";
    if (timeCompare > 60) return "4";
  };

  const autoFillLoaiDangKyKT = (ngayDangKy: string, ngayChet: string) => {
    let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
    let ngayChetDate = moment(ngayChet, "DD.MM.YYYY").toDate().getTime();
    let timeCompare = (ngayDangKyDate - ngayChetDate) / (1000 * 60 * 60 * 24);
    if (timeCompare < 0) return undefined;
    if (timeCompare <= 15) return "1";
    if (timeCompare / 365 > 10) return "2";
    if (timeCompare > 15) return "4";
  };

  return (
    // <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
    <>
      <div
        className="content-box"
        style={{ margin: "0px 20px", padding: "20px" }}
      >
        {isShowTableData ? (
          <>
            <DragAndDropTable
              toggleEditDataModal={() => {}}
              setCurrentDataInitActive={() => {}}
              setTableData={setTableData}
              tableData={tableData}
              columns={columns}
            />
          </>
        ) : (
          <></>
        )}
        <div className="body-block-create-info-init" ref={wrapperDiv}>
          <SplitPane
            split={displayFlex ? "vertical" : "horizontal"}
            size={displayFlex ? width : height}
            minSize={"50%"}
            onChange={(size) => changeSize(size)}
            maxSize={"100%"}
          >
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      // resize: "horizontal",
                      overflow: "auto",
                    }
                  : {
                      // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                      order: orderNumber,
                      padding: 10,
                      width: "100%",
                      overflow: "auto",
                    }
              }
            >
              <div className="header-block">
                <div>
                  <label
                    style={{
                      backgroundColor: "#fefbd8",
                      fontWeight: "bold",
                      fontSize: 10,
                    }}
                    className="search-title"
                  >
                    {filePdfResponse?.pathFile || ""}
                  </label>
                </div>
                <div>
                  <ZoomOut
                    onClick={() =>
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return scale === 1 ? 1 : scale - 1;
                      })
                    }
                    style={{
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      marginRight: 20,
                      marginBottom: -5,
                    }}
                  />
                  <ZoomIn
                    onClick={() =>
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return scale + 1;
                      })
                    }
                    style={{
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      marginRight: 15,
                      marginBottom: -5,
                    }}
                  />
                  <Rotate
                    onClick={() => setRotate(rotate - 90)}
                    style={{
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      marginRight: 15,
                      marginBottom: -5,
                    }}
                  />
                  <Top
                    onClick={() => changeLayout(false, 1)}
                    style={{
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      marginRight: 15,
                      marginBottom: -5,
                    }}
                  />
                  <Right
                    onClick={() => changeLayout(true, 3)}
                    style={{
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      marginRight: 15,
                      marginBottom: -5,
                    }}
                  />
                  {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: -5 }} /> */}
                  <Left
                    onClick={() => changeLayout(true, 1)}
                    style={{
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      marginRight: 15,
                      marginBottom: -5,
                    }}
                  />
                  {/* <label
                    onClick={() => {
                      toggleViewPdfModal();
                      setIndexCompare(-1);
                    }}
                    style={{ color: "#1890ff", cursor: "pointer" }}
                    className="search-title"
                  >
                    file trước
                  </label>{" "}
                  |{" "}
                  <label
                    onClick={() => {
                      toggleViewPdfModal();
                      setIndexCompare(1);
                    }}
                    style={{ color: "#1890ff", cursor: "pointer" }}
                    className="search-title"
                  >
                    file sau
                  </label> */}
                </div>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  height: displayFlex
                    ? "calc(100vh - 160px)"
                    : "calc(100% - 55px)",
                }}
                id="block-view-pdf"
              >
                <TransformWrapper
                  maxScale={3}
                  initialScale={1}
                  disablePadding={true}
                  ref={transformComponentRef}
                  onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                    if (ref.state.scale >= 3) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev + 1;
                      });
                    } else if (ref.state.scale <= 1) {
                      setScale((prev: number) => {
                        prevScale.current = prev;
                        return prev - 1 || 1;
                      });
                    }
                  }}
                >
                  <TransformComponent>
                    {/* <iframe title="image" src="http://42.1.67.101:8082/CC.2007.01.00007.pdf"/> */}
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(
                        new Array(Math.round(numPages / 2)),
                        (el, index) => (
                          <div
                            style={{ display: "flex" }}
                            key={`grouppage${index + 1}`}
                          >
                            <Page
                              width={width}
                              // height={height}
                              rotate={rotate}
                              scale={scale}
                              canvasRef={canvas}
                              key={`page_${index * 2 + 1}`}
                              pageNumber={index * 2 + 1}
                              onRenderSuccess={onRenderSuccess}
                              onClick={onClickPage}
                            />
                            {index * 2 + 2 <= numPages ? (
                              <Page
                                width={width}
                                // height={height}
                                rotate={rotate}
                                scale={scale}
                                key={`page_${index * 2 + 2}`}
                                pageNumber={index * 2 + 2}
                                canvasRef={canvas1}
                                onRenderSuccess={onRenderSuccess}
                                onClick={onClickPage}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        )
                      )}
                    </Document>
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
            <div
              style={
                displayFlex
                  ? {
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      width: "100%",
                      boxSizing: "border-box",
                      position: "absolute",
                      // resize: "horizontal",
                      // overflow: "auto"
                    }
                  : {
                      // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                      order: orderNumber === 1 ? 3 : 1,
                      padding: 10,
                      width: "100%",
                      boxSizing: "border-box",
                      // position: "absolute"
                    }
              }
            >
              <Form
                name="searchBox"
                autoComplete="off"
                form={form}
                colon={false}
                style={{ marginTop: 0 }}
                layout="vertical"
                labelWrap
                labelAlign="left"
              >
                <label
                  style={{ backgroundColor: "#fefbd8" }}
                  className="search-title"
                >
                  Dữ liệu THHT: {filePdfResponse ? pdfFileName : ""}
                </label>
                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "calc(100vh - 215px)",
                    paddingRight: 10,
                  }}
                >
                  <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                    {keyMaps.length &&
                      keyMaps
                        .filter((x) => x.isDisplay)
                        .map((item: any, index) => {
                          return (
                            <Col span={8} key={item.dataKeyAscii}>
                              {convertTypeDataToElement(item, index)}
                            </Col>
                          );
                        })}
                  </Row>
                </div>
              </Form>
              {filePdfResponse?.id ? (
                <div className="search-box-button" style={{ marginTop: 15 }}>
                  {/* <Button onClick={() => passFile()}>Bỏ qua</Button> */}
                  <Button
                    onClick={() => toggleClearDataModal()}
                    htmlType="button"
                  >
                    Xóa ô nhập liệu
                  </Button>
                  <Button
                    disabled={!checkActiveSave()}
                    type="primary"
                    onClick={onFinish}
                  >
                    Lưu dữ liệu
                  </Button>
                  <Button
                    type="primary"
                    style={
                      (!filePdfResponse?.numberCheck &&
                        !detailResponse?.currentData?.numberCheck) ||
                      year === "A4"
                        ? { backgroundColor: "#ff4d4f", border: "#ff4d4f" }
                        : { backgroundColor: "#49cc90", border: "#49cc90" }
                    }
                    onClick={toggleConfirmSuccessModal}
                  >
                    {(!filePdfResponse?.numberCheck &&
                      !detailResponse?.currentData?.numberCheck) ||
                    year === "A4"
                      ? "Báo lỗi"
                      : `Nhập đủ (${
                          detailResponse?.currentData?.numberCheck ||
                          filePdfResponse?.numberCheck
                        })`}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </SplitPane>
        </div>
      </div>
      {/* okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"} */}
      {confirmVisible ? (
        <ConfirmModal
          cancelText="Huỷ bỏ"
          okText={
            year === "A4" || numberTHHTRemain === 1
              ? undefined
              : "Lưu (Nhập THHT khác)"
          }
          note={
            year === "A4"
              ? undefined
              : "*Chú ý: Lưu (Nhập THHT khác) chỉ áp dụng cho ảnh có nhiều trường hợp hộ tịch"
          }
          okSuccess={
            year === "A4" || numberTHHTRemain === 1
              ? "Lưu (Chuyển file pdf khác)"
              : undefined
          }
          classBtnOk="ant-button-dangerous"
          contents={["Bạn có chắc chắn muốn lưu thông tin?"]}
          handleCancel={toggleConfirmModal}
          handleOk={() => onSubmitCheckData()}
          handleSuccess={() => onSubmitCheckData(true)}
          type={1}
          visible={confirmVisible}
          widthConfig={570}
          okBtnDanger={true}
          isLoading={isLoading}
        />
      ) : (
        <></>
      )}
      {confirmSuccessVisible ? (
        <ConfirmModal
          isMessage={true}
          cancelText="Huỷ bỏ"
          okText="Xác nhận"
          classBtnOk="ant-button-dangerous"
          contents={
            (!filePdfResponse?.numberCheck &&
              !detailResponse?.currentData?.numberCheck) ||
            year === "A4"
              ? ["Bạn có chắc chắn muốn báo cáo lỗi!"]
              : [
                  "Bạn có chắc chắn hoàn thành nhập liệu file này và chuyển sang file khác?",
                  "(Dữ liệu hiện tại đang nhập sẽ không được lưu)",
                ]
          }
          handleCancel={toggleConfirmSuccessModal}
          handleOk={onSubmitSuccess}
          type={1}
          visible={confirmSuccessVisible}
          widthConfig={500}
          okBtnDanger={true}
        />
      ) : (
        <></>
      )}
      {clearDataVisible ? (
        <ConfirmModal
          cancelText="Huỷ bỏ"
          okText="Xác nhận"
          classBtnOk="ant-button-dangerous"
          contents={["Bạn có chắc chắn muốn xóa ô nhập liệu!"]}
          handleCancel={toggleClearDataModal}
          handleOk={onAcceptClearData}
          type={1}
          visible={clearDataVisible}
          widthConfig={500}
          okBtnDanger={true}
        />
      ) : (
        <></>
      )}
      {/* {viewPdfVisible ? (
        <ViewFilePdf
          visible={viewPdfVisible}
          toggleModal={toggleViewPdfModal}
          filePdf={filePdfResponse}
          indexCompare={indexCompare}
        />
      ) : (
        <></>
      )} */}
    </>
  );
};

export default CreateDataInfo;
