import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tree,
  Upload,
} from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  useAddUserMutation,
  useEditUserMutation,
  useLazyGetByIdQuery,
  useLazyGetFullGroupUserQuery,
} from "../redux/userApi";
import { DatePicker } from "../../../custom-components";

import { ReactComponent as XIcon } from "../../../assets/logos/x-solid.svg";

import "../assets/scss/user.scss";
import {
  useLazyGetAllRoleQuery,
  useLazyGetFullRoleQuery,
} from "../../permissions/redux/permissionsApi";
import { useNavigate, useParams } from "react-router-dom";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import {
  DataRoleResponse,
  FullRoleResponse,
  Role,
  TreeRole,
} from "../../permissions/redux/apiTypes";
import {
  useDeleteFileMutation,
  useLazyGetFileByObjectIdAndTypeQuery,
  useUploadFileForObjectMutation,
  useUploadFileMutation,
} from "../../../redux/api/FileApi";
import confirm from "antd/lib/modal/confirm";
import {
  checkPermission,
  exportFile,
} from "../../common/assets/CommonFunctions";
import { arrayToTree } from "performant-array-to-tree";
import moment from "moment";
import { DataInfoContext } from "../../../layouts/context/DataInfoProvider";
import { ColumnsType } from "antd/es/table";
import { DataType } from "../../permissions/components/AddRole";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";
import { DataNode, TreeProps } from "antd/lib/tree";

const pattern =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const patternMessage =
  "Nhập mật khẩu tối thiểu 8 ký tự bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt.";

const CreateUser = () => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const [loadingFile, setLoadingFile] = useState(false);
  const [fileError, setFileError] = useState("");
  const [uploadFile] = useUploadFileMutation();
  const [fileList, setFileList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [deleteIds, setDeleteIds] = useState<any>([]);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const navigate = useNavigate();
  const params = useParams();

  const canEdit = checkPermission(
    PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.SUA
  );

  const [addUser] = useAddUserMutation();
  const [editUser] = useEditUserMutation();
  const [roleListTrigger, roleListResponse] = useLazyGetAllRoleQuery();
  const [fullGroupUserTrigger, fullGroupUserResponse] =
    useLazyGetFullGroupUserQuery();

  const [getFullRoleTrigger, fullRoleResponse] = useLazyGetFullRoleQuery();
  const [detailUserTrigger] = useLazyGetByIdQuery();

  const [uploadFileToObject] = useUploadFileForObjectMutation();
  // const [fileIdTrigger] = useLazyGetFileByObjectIdAndTypeQuery();
  const [deleteFile] = useDeleteFileMutation();

  const [treeData, setTreeData] = useState<any>([]);

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [originalKeys, setOriginalKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const formattedTreeData: DataNode[] = useMemo(() => {
    let newTreeData = treeData || [];
    if (treeData?.length && originalKeys?.length) {
      newTreeData?.forEach((item: any, index: number) => {
        // Disable cấp cha nếu tất cả con đã được tích
        if (
          item?.children?.every((subItem: any) =>
            originalKeys?.includes(subItem?.key)
          )
        ) {
          newTreeData[index].disableCheckbox = true;
        }

        // Disable cấp con
        item?.children?.forEach((subItem: any, subIndex: number) => {
          if (originalKeys?.includes(subItem?.key)) {
            newTreeData[index].children[subIndex].disableCheckbox = true;
          }
        });
      });
    }

    return newTreeData;
  }, [originalKeys, treeData]);

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setSelectedKeys(selectedKeysValue);
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue as React.Key[]);
  };

  useEffect(() => {
    fullGroupUserTrigger({});
    getFullRoleTrigger({})
      .unwrap()
      .then((res) => {
        let tree: TreeRole[] = [
          {
            title: "Quản trị hệ thống",
            key: "quanTriHeThong",
            id: "quanTriHeThong",
            authKey: "quanTriHeThong",
            authoritieName: "Quản trị hệ thống",
            description: "Quản trị hệ thống",
            children: [],
          },
          {
            title: "Quản lý danh mục",
            key: "danhMuc",
            id: "danhMuc",
            authKey: "danhMuc",
            authoritieName: "Quản lý danh mục",
            description: "Quản lý danh mục",
            children: [],
          },
        ];

        res.forEach((item: FullRoleResponse) => {
          let treeNode: TreeRole = {
            ...item,
            title: item.authoritieName,
            key: item.id,
            children: [],
          };
          if (
            ["Tỉnh thành", "Quận huyện", "Phường xã"].includes(
              item.authoritieName
            )
          ) {
            treeNode.parentId = "danhMuc";
          }
          if (
            ["Người dùng", "Nhóm người dùng", "Nhóm quyền"].includes(
              item.authoritieName
            )
          ) {
            treeNode.parentId = "quanTriHeThong";
          }
          if (item.authoritieName === "Tham số hệ thống") {
            treeNode.title = "Cấu hình trường thông tin";
            treeNode.authoritieName = "Cấu hình trường thông tin";
          }
          if (item.authoritieName === "Nhóm người dùng") {
            treeNode.title = "Nhóm người dùng";
            treeNode.authoritieName = "Nhóm người dùng";
          }
          if (item.authoritieName === "Nhóm quyền") {
            treeNode.title = "Nhóm quyền";
            treeNode.authoritieName = "Nhóm quyền";
          }
          if (item.authoritieName === "Người dùng") {
            treeNode.title = "Quản lý người dùng";
            treeNode.authoritieName = "Quản lý người dùng";
          }
          tree.push(treeNode);
        });

        const treeRes = arrayToTree(tree, { dataField: null });

        let sortIn = [
          "ROLE_SYSTEM_DATA_PDF",               // Danh sách file pdf,
          "ROLE_SYSTEM_DATA_ENTRY",             // Nhập liệu dữ liệu,
          "ROLE_SYSTEM_DATA_RECHECK",           // Đối chiếu dữ liệu,
          "ROLE_DATA_CHECK",                    // Kiểm tra dữ liệu,
          "ROLE_SYSTEM_DATA_ENTRY_DATA",        // Kho dữ liệu nhập liệu,
          "ROLE_SYSTEM_DATA_RECHECK_DATA",      // Kho đối chiếu nhập liệu,
          "ROLE_ARCHIVE_DATA_CHECK",            // Kho dữ liệu kiểm tra,
          "ROLE_SYSTEM_DATA_ENTRY_CAMPAIGN",    // Chiến dịch nhập liệu,
          "ROLE_SYSTEM_DATA_RECHECK_CAMPAIGN",  // Chiến dịch đối chiếu,
          "ROLE_SYSTEM_DATACAMPAIGN",           // Chiến dịch kiểm tra,
          "ROLE_REPORT",                        // Báo cáo,
          "ROLE_SYSTEM_PARAM",                  // Cấu hình trường thông tin,
          "ROLE_SYSTEM_AUTHORITY",              // Chức năng,
          "danhMuc",                            // Quản lý danh mục,
          "quanTriHeThong",                     // Quản trị hệ thống,
        ];

        let sortRes = sortIn
          .map((item) => {
            let idx = treeRes.findIndex((x) => x.authKey === item);
            if (idx !== -1) {
              return treeRes[idx];
            }

            return undefined;
          })
          .filter((item) => item !== undefined);

        sortRes.forEach((item) => {
          const idx = item?.children.findIndex(
            (x: any) => x.title.substring(0, 3) === "Xem"
          );
          item?.children.unshift(item?.children.splice(idx, 1)[0]);

          if (
            item?.title === "Quản trị hệ thống" ||
            item?.title === "Quản lý danh mục"
          ) {
            item.children.sort((a: TreeRole, b: TreeRole) =>
              b.title.localeCompare(a.title)
            );

            item?.children.forEach((a: TreeRole) => {
              const idx1 = a?.children.findIndex(
                (y: TreeRole) => y.title.substring(0, 3) === "Xem"
              );
              a?.children.unshift(a?.children.splice(idx1, 1)[0]);
            });
          }
        });

        let adminItem = treeRes?.find(
          (treeItem) => treeItem?.authoritieName === "Admin"
        );
        if (adminItem) {
          sortRes.push(adminItem);
        }
        // console.log(sortRes)
        setTreeData(sortRes);
      });
  }, [fullGroupUserTrigger, getFullRoleTrigger]);

  // // table
  // const _onExpandIconClick = (key: number) => {
  //     _toggleExpandByKey(key);
  // }

  // const _toggleExpandByKey = (key: number) => {
  //     const newExpandedRowKeys = expandedRowKeys.find(x => x === key)
  //         ? expandedRowKeys.filter(x => x !== key)
  //         : [...expandedRowKeys, key];
  //     setExpandedRowKeys(newExpandedRowKeys);
  // };

  // const handleOnChange = (e: any, record: any, type: string) => {
  //     const a = record.children.map((child: any) => child[type])

  //     let newFormValues = { ...form.getFieldsValue() };
  //     a.forEach((item: any) => {
  //         newFormValues[item] = e.target.checked
  //     })

  //     form.setFieldsValue(newFormValues)
  // }

  // const columns: ColumnsType<DataType> = [
  //     {
  //         title: 'Phân quyền chức năng',
  //         dataIndex: 'title',
  //         render: (text: string) => <a>{text}</a>,
  //     },
  //     {
  //         key: 'action',
  //         align: "center",
  //         width: 60,
  //         render: (record) => {
  //             const { key } = record;
  //             const expened = expandedRowKeys.find(x => x === key);
  //             return Array.isArray(record?.children) && (
  //                 <span onClick={(e) => e.stopPropagation()}>
  //                     {expened &&
  //                         <DownOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
  //                     {!expened &&
  //                         <RightOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
  //                 </span>
  //             );
  //         },
  //     },
  //     {
  //         title: 'Xem',
  //         dataIndex: 'view',
  //         render: (text: any, record: any) => {
  //             return (
  //                 <Form.Item name={record?.view} valuePropName="checked">
  //                     <Checkbox onChange={(e: any) => handleOnChange(e, record, 'view')} disabled />
  //                 </Form.Item>
  //             )
  //         },
  //         align: 'center'
  //     },
  //     {
  //         title: 'Thêm',
  //         dataIndex: 'add',
  //         render: (text: any, record: any) => (
  //             <Form.Item name={record?.add} valuePropName="checked">
  //                 <Checkbox onChange={(e: any) => handleOnChange(e, record, 'add')} disabled />
  //             </Form.Item>
  //         ),
  //         align: 'center'
  //     },
  //     {
  //         title: 'Sửa',
  //         dataIndex: 'edit',
  //         render: (text: any, record: any) => (
  //             <Form.Item name={record?.edit} valuePropName="checked">
  //                 <Checkbox onChange={(e: any) => handleOnChange(e, record, 'edit')} disabled />
  //             </Form.Item>
  //         ),
  //         align: 'center'
  //     },
  //     {
  //         title: 'Xoá',
  //         dataIndex: 'delete',
  //         render: (text: any, record: any) => (
  //             <Form.Item name={record?.delete} valuePropName="checked">
  //                 <Checkbox onChange={(e: any) => handleOnChange(e, record, 'delete')} disabled />
  //             </Form.Item>
  //         ),
  //         align: 'center'
  //     },
  // ];

  // initial data

  useEffect(() => {
    roleListTrigger({});
    getFullRoleTrigger({});
  }, [roleListTrigger]);

  useEffect(() => {
    if (params.id) {
      detailUserTrigger(params.id)
        .unwrap()
        .then((res) => {
          let newFormValues = {
            // ...form.getFieldsValue(),
            username: res.username,
            phoneNumber: res.phoneNumber,
            fullName: res.fullName,
            email: res.email,
            address: res.address,
            password: res.password,
            status: res.status,
            // role: res.roles?.[0].id,
            role: (res?.roles || []).map((role) => {
              return { label: role?.roleName || "", value: role.id };
            }),
            birthday: res.birthday,
            gender: res.gender,
            educationLevel: res.educationLevel,
            startWorkDate: res.startWorkDate,
            workType: res.workType,
            warningCount: res.warningCount,
            rewardCount: res.rewardCount,
            groupUsers: res.groupUsers,
          };

          let auIds: any[] = [];
          if (res.roles?.length) {
            const authList = res.roles.reduce((pre, cur, i) => {
              const authListCurrValue =
                cur?.authoritieLst?.map((role: Role) => role.id) || [];
              const newAuthListValue = [...authListCurrValue];

              authListCurrValue.forEach((value: any) => {
                auIds.push(value);
              });

              return newAuthListValue;
            }, []);
            // setCheckedKeys([...new Set(auIds)]);
          }

          if (res.groupUsers?.length) {
            auIds = [...auIds, ...res.roleByGroup];
            // setCheckedKeys([...new Set(newAuthListValue)]);
          }

          // Danh sách các quyền thuộc group và role ko được edit
          setOriginalKeys(auIds);

          // authoritieLst ko được tính vào originalKeys
          if (res?.authoritieLst?.length) {
            auIds = [...auIds, ...res.authoritieLst?.map((item) => item?.id)];
          }

          auIds = [...new Set(auIds)];
          setCheckedKeys(auIds);
          form.setFieldsValue(newFormValues);
        })
        .catch((err) => {
          notification.error({
            message: err.data?.message || "Có lỗi xảy ra!",
          });
        });

      // fileIdTrigger({ objectId: params?.id, objectType: 100 })
      //   .unwrap()
      //   .then((res) => {
      //     // console.log('file', res)
      //     if (res.length) {
      //       setLoading(true);
      //       let newFileList: any = [];

      //       Promise.all(
      //         res.map((item) =>
      //           fetch(
      //             process.env.REACT_APP_API_URL +
      //               "/api/v1/file/downloadFile/" +
      //               item.id,
      //             {
      //               headers: {
      //                 Authorization:
      //                   "Bearer " + localStorage.getItem("accessToken"),
      //               },
      //             }
      //           ).then((res) => {
      //             return res.blob().then((blob) => {
      //               return { ...item, blob };
      //             });
      //           })
      //         )
      //       ).then((res) => {
      //         let promises: any = [];
      //         res.forEach((item) => {
      //           let newPromise = new Promise((resolve) => {
      //             let reader = new FileReader();
      //             reader.readAsDataURL(item.blob);
      //             // reader.onload = () => resolve({ ...item, thumbUrl: reader.result });
      //             reader.onload = () => resolve(item);
      //           });

      //           promises.push(newPromise);
      //         });

      //         Promise.all(promises).then((fileContents) => {
      //           fileContents.forEach((item) => {
      //             newFileList.push({
      //               id: item.id,
      //               name: item.fileName,
      //               status: "done",
      //               thumbUrl: item.thumbUrl,
      //               response: item,
      //               fileExtension: item.fileExtension,
      //             });
      //           });
      //           setUploadedFiles(newFileList);
      //         });
      //         setLoading(false);
      //       });
      //     }
      //   });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailUserTrigger, params.id]);

  const onFinish = (values: any) => {
    deleteIds.forEach((id: string) => {
      if (typeof id === "string") deleteFile(id);
    });
    // let keys = Object.keys(values).filter(k => values[k] === true);
    // const authoritieList = fullRoleResponse?.data.filter((role: Role) => keys.includes(role.id));

    const authorities = fullRoleResponse?.data?.filter((role: Role) => {
      if (role?.id) {
        return checkedKeys?.includes(role.id);
      }
      return false;
    });
    // console.log(authorities?.map((item: any) => item?.authoritieName));
    const roles: DataRoleResponse[] = [];
    values?.role?.forEach((roleItem: string) => {
      const roleData = roleListResponse?.data?.find(
        (role) => role.id === roleItem
      );
      if (roleData) roles.push(roleData);
    });

    if (!params.id) {
      addUser({
        fullName: values.fullName?.trim() || "",
        phoneNumber: values.phoneNumber?.trim() || "",
        email: values.email?.trim() || "",
        address: values.address?.trim() || "",
        birthday: values.birthday
          ? new Date(values?.birthday)?.toISOString()
          : "",
        gender: values.gender,
        educationLevel: values.educationLevel?.trim() || "",
        startWorkDate: values.startWorkDate
          ? new Date(values?.startWorkDate)?.toISOString()
          : "",
        workType: values.workType || 0,
        warningCount: values.warningCount,
        rewardCount: values.rewardCount,
        // groupUsers: values.groupUsers,

        username: values.username?.trim() || "",
        password: values.password?.trim() || "",
        status: values.status || 0,
        // roles,
      })
        .unwrap()
        .then((res) => {
          notification.success({ message: "Tạo tài khoản thành công!" });
          navigate("/setting/user");
          // addFileToObject(res, values);
        })
        .catch((err) => {
          notification.error({
            message: err.data?.message || "Có lỗi xảy ra!",
          });
        });
    } else {
      editUser({
        id: params.id,

        fullName: values.fullName?.trim() || "",
        phoneNumber: values.phoneNumber?.trim() || "",
        email: values.email?.trim() || "",
        address: values.address?.trim() || "",
        birthday: values.birthday
          ? new Date(values?.birthday)?.toISOString()
          : "",
        gender: values.gender,
        educationLevel: values.educationLevel?.trim() || "",
        startWorkDate: values.startWorkDate
          ? new Date(values?.startWorkDate)?.toISOString()
          : "",
        workType: values.workType || 0,
        warningCount: values.warningCount,
        rewardCount: values.rewardCount,
        // groupUsers: values.groupUsers,
        authoritieLst: authorities,
        username: values.username?.trim() || "",
        password: values.password?.trim() || "",
        // status: values.status || 0,

        roles,
      })
        .unwrap()
        .then((res) => {
          notification.success({ message: "Chỉnh sửa tải khoản thành công!" });
          navigate("/setting/user");
          // addFileToObject(res, values);
        })
        .catch((err) => {
          notification.error({
            message: err.data?.message || "Có lỗi xảy ra!",
          });
        });
    }
  };

  // handle khi change role
  const onValuesChange = (changedValues: any, allValues: any) => {
    setCheckedKeys([]);
    if (changedValues.role) {
      // let keys = Object.keys(allValues).filter(k => allValues[k] === true);
      // const roles = roleListResponse?.data?.find(role => role.id === changedValues.role)?.authoritieLst?.map((child: any) => child['id']);
      // if (roles) {
      //     let newFormValues = { ...form.getFieldsValue() };

      //     keys.forEach((item: any) => {
      //         newFormValues[item] = false;
      //     })
      //     roles.forEach((item: any) => {
      //         newFormValues[item] = true;
      //     })
      //     form.setFieldsValue(newFormValues)
      // }

      const roles: DataRoleResponse[] = [];
      const auIds: any[] = [];

      changedValues.role?.forEach((roleItem: string) => {
        const roleData = roleListResponse?.data?.find(
          (role) => role.id === roleItem
        );
        if (roleData) roles.push(roleData);
      });

      let authList = roles.reduce((pre: string[], cur: DataRoleResponse) => {
        const authListCurrValue =
          cur?.authoritieLst?.map((role: any) => role.id) || [];
        setCheckedKeys([...new Set(authListCurrValue)]);
        const newAuthListValue = [...authListCurrValue];

        authListCurrValue.forEach((value) => {
          auIds.push(value);
        });

        return newAuthListValue;
      }, []);

      if (allValues.groupUsers) {
        allValues.groupUsers?.forEach((roleItem: string) => {
          const roleData = fullGroupUserResponse?.data?.find(
            (role) => role.id === roleItem
          );
          if (roleData?.roles) {
            roleData.roles.forEach((item: any) => {
              roles.push(item);
            });
          }
        });

        authList = roles.reduce((pre: string[], cur: DataRoleResponse) => {
          const authListCurrValue =
            cur?.authoritieLst?.map((role: any) => role.id) || [];
          const newAuthListValue = [...authListCurrValue];

          authListCurrValue.forEach((value) => {
            auIds.push(value);
          });

          return newAuthListValue;
        }, []);
      }

      setCheckedKeys([...new Set(auIds)]);
    }

    if (changedValues.groupUsers) {
      const roles: DataRoleResponse[] = [];
      const auIds: any[] = [];
      const roleIds: any[] = [];

      changedValues.groupUsers?.forEach((roleItem: string) => {
        const roleData = fullGroupUserResponse?.data?.find(
          (role) => role.id === roleItem
        );
        if (roleData?.roles) {
          roleData.roles.forEach((item: any) => {
            roles.push(item);
            roleIds.push(item.id);
          });
        }
      });

      let authList = roles.reduce((pre: string[], cur: DataRoleResponse) => {
        const authListCurrValue =
          cur?.authoritieLst?.map((role: any) => role.id) || [];
        const newAuthListValue = [...authListCurrValue];

        authListCurrValue.forEach((value) => {
          auIds.push(value);
        });

        return newAuthListValue;
      }, []);

      /*if (allValues.role) {
                allValues.role?.forEach((roleItem: string) => {
                    const roleData = roleListResponse?.data?.find(role => role.id === roleItem);
                    if (roleData) roles.push(roleData)
                });

                authList = roles.reduce((pre: string[], cur: DataRoleResponse) => {
                    const authListCurrValue = cur?.authoritieLst?.map((role: any) => role.id) || [];
                    const newAuthListValue = [...authListCurrValue];

                    authListCurrValue.forEach(value => {
                        auIds.push(value);
                    });

                    return newAuthListValue;
                }, []);
            }*/

      setCheckedKeys(auIds);
      // push role
      form.setFieldsValue({ role: roleIds });
    }
  };

  // upload file
  // const customUpload = (options: any) => {
  //     setLoadingFile(true);
  //     options.onProgress({ percent: 0 })
  //     let formData = new FormData();
  //     formData.append('file', options.file);
  //     uploadFile({
  //         file: formData,
  //         objectType: 100,
  //         storageType: 'FILE_HOP_DONG',
  //     }).unwrap()
  //         .then(res => {
  //             options.onProgress({ percent: 100 });
  //             options.onSuccess(res, options.file);

  //             let uploadFile = {
  //                 id: res.id,
  //                 uid: options.file.uid,
  //                 name: options.file.name,
  //                 size: options.file.size,
  //                 type: options.file.type,
  //             }

  //             setUploadedFiles([...uploadedFiles, uploadFile]);
  //             setLoadingFile(false);
  //             fetch(process.env.REACT_APP_API_URL + "/api/v1/file/downloadFile/" + res.id, {
  //                 headers: {
  //                     Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
  //                 }
  //             }).then(response => {
  //                 return response.blob().then(blob => {
  //                     let reader = new FileReader();
  //                     reader.readAsDataURL(blob);
  //                     reader.onload = () => setFileList([
  //                         {
  //                             uid: res.id,
  //                             name: res.fileName,
  //                             status: 'done',
  //                             thumbUrl: reader.result,
  //                             response: res
  //                         }]);
  //                 })
  //             })
  //         })
  //         .catch(err => {
  //             options.onError();
  //             setLoadingFile(false);
  //             if (err?.data?.message) {
  //                 notification.error({
  //                     message: err?.data?.message
  //                 })
  //             }
  //         })
  // }

  // const beforeUpload = (files: File[]) => {
  //     let accepted = true;
  //     let fileList = [...files];
  //     fileList = fileList.slice(-1);
  //     Array.from(fileList).forEach(file => {
  //         let error = ''
  //         if (file.type !== "application/vnd.ms-excel" &&
  //             file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
  //             accepted = false;
  //             error = 'Định dạng file không hợp lệ!'
  //         }
  //         setFileError(error)
  //     })
  //     return accepted || Upload.LIST_IGNORE;
  // }

  // const onFileRemove = (item: any, index: number) => {
  //     return new Promise<boolean>((resolve, reject) => {
  //         confirm({
  //             title: 'Xoá file đã chọn?',
  //             okText: "Đồng ý",
  //             cancelText: "Hủy bỏ",
  //             onOk: () => {
  //                 if (uploadedFiles) {
  //                     let newDeleteIds = deleteIds;
  //                     newDeleteIds.push(item.id);
  //                     setDeleteIds(newDeleteIds);
  //                     let newUploadedFiles = uploadedFiles;
  //                     newUploadedFiles.splice(index, 1);
  //                     setUploadedFiles([...newUploadedFiles])
  //                     form.setFieldsValue({
  //                         attachment: []
  //                     })
  //                     form.validateFields(["attachment"])
  //                 }
  //                 resolve(true);
  //             },
  //             onCancel: () => {
  //                 resolve(false)
  //             }
  //         })
  //     })
  // }
  // const downloadFile = (id: number, name: string) => {
  //     exportFile(process.env.REACT_APP_API_URL + "/api/v1/file/downloadFile/" + id, name);
  // }

  const addFileToObject = (res: any, values: any) => {
    let fileIds = uploadedFiles?.length
      ? uploadedFiles.map((item: any) => item.id)
      : [];
    uploadFileToObject({
      listFileIds: fileIds,
      objectId: res.id,
      storageType: "FILE_HOP_DONG",
    })
      .unwrap()
      .then(() => {
        setDeleteIds([]);
        notification.success({
          message: params?.id
            ? "Cập nhật người dùng thành công"
            : "Thêm mới người dùng thành công",
        });
        navigate("/setting/user");
      })
      .catch((err) => {
        notification.error({
          message:
            err?.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
        });
      });
  };

  const changePhoneNumber = (e: { target: { value: string } }) => {
    if (`${e.target.value}`.includes("+")) {
      form.setFieldsValue({ phoneNumber: e.target.value });
    } else if (e.target.value.charAt(0) !== "0") {
      form.setFieldsValue({ phoneNumber: "+" + e.target.value });
    }
    if (e.target.value === "") {
      form.setFieldsValue({ phoneNumber: e.target.value });
    }
  };

  const { toggleShowTitle } = useContext(DataInfoContext);

  useEffect(() => {
    toggleShowTitle(editing);
  });

  return (
    <div className="create-container">
      <Row
        className="fixed-header"
        align="middle"
        justify="end"
        style={{ width: "100%" }}
      >
        {/* <Col>
                    <span className="title">{params?.id ? !editing ? 'THÔNG TIN NGƯỜI DÙNG' : 'CHỈNH SỬA NGƯỜI DÙNG' : 'THÊM MỚI NGƯỜI DÙNG'}</span>
                </Col> */}
        <Col>
          <Space size={16}>
            {params.id && canEdit && !editing && (
              <Button
                className="submit-btn"
                type="primary"
                onClick={() => {
                  setEditing(!editing);
                }}
              >
                Chỉnh sửa
              </Button>
            )}
            {editing && (
              <Button
                className="submit-btn btn"
                type="primary"
                onClick={() => form.submit()}
              >
                Lưu
              </Button>
            )}
            {!params.id && (
              <Button
                className="submit-btn btn"
                type="primary"
                onClick={() => form.submit()}
              >
                Thêm mới
              </Button>
            )}
            <Button
              danger
              className="submit-btn"
              onClick={() => navigate("/setting/user")}
            >
              Huỷ
            </Button>
          </Space>
        </Col>
      </Row>
      <Form
        name="searchBox"
        autoComplete={"off"}
        form={form}
        labelWrap
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        labelAlign="left"
        requiredMark={false}
        colon={false}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        scrollToFirstError={{ behavior: "smooth" }}
        // initialValues={{
        //     username: '',
        //     password: '',
        // }}
        // onFinishFailed={() => setModalVisible(!modalVisible)}
      >
        <Row gutter={16} className="create-section">
          <span className="section-title">Thông tin cá nhân</span>
          <Col span={12}>
            <Form.Item
              label="Họ tên"
              name="fullName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Vui lòng nhập Họ tên.",
                },
              ]}
            >
              <Input
                placeholder="Nhập họ và tên"
                autoFocus
                allowClear
                style={{ minWidth: "100%" }}
                disabled={params.id && !editing ? true : false}
                maxLength={250}
                autoComplete="on"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Số điện thoại"
              name="phoneNumber"
              rules={[
                //     { required: true, whitespace: true, message: "Vui lòng nhập Số điện thoại." }
                { min: 10, message: "Số điện thoại cần tối thiểu 10 chữ số" },
              ]}
            >
              <Input
                maxLength={11}
                placeholder="Nhập số điện thoại"
                allowClear
                style={{ minWidth: "100%" }}
                disabled={params.id && !editing ? true : false}
                onBlur={changePhoneNumber}
                onKeyDown={(e) => {
                  if (
                    (e.key < "0" || e.key > "9") &&
                    !["Backspace", "ArrowLeft", "ArrowRight"].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                autoComplete="on"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  whitespace: true,
                  type: "email",
                  message: "Vui lòng nhập đúng định dạng Email.",
                },
              ]}
            >
              <Input
                placeholder="Nhập email"
                allowClear
                style={{ minWidth: "100%" }}
                disabled={params.id && !editing ? true : false}
                type="email"
                autoComplete="on"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Địa chỉ" name="address">
              <Input.TextArea
                placeholder="Nhập địa chỉ"
                allowClear
                rows={1}
                maxLength={500}
                disabled={params.id && !editing ? true : false}
                autoComplete="on"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ngày sinh" name="birthday">
              <DatePicker
                placeholder="Nhập Ngày sinh"
                format="DD/MM/Y"
                style={{ minWidth: "100%" }}
                allowClear
                disabled={params.id && !editing ? true : false}
                disabledDate={(current) =>
                  current > moment().endOf("day").toDate()
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Giới tính" name="gender">
              <Select
                placeholder="Chọn giới tính"
                allowClear
                disabled={params.id && !editing ? true : false}
              >
                <Option value={1}>Nam</Option>
                <Option value={2}>Nữ</Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
                        <Form.Item
                            label="Trình độ học vấn"
                            name="educationLevel"
                        >
                            <Input placeholder="Nhập Trình độ học vấn" allowClear style={{ minWidth: '100%' }} disabled={(params.id && !editing) ? true : false} autoComplete="on"/>
                        </Form.Item>
                    </Col> */}
          <Col span={12}>
            <Form.Item label="Ngày vào làm" name="startWorkDate">
              <DatePicker
                placeholder="Nhập Ngày vào làm"
                format="DD/MM/Y"
                style={{ minWidth: "100%" }}
                allowClear
                disabled={params.id && !editing ? true : false}
                disabledDate={(current) =>
                  current > moment().endOf("day").toDate()
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hình thức làm việc" name="workType">
              <Select
                placeholder="Chọn hình thức làm việc"
                allowClear
                disabled={params.id && !editing ? true : false}
              >
                <Option value={0}>Online</Option>
                <Option value={1}>Offline</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Số lần cảnh báo" name="warningCount">
              <Input
                placeholder="Nhập Số lần cảnh báo"
                allowClear
                type="number"
                min={0}
                style={{ minWidth: "100%" }}
                disabled={params.id && !editing ? true : false}
                autoComplete="on"
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
                        <Form.Item
                            label="Số lần khen thưởng"
                            name="rewardCount"
                        >
                            <Input placeholder="Nhập Số lần khen thưởng" allowClear type='number' min={0} style={{ minWidth: '100%' }} disabled={(params.id && !editing) ? true : false} />
                        </Form.Item>
                    </Col> */}
          {/* <Col span={12}>
                        <Form.Item
                            label="Hợp đồng"
                            name="contract"
                        >
                            <Upload
                                showUploadList={false}
                                customRequest={(options) => customUpload(options)}
                                accept=".xlsx, .xls,.docx,.doc"
                                beforeUpload={(file, fileList) => beforeUpload(fileList)}
                                style={{ marginBottom: 0 }}
                                multiple={true}
                                openFileDialogOnClick
                                listType="text"
                            >
                                <Button
                                    type="primary"
                                    loading={loadingFile}
                                    style={{ marginBottom: 15, marginLeft: 0 }}
                                    disabled={(params.id && !editing) ? true : false}
                                >Lựa chọn</Button>
                                {fileError ? <div style={{ color: 'red' }}>{fileError}</div> : <></>}
                            </Upload>
                            {(uploadedFiles && uploadedFiles.length) ? uploadedFiles.map((item: any, index: number) => {
                                return (
                                    <div className='file-wrapper' style={{ marginTop: 10 }} key={item?.id || item.uid}>
                                        <span style={{ cursor: 'pointer' }} className="anchor-text" onClick={() => downloadFile(item?.id, item.name)}>{item.name}</span>
                                        {editing ? <XIcon
                                            onClick={() => onFileRemove(item, index)}
                                            style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                            : <></>
                                        }
                                    </div>
                                )
                            }) : <></>}
                        </Form.Item>
                    </Col> */}
        </Row>
        <Row gutter={16} className="create-section">
          <span className="section-title">Thông tin tài khoản</span>
          <Col span={8}>
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Vui lòng nhập Tên đăng nhập.",
                },
              ]}
            >
              <Input
                maxLength={50}
                placeholder="Nhập tên đăng nhập"
                disabled={params.id && !editing ? true : false}
                allowClear
                style={{ minWidth: "100%" }}
                autoComplete="on"
              />
            </Form.Item>
          </Col>
          {!params.id ? (
            <Col span={8}>
              <Form.Item
                label="Mật khẩu"
                name="password"
                rules={[
                  { required: true, message: "Vui lòng nhập mật khẩu." },
                  { pattern, message: patternMessage },
                ]}
              >
                <Input.Password
                  placeholder="Nhập mật khẩu"
                  allowClear
                  disabled={params.id && !editing ? true : false}
                  style={{ minWidth: "100%" }}
                  maxLength={20}
                  autoComplete={"new-password"}
                />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          <Col span={8}>
            <Form.Item label="Trạng thái" name="status">
              <Select
                placeholder="Chọn trạng thái"
                allowClear
                disabled={params.id && !editing ? true : false}
                defaultValue={0}
              >
                <Option value={0}>Hoạt động</Option>
                <Option value={1}>Không hoạt động</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {params.id ? (
          <>
            <Row gutter={16} className="create-section">
              <span className="section-title">Thông tin nhóm Người dùng</span>
              <Col span={12}>
                <Form.Item
                  label="Nhóm người dùng"
                  name="groupUsers"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Vui lòng chọn Nhóm người dùng",
                  //   },
                  // ]}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="--Chọn Nhóm người dùng--"
                    showSearch
                    optionFilterProp="label"
                    options={fullGroupUserResponse?.data
                      ?.filter(
                        (permission) =>
                          permission.status === 0 && permission.isDelete === 0
                      )
                      .map((item: { id: string; name: string }) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        ?.toLowerCase()
                        ?.localeCompare(optionB.label?.toLowerCase())
                    }
                    // disabled={(params.id && !editing) ? true : false}
                    disabled
                  ></Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="create-section">
              <span className="section-title">Thông tin phân quyền</span>
              <Col span={12}>
                <Form.Item label="Vai trò" name="role">
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="--Chọn Nhóm quyền--"
                    showSearch
                    optionFilterProp="label"
                    options={roleListResponse?.data
                      ?.filter(
                        (permission) =>
                          permission.status === 0 && permission.isDelete === 0
                      )
                      .map((item: { id: string; roleName: string }) => ({
                        label: item.roleName,
                        value: item.id,
                      }))}
                    filterSort={(optionA, optionB) =>
                      optionA.label
                        ?.toLowerCase()
                        ?.localeCompare(optionB.label?.toLowerCase())
                    }
                    // disabled={(params.id && !editing) ? true : false}
                    disabled={true}
                  ></Select>
                </Form.Item>
              </Col>

              {/* <Table
                        columns={columns}
                        dataSource={[]}
                        expandable={{
                            expandIconColumnIndex: 0,
                            rowExpandable: record => Array.isArray(record.children),
                            showExpandColumn: false,
                            expandedRowKeys: expandedRowKeys,
                        }}
                        pagination={false}
                        style={{ width: '100%', marginTop: 18 }}
                    /> */}

              <Tree
                checkable
                disabled={params.id && !editing ? true : false}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                checkedKeys={checkedKeys}
                onCheck={onCheck}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                treeData={formattedTreeData}
                style={{ marginTop: 18 }}
              />
            </Row>
          </>
        ) : (
          <></>
        )}
      </Form>

      {modalVisible && (
        <ConfirmModalCustom
          onCancel={() => setModalVisible(!modalVisible)}
          cancelText="Huỷ"
          okText="Đồng ý"
          showCancel={true}
          showOk={true}
          type={"info"}
          danger={true}
          title={params?.id ? "Chỉnh sửa người dùng" : "Thêm mới người dùng"}
          content={
            params?.id
              ? "Bạn có chắc chắn muốn chỉnh sửa người dùng này hay không?"
              : "Bạn có chắc chắn muốn thêm mới người dùng này hay không?"
          }
          visible={modalVisible}
          onOk={() => form.submit()}
        />
      )}
    </div>
  );
};

export default CreateUser;
