import { Button, Col, Form, Input, Row, Select } from "antd";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import { useState } from "react";
import { ReactComponent as ZoomIn } from "../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../assets/logos/zoom-out-line.svg";
import { DatePicker } from "../../custom-components";
import UserList from "./components/UserList";
import { CommonTableParams } from "../../redux/api/apiTypes";
const RegisterInfo = () => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [searchParam, setSearchParam] = useState<CommonTableParams<any>>({
    searchParam: {},
    page: 0,
    size: 10,
  });

  const onFinish = (values: any) => {
    setSearchParam((prev) => ({
      searchParam: values,
      page: 0,
      size: prev.size,
    }));
  };

  const resetFormData = () => {
    form.resetFields();
  };
  const [isSearch, setIsSearch] = useState(false);
  return (
    <>
      {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
      {/*    <Breadcrumb.Item href="/home">*/}
      {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
      {/*    </Breadcrumb.Item>*/}
      {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
      {/*    <Breadcrumb.Item href="">*/}
      {/*        <span>Danh sách tài khoản</span>*/}
      {/*    </Breadcrumb.Item>*/}
      {/*</Breadcrumb>*/}

      {/* <div className='title-label' style={{ marginBottom: 20 }}>Danh sách người dùng</div> */}

      <div className="content-box" style={{ marginBottom: 30 }}>
        <Form
          name="searchBox"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          labelWrap
          labelAlign="left"
          colon={false}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setIsSearch(!isSearch)}
          >
            {isSearch ? (
              <ZoomOut style={{ width: 20, paddingTop: 8 }} />
            ) : (
              <ZoomIn style={{ width: 20, paddingTop: 8 }} />
            )}
            <label className="search-title" style={{ cursor: "pointer" }}>
              TÌM KIẾM
            </label>
          </div>
          {isSearch ? (
            <>
              <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                <Col span={12} xs={24} md={12} lg={8} xl={6}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Tên đăng nhập"
                    name="username"
                  >
                    <Input
                      placeholder="Nhập tên đăng nhập"
                      allowClear
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12} lg={8} xl={6}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Họ tên"
                    name="fullName"
                  >
                    <Input
                      placeholder="Nhập họ tên"
                      allowClear
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12} lg={8} xl={6}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Số điện thoại"
                    name="phoneNumber"
                  >
                    <Input
                      placeholder="Nhập số điện thoại"
                      allowClear
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12} lg={8} xl={6}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Email"
                    name="email"
                  >
                    <Input
                      placeholder="Nhập email"
                      allowClear
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12} lg={8} xl={6}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Địa chỉ"
                    name="address"
                  >
                    <Input
                      placeholder="Nhập địa chỉ"
                      allowClear
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12} lg={8} xl={6}>
                  <Form.Item label="Ngày vào làm" name="startWorkDate">
                    <RangePicker
                      format="DD/MM/Y"
                      placeholder={["Bắt đầu", "Kết thúc"]}
                      ranges={{
                        "Hôm nay": [new Date(), new Date()],
                        "Tháng này": [startOfMonth(new Date()), new Date()],
                        "Tháng trước": [
                          startOfMonth(subMonths(new Date(), 1)),
                          endOfMonth(subMonths(new Date(), 1)),
                        ],
                        "3 tháng gần nhất": [
                          startOfMonth(subMonths(new Date(), 2)),
                          new Date(),
                        ],
                        "6 tháng gần nhất": [
                          startOfMonth(subMonths(new Date(), 5)),
                          new Date(),
                        ],
                        "Năm nay": [
                          startOfYear(subMonths(new Date(), 5)),
                          new Date(),
                        ],
                        "Năm trước": [
                          startOfYear(subYears(new Date(), 1)),
                          endOfYear(subYears(new Date(), 1)),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12} lg={8} xl={6}>
                  <Form.Item label="Hình thức làm việc" name="workType">
                    <Select placeholder="Chọn hình thức làm việc" allowClear>
                      <Option value={0}>Online</Option>
                      <Option value={1}>Offline</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={12} xs={24} md={12} lg={8} xl={6}>
                                <Form.Item
                                    label="Số lần cảnh báo"
                                    name="warningCount"
                                >
                                    <Input placeholder="Nhập Số lần cảnh báo" type="number" allowClear style={{ minWidth: '100%' }} autoComplete="on"/>
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} md={12} lg={8} xl={6}>
                                <Form.Item
                                    label="Số lần khen thưởng"
                                    name="rewardCount"
                                >
                                    <Input placeholder="Nhập Số lần khen thưởng" type="number" allowClear style={{ minWidth: '100%' }} autoComplete="on"/>
                                </Form.Item>
                            </Col> */}
              </Row>
              <div className="search-box-button">
                <Button htmlType="button" onClick={() => resetFormData()}>
                  Xóa điều kiện
                </Button>
                <Button htmlType="submit" type="primary">
                  Tìm kiếm
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </Form>
      </div>
      <UserList
        form={form}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />
    </>
  );
};

export default RegisterInfo;
