import {
  Button,
  Col,
  Dropdown,
  FormInstance,
  Menu,
  notification,
  Pagination,
  Row,
  Spin,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  useDeleteMutation,
  useLazyGetDetailQuery,
  useGetPageQuery,
  useStatusMutation,
} from "../redux/groupUserApi";

import { ReactComponent as Manipulation } from "../../../assets/logos/action.svg";
import { ReactComponent as RemoveDetail } from "../../../assets/logos/delete.svg";
import { ReactComponent as AddIcon } from "../../../assets/logos/add_icon.svg";
import { ReactComponent as Lock } from "../../../assets/logos/lock-dots.svg";
import { ReactComponent as Unlock } from "../../../assets/logos/unlock.svg";

import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";
import AddGroupUserModal from "./AddGroupUserModal";
import { DataNode } from "antd/lib/tree";
import { checkPermission } from "../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { CommonTableParams } from "../../../redux/api/apiTypes";

type Props = {
  form: FormInstance<any>;
  searchParam: CommonTableParams<any>;
  setSearchParam: React.Dispatch<React.SetStateAction<CommonTableParams<any>>>;
};

const RoleList = ({ form, searchParam, setSearchParam }: Props) => {
  const pageSizeRef = useRef(10);

  const [tableData, setTableData] = useState<any[]>([]);
  const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<any>();
  const [addRole, setAddRole] = useState(false);
  const [deleteRoleVisible, setDeleteRoleVisible] = useState(false);
  const [activateRoleVisible, setActivateRoleVisible] =
    useState<boolean>(false);

  const { data: roleResponse, isFetching } = useGetPageQuery(
    searchParam,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deleteRole] = useDeleteMutation();
  const [statusTrigger] = useStatusMutation();
  const [detailRoleTrigger, detailRoleResponse] = useLazyGetDetailQuery();

  const canDelete = checkPermission(
    PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_NGUOI_DUNG.XOA
  );
  const canAddNew = checkPermission(
    PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_NGUOI_DUNG.THEM_MOI
  );
  const canEdit = checkPermission(
    PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_NGUOI_DUNG.SUA
  );

  useEffect(() => {
    if (roleResponse?.content?.length) {
      let newTableData: any[] = [];
      // totalPages.current = RoleResponse?.data?.totalPages;
      roleResponse.content.forEach((item: any) => {
        newTableData.push({
          ...item,
        });
      });
      setTableData(newTableData);
    } else {
      setTableData([]);
      // totalPages.current = 1;
    }
  }, [roleResponse]);

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, item, index) =>
        searchParam.page * pageSizeRef.current + index + 1,
    },
    {
      title: "Thao Tác",
      key: "action",
      align: "center",
      render: (record) => {
        return (
          canDelete && (
            <Dropdown placement="bottomLeft" overlay={dropdownOverlay(record)}>
              <Manipulation onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          )
        );
      },
    },
    {
      title: "Tên nhóm",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Quản lý",
      dataIndex: "manage",
      key: "manage",
      align: "left",
    },
    {
      title: "Trạng Thái",
      key: "status",
      dataIndex: "status",
      render: (tag) => (
        <Tag
          style={{ border: "none" }}
          className={`tag-status${!tag ? "_active" : "_err"}`}
        >
          {!tag ? "Hoạt động" : "Không hoạt động"}
        </Tag>
      ),
      align: "center",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      align: "left",
    },
    {
      title: "Ngày cập nhật",
      key: "modifiedDateStr",
      dataIndex: "modifiedDateStr",
      align: "left",
    },
    // {
    //     title: "Người cập nhật",
    //     key: "modifiedBy",
    //     dataIndex: "modifiedBy",
    //     align: "left",
    // },
  ];

  const dropdownOverlay = (record: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          // items={menuItems}
          className="menu-actions"
          style={{ padding: "8px", width: "140px" }}
        >
          <Menu.Item
            key="remove-detail"
            onClick={() => deleteRoleToggle(record)}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <RemoveDetail style={{ height: 24, width: 24 }} />
              Xóa
            </div>
          </Menu.Item>
          {record.status ? (
            <Menu.Item key="unlock">
              <div
                style={{ display: "flex", alignItems: "center", gap: 4 }}
                onClick={() => activateRoleToggle(record)}
              >
                <Unlock style={{ height: 24, width: 24 }} />
                Kích hoạt
              </div>
            </Menu.Item>
          ) : (
            <Menu.Item key="lock">
              <div
                style={{ display: "flex", alignItems: "center", gap: 4 }}
                onClick={() => activateRoleToggle(record)}
              >
                <Lock style={{ height: 24, width: 24 }} />
                Khóa
              </div>
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  };

  // get table data
  const getData = () => {
    // console.log('setSearchParam', searchParam);
    setSearchParam((prev) => ({
      ...searchParam,
      page: 0,
      size: pageSizeRef.current,
    }));
    // setPage(1);
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage;
    if (pageSizeChange) {
      newPage = 1;
    }
    // setPage(newPage);
    pageSizeRef.current = pageSize;

    setSearchParam((prev) => ({
      ...searchParam,
      page: newPage - 1,
      size: pageSizeRef.current,
    }));
  };

  // Thêm mới
  const toggleAddRole = (record?: any) => {
    setAddRole(!addRole);
    if (record) {
      let data: any = { ...record };
      let users: any[] = [];
      let roles: any[] = [];

      if (record.users) {
        for (let i = 0; i < record.users.length; i++) {
          users.push(record.users[i].username);
        }
      }
      if (record.roles) {
        for (let i = 0; i < record.roles.length; i++) {
          roles.push(record.roles[i].id);
        }
      }

      data.users = users;
      data.roles = roles;

      setCurrentRole(data);
    }
  };
  const onAddSuccessful = () => {
    toggleAddRole();
    setCurrentRole(undefined);
    // setPage(1);
    setSearchParam((prev) => ({
      searchParam: {},
      size: pageSizeRef.current,
      page: 0,
    }));
  };

  // Xóa
  const deleteRoleToggle = (record: any) => {
    setDeleteRoleVisible(!deleteRoleVisible);
    setCurrentRole(record);
  };

  const onDelete = () => {
    if (currentRole) {
      if (!loadingBtnSubmit) {
        setLoadingBtnSubmit(true);
        deleteRole([currentRole.id])
          .unwrap()
          .then((res) => {
            notification.success({
              message: "Xóa Nhóm người dùng thành công",
            });
            setDeleteRoleVisible(false);
            setCurrentRole(undefined);
            // setPage(1);
            form.resetFields();
            setLoadingBtnSubmit(false);
            setSearchParam((prev) => ({
              searchParam: {},
              size: pageSizeRef.current,
              page: 0,
            }));
          })
          .catch((err) => {
            notification.error({
              message:
                err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
            });
            setLoadingBtnSubmit(false);
          });
      }
    }
  };

  const activateRoleToggle = (record: any) => {
    setActivateRoleVisible(!activateRoleVisible);
    setCurrentRole(record);
  };

  const onActivate = () => {
    if (currentRole && currentRole?.id) {
      setLoadingBtnSubmit(true);
      statusTrigger({
        id: currentRole?.id,
        status: currentRole.status ? 0 : 1,
      })
        .unwrap()
        .then((res) => {
          notification.success({
            message: `${
              currentRole.status ? "Kích hoạt" : "Khoá"
            } Nhóm người dùng thành công.`,
          });
          setActivateRoleVisible(false);
          setCurrentRole(undefined);
          // setPage(1);
          form.resetFields();
          setLoadingBtnSubmit(false);
          setSearchParam((prev) => ({
            searchParam: {},
            size: pageSizeRef.current,
            page: 0,
          }));
        })
        .catch((err) => {
          notification.error({
            message:
              err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
          });
          setActivateRoleVisible(false);
          setLoadingBtnSubmit(false);
        });
    }
  };

  // reset All state
  const resetState = () => {
    setCurrentRole(undefined);
    setDeleteRoleVisible(false);
    setAddRole(false);
    setActivateRoleVisible(false);
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        <Col span={6}>
          <div>
            Tổng số: <strong>{roleResponse?.totalElements ?? 0}</strong> bản ghi
          </div>
        </Col>
        <div className="search-box-button">
          {/* {canAddNew ? <Button
                        htmlType="button"
                        onClick={() => navigate('/setting/role/add')}
                        className="secondary-button"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <AddIcon className='add-icon' style={{ marginRight: 8 }} />
                        Thêm mới
                    </Button> : <></>
                    } */}
          {canAddNew ? (
            <Button
              htmlType="button"
              onClick={() => setAddRole(!addRole)}
              className="secondary-button"
              style={{ display: "flex", alignItems: "center" }}
            >
              <AddIcon className="add-icon" style={{ marginRight: 8 }} />
              Thêm mới
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Row>

      <Table<any>
        loading={isFetching}
        dataSource={tableData}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
        onRow={(record) => {
          // return {
          //     onClick: event => navigate(`/setting/role/detail/${record.id}`),
          // }
          return {
            onClick: (event) => toggleAddRole(record),
          };
        }}
      />

      <Spin spinning={isFetching} tip="Đang tải...">
        <Pagination
          total={roleResponse?.totalElements || 0}
          defaultPageSize={pageSizeRef.current}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[1, 10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={searchParam.page + 1}
        />
      </Spin>

      {addRole && (
        <AddGroupUserModal
          visible={addRole}
          onCancel={resetState}
          currentRole={currentRole}
          onAddSuccessful={onAddSuccessful}
          canEdit={canEdit}
        />
      )}

      {/* modal xóa */}
      {deleteRoleVisible && (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Huỷ bỏ"
          okText="Xác nhận"
          showCancel={true}
          showOk={true}
          type={"error"}
          danger={true}
          title="Xóa Nhóm người dùng"
          content="Bạn có chắc chắn muốn xóa Nhóm người dùng này hay không?"
          visible={deleteRoleVisible}
          onOk={onDelete}
          loadingBtnOk={loadingBtnSubmit}
        />
      )}

      {activateRoleVisible && (
        <ConfirmModalCustom
          onCancel={resetState}
          cancelText="Huỷ bỏ"
          okText="Xác nhận"
          showCancel={true}
          showOk={true}
          type={currentRole?.status === 0 ? "warning" : "success"}
          title={
            currentRole?.status === 0
              ? "Khóa Nhóm người dùng"
              : "Kích hoạt Nhóm người dùng"
          }
          content={
            currentRole?.status === 0
              ? "Bạn có chắc chắn muốn khóa Nhóm người dùng này hay không?"
              : "Bạn có chắc chắn muốn kích hoạt Nhóm người dùng này hay không?"
          }
          visible={activateRoleVisible}
          onOk={onActivate}
          loadingBtnOk={loadingBtnSubmit}
        />
      )}
    </div>
  );
};

export default RoleList;
