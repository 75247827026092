import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Input, Modal, notification, Space, Spin } from "antd";
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page, PDFPageProxy } from "react-pdf";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { ReactComponent as Rotate } from "../../../assets/logos/rotate.svg";
import { ReactComponent as ZoomIn } from "../../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../../assets/logos/zoom-out-line.svg";
import { useReplacePdfMutation } from "../../check-data/redux/checkDataApi";
import ConfirmModal from "../../common/components/ConfirmModal";
import { useUpdateStatusFilePdfMutation } from "../../data_init/redux/dataInitApi";

type Props = {
  visible: boolean;
  onClose: () => void;
  currentFile?: any;
  isLoading?: boolean;
  refetch?: () => void;
};

const DetailModal = ({ visible, onClose, isLoading, currentFile }: Props) => {
  const [width, setWidth] = useState<any>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const prevScale = useRef<number>(1);
  const [numPages, setNumPages] = useState(0);
  const canvas = useRef<any>();
  const canvas1 = useRef<any>();
  const [isRendered, setIsRendered] = useState<boolean>(true);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [clientY, setClientY] = useState<number>(0);
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const [updateStatusPdf, responseUpdateStatusPdf] =
    useUpdateStatusFilePdfMutation();
  const [confirmSuccessVisible, setConfirmSuccessVisible] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [editable, setEditable] = useState<boolean>(false);
  const [inputError, setInputError] = useState<boolean>(false);

  const [uploadFile, responseUpload] = useReplacePdfMutation();

  const [newFile, setNewFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // console.log("current", currentFile);

  useEffect(() => {
    if (currentFile?.fileName) {
      setFileName(currentFile?.fileName);
    }
  }, [currentFile?.fileName]);

  const file = useMemo(() => {
    if (currentFile?.pathFile) {
      return {
        url: process.env.REACT_APP_API_FILE_URL + "/" + currentFile?.pathFile,
        httpHeaders: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
    }
  }, [currentFile?.pathFile]);

  const buttonLabel = useMemo(() => {
    return editable ? "Xác nhận" : "Chỉnh sửa";
  }, [editable]);

  const disabledSave = useMemo(() => {
    return !newFile || !fileName;
  }, [fileName, newFile]);

  const handleClose = () => {
    if (!onClose) return;
    onClose();
    setEditable(false);
  };

  const toggleConfirmSuccessModal = () => {
    setConfirmSuccessVisible(!confirmSuccessVisible);
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const onRenderSuccess = (page: PDFPageProxy) => {
    setIsRendered(true);
  };

  const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
    if (!isDrag) {
      const rect = canvas.current.getBoundingClientRect();
      const scaleY = canvas.current.height / rect.height;
      setClientY((event.clientY - rect.top) * scaleY);
    }
  };

  const onSubmitSuccess = (message?: string) => {
    updateStatusPdf({
      id: currentFile.key,
      isCheck: true,
      message,
    })
      .unwrap()
      .then((res: any) => {
        toggleConfirmSuccessModal();
        handleClose();
        notification.success({
          message: "Bạn đã báo lỗi thành công!",
        });
      })
      .catch(() => {
        notification.error({
          message: "Bạn đã báo lỗi thất bại!",
        });
      });
  };

  const onChangeFileName = (e: ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    setFileName(e.target.value);
  };

  const handleSubmit = () => {
    if (!editable) {
      setEditable(!editable);
    } else {
      if (!newFile || !currentFile) return;

      let pattern =
        /[a-zA-z]{2,3}\.[0-9]{4}\.[0-9]{1,}\.[0-9]{5}_[0-9]{1,}\.pdf/g;
      if (!pattern.test(newFile.name)) {
        notification.error({
          message: "Tên file sai định dạng",
        });
        setInputError(true);
        return;
      }

      let formData = new FormData();
      formData.append("newFile", newFile);
      formData.append("newFileName", newFile.name);
      uploadFile({
        dataInfoId: currentFile.key,
        newFile: formData,
        filePDFName: newFile.name,
      })
        .unwrap()
        .then((res: any) => {
          notification.success({ message: "Tải file thành công!" });
        })
        .catch((err: any) => {
          if (err?.data?.message) {
            notification.error({
              message: err?.data?.message,
            });
          }
        });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    if (file.type !== "application/pdf") {
      notification.error({ message: `Định dạng file không hợp kệ` });
      return;
    }

    let pattern =
      /[a-zA-z]{2,3}\.[0-9]{4}\.[0-9]{1,}\.[0-9]{5}\_[0-9]{1,}\.pdf/g;
    if (!pattern.test(file.name)) {
      notification.error({
        message: "Tên file sai định dạng",
      });
      return;
    }

    if (file.name !== fileName) {
      notification.error({ message: `Tên file pdf không trùng khớp` });
      return;
    }
    setNewFile(file);
  };

  const handleIconClick = () => {
    inputRef.current?.click();
  };

  const handleClearFile = () => {
    setNewFile(null);
    inputRef.current!.value = "";
  };

  return (
    <Modal
      open={visible}
      title="Thông tin file pdf"
      onCancel={handleClose}
      width={800}
      maskClosable={false}
      bodyStyle={{
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
      footer={[
        <React.Fragment key="footer">
          <Button htmlType="button" onClick={handleClose}>
            Đóng
          </Button>
          <Button
            loading={isLoading || responseUpload.isLoading}
            htmlType="button"
            type="primary"
            onClick={handleSubmit}
            disabled={disabledSave && editable}
          >
            {buttonLabel}
          </Button>
          <Button
            htmlType="button"
            type="primary"
            style={{ backgroundColor: "#ff4d4f", border: "#ff4d4f" }}
            onClick={toggleConfirmSuccessModal}
          >
            Báo lỗi
          </Button>
        </React.Fragment>,
      ]}
    >
      <div
        className="header-block"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "12px",
        }}
      >
        <div>
          <Input
            placeholder="Nhập tên file"
            style={{
              minWidth: "100%",
              border: inputError ? "1px solid red" : "initial",
            }}
            autoComplete="on"
            value={fileName}
            onChange={onChangeFileName}
            disabled={!editable}
          />
        </div>
        <div>
          <ZoomOut
            onClick={() =>
              setScale((prev: number) => {
                prevScale.current = prev;
                return scale === 1 ? 1 : scale - 1;
              })
            }
            style={{
              cursor: "pointer",
              width: 20,
              height: 20,
              marginRight: 20,
              marginBottom: -5,
            }}
          />
          <ZoomIn
            onClick={() =>
              setScale((prev: number) => {
                prevScale.current = prev;
                return scale + 1;
              })
            }
            style={{
              cursor: "pointer",
              width: 20,
              height: 20,
              marginRight: 15,
              marginBottom: -5,
            }}
          />
          <Rotate
            onClick={() => setRotate(rotate - 90)}
            style={{
              cursor: "pointer",
              width: 20,
              height: 20,
              marginRight: 15,
              marginBottom: -5,
            }}
          />
        </div>
      </div>

      <Spin spinning={!fileName} wrapperClassName="spin-container">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div id="block-view-pdf">
            <TransformWrapper
              maxScale={3}
              initialScale={1}
              disablePadding={true}
              ref={transformComponentRef}
              onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                if (ref.state.scale >= 3) {
                  setScale((prev: number) => {
                    prevScale.current = prev;
                    return prev + 1;
                  });
                } else if (ref.state.scale <= 1) {
                  setScale((prev: number) => {
                    prevScale.current = prev;
                    return prev - 1 || 1;
                  });
                }
              }}
            >
              <TransformComponent>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(
                    new Array(Math.round(numPages / 2)),
                    (el, index) => (
                      <div
                        style={{ display: "flex", maxHeight: "90%" }}
                        key={`grouppage${index + 1}`}
                      >
                        <Page
                          width={width}
                          height={600}
                          rotate={rotate}
                          scale={scale}
                          canvasRef={canvas}
                          key={`page_${index * 2 + 1}`}
                          pageNumber={index * 2 + 1}
                          onRenderSuccess={onRenderSuccess}
                          onClick={onClickPage}
                        />
                        {index * 2 + 2 <= numPages ? (
                          <Page
                            width={width}
                            height={600}
                            rotate={rotate}
                            scale={scale}
                            key={`page_${index * 2 + 2}`}
                            pageNumber={index * 2 + 2}
                            canvasRef={canvas1}
                            onRenderSuccess={onRenderSuccess}
                            onClick={onClickPage}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  )}
                </Document>
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      </Spin>

      <Space style={{ marginTop: 12, justifyContent: "center" }}>
        <Button
          title="Thay thế file pdf khác"
          type="primary"
          loading={false}
          disabled={!editable}
          style={{ marginLeft: "10px" }}
          onClick={handleIconClick}
        >
          <UploadOutlined />
        </Button>

        {newFile && (
          <Space>
            <span>{newFile?.name}</span>
            <CloseCircleOutlined
              style={{ color: "red" }}
              onClick={handleClearFile}
            />
          </Space>
        )}
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Space>

      {confirmSuccessVisible ? (
        <ConfirmModal
          isMessage={true}
          cancelText="Huỷ bỏ"
          okText="Xác nhận"
          classBtnOk="ant-button-dangerous"
          contents={["Bạn có chắc chắn muốn báo cáo lỗi!"]}
          handleCancel={toggleConfirmSuccessModal}
          handleOk={onSubmitSuccess}
          isLoading={responseUpdateStatusPdf.isLoading}
          type={1}
          visible={confirmSuccessVisible}
          widthConfig={500}
          okBtnDanger={true}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default DetailModal;
