import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const recheckCampaignApi = createApi({
  reducerPath: "recheckCampaignApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/api/v1/tool/",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getListRecheckCampaign: builder.query({
      query: (params) => {
        return {
          url: `/data-campaign-recheck/getPage`,
          method: "GET",
          params: params,
        };
      },
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    updateCampaign: builder.mutation({
      query: (body) => ({
        url: `data-campaign-recheck/edit`,
        method: "POST",
        body,
      }),
      transformResponse: (response: { data: any }, meta, arg) => {
        return response.data;
      },
    }),

    updateStatus: builder.mutation({
      query: (payload) => ({
        url: `data-campaign-recheck/update_status/${payload.id}`,
        method: "PUT",
        params: {
          status: payload.status,
        },
      }),
      transformResponse: (response: { data: any }, meta, arg) => {
        return response.data;
      },
    }),

    getRandomDataPdfRecheck: builder.query<any, any>({
      query: (body) => ({
        url: `data-campaign-recheck/random-compare`,
        method: "GET",
      }),
      transformResponse: (response: any, meta, arg) => {
        return response.data;
      },
    }),

    updateFieldKeyMapValue: builder.mutation<any, any>({
      query: (body) => ({
        url: `data-campaign-recheck/${body.id}/data-key-map`,
        method: "PUT",
        body: body.payload
      }),
      transformResponse: (response: any, meta, arg) => {
        return response.data;
      },
    }),

  }),
});

export const {
  useLazyGetListRecheckCampaignQuery,
  useUpdateCampaignMutation,
  useUpdateStatusMutation,
  useLazyGetRandomDataPdfRecheckQuery,
  useUpdateFieldKeyMapValueMutation,
  useGetRandomDataPdfRecheckQuery
} = recheckCampaignApi;
export default recheckCampaignApi;
