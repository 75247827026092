import { Col, Form, Row, Select } from "antd";
import { useState } from "react";
import { ReactComponent as ZoomIn } from '../../assets/logos/zoom-in-line.svg';
import { ReactComponent as ZoomOut } from '../../assets/logos/zoom-out-line.svg';
import { TYPE_DATA } from "../../assets/ts/constant";
import { CommonTableParams } from "../../redux/api/apiTypes";
import CampaignList from "./components/FieldConfigList";
const RegisterInfo = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState<CommonTableParams<any>>({
        searchParam: undefined,
        page: 0,
        size: 10,
      });

    const onValuesChange = (values: any) => {
        setSearchParam((prev) => ({ searchParam: values, page: 0, size: prev.size }));
    }
    const [isSearch, setIsSearch] = useState(false);
    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Cấu hình trường thông tin</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            {/* <div className='title-label' style={{ marginBottom: 20 }}>Cấu hình trường thông tin</div> */}

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChange}
                >
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsSearch(!isSearch)}>
                        {isSearch ? <ZoomOut style={{ width: 20, paddingTop: 8 }} /> : <ZoomIn style={{ width: 20, paddingTop: 8 }} />}
                        <label className='search-title' style={{ cursor: 'pointer' }}>TÌM KIẾM</label>
                    </div>
                    {isSearch ? <>

                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Loại dữ liệu"
                                    name="typeId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn loại dữ liệu"
                                        optionFilterProp='label'
                                        options={Object.keys(TYPE_DATA).map(item => ({
                                            label: `${TYPE_DATA[item]} (${item})`,
                                            value: item
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => resetFormData()}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div> */}
                    </> : <></>}

                </Form>
            </div>
            <CampaignList form={form} searchParam={searchParam} setSearchParam={setSearchParam}/>
        </>
    )
}

export default RegisterInfo;
