import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonAPIResponse } from '../../../../redux/api/apiTypes';
import { ProvinceParams, ProvinceResponse } from './apiTypes';
// import { FilePdf, FilePdfParams, UploadFileResponse, UploadFileType } from './apiTypes';
// import moment from 'moment';

export const provinceApi = createApi({
    reducerPath: 'provinceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/common/",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getPageProvince: builder.query<CommonAPIResponse<ProvinceResponse>, Partial<ProvinceParams>>({
            query: (body) => {
                let apiParam: Partial<ProvinceParams> = {}
                if(body.provinceCode){
                    apiParam.provinceCode = body.provinceCode;
                }
                if(body.provinceName){
                    apiParam.provinceName = body.provinceName;
                }
                if(typeof body.status ==='number'){
                    apiParam.status = body.status;
                }
                return ({
                    url: `province/getPage`,
                    method: 'GET',
                    params: {
                        search: JSON.stringify(apiParam),
                        page: body.page || 0,
                        size: body.size || 10,
                        sortBy: body.sortBy || "DESC",
                        sortByProperty: body.sortByProperty || "modifiedDate",
                    }
                })
            },
            transformResponse: (response: { data: CommonAPIResponse<ProvinceResponse>, message: string }, meta, arg) => {
                return response.data;
            },
        }),
        ListProvince: builder.query<ProvinceResponse[],any>({
            query: (body) => {
                return ({
                    url: `province/all`,
                    method: 'GET',
                })
            },
            transformResponse: (response: { data: ProvinceResponse[], message: string }, meta, arg) => {
                return response.data;
            },
        }),
        getAllProvinceNoAuth: builder.query<ProvinceResponse[], Partial<ProvinceParams>>({
            query: (body) => {
                 let apiParam: Partial<ProvinceParams> = {}
                if(body.provinceCode){
                    apiParam.provinceCode = body.provinceCode;
                }
                if(body.provinceName){
                    apiParam.provinceName = body.provinceName;
                }
                if(typeof body.status ==='number'){
                    apiParam.status = body.status;
                }
                return ({
                url: `province/getAllNoAuth`,
                method: 'GET',
                params: {
                    search: JSON.stringify(apiParam),
                    page: body.page || 0,
                    size: body.size || 20000,
                    sortBy: body.sortBy || "DESC",
                    sortByProperty: body.sortByProperty || "modifiedDate",
                }
            })
        },
            transformResponse: (response : {data: {content: ProvinceResponse[]}}, meta, arg) => {
                return response.data.content;
            }
        }),

        // Thêm mới Tỉnh/Thành phố
        addProvince: builder.mutation<ProvinceResponse, Partial<ProvinceResponse>>({
            query: (body) => ({
                url: `province`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { body: ProvinceResponse }, meta, arg
            ) => {
                return response.body;
            },
        }),
        // Sửa Tỉnh/Thành phố
        editProvince: builder.mutation<ProvinceResponse, Partial<ProvinceResponse>>({
            query: (body) => ({
                url: `province`,
                method: "PUT",
                body: body,
            }),
            transformResponse: (
                response: { data: ProvinceResponse, message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        
        // Xóa Tỉnh/Thành phố
        deleteProvince: builder.mutation<ProvinceResponse[], string[]>({
            query: (body) => ({
                url: `province/delete`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: ProvinceResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        // Khóa Tỉnh/Thành phố
        lockProvince: builder.mutation<ProvinceResponse[], string[]>({
            query: (body) => ({
                url: `province/lock`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: ProvinceResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        // Kích hoạt Tỉnh/Thành phố
        unlockProvince: builder.mutation<ProvinceResponse[], string[]>({
            query: (body) => ({
                url: `province/unlock`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: ProvinceResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),

    })
})

export const {
    useLazyListProvinceQuery,
    useLazyGetPageProvinceQuery,
    useLazyGetAllProvinceNoAuthQuery,
    useGetAllProvinceNoAuthQuery,
    useAddProvinceMutation,
    useEditProvinceMutation,
    useDeleteProvinceMutation,
    useUnlockProvinceMutation,
    useLockProvinceMutation
} = provinceApi;
export default provinceApi;