import {
  Button,
  FormInstance,
  notification,
  Pagination,
  Row,
  Spin,
  Table,
  Upload,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { CommonTableParams } from "../../../redux/api/apiTypes";
import { checkPermission } from "../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import {
  useGetCountFilePdfQuery,
  useGetFilePdfQuery,
  useUploadFilePdfMutation,
} from "../redux/filePdfApi";
import CreateCampaign from "./CreateCampaign";
import DetailModal from "./DetailModal";
import UploadPdf from "./UploadPdf";
interface DataListTable {
  key: string;
  index: number;
  fileName?: any;
  createBy?: string;
  contentType?: string;
  createdDate: string;
  isChecked: string;
  numberCheck: number;
  timeComplete?: string;
  status: string;
  provinceCode?: string;
  districtCode?: string;
  communeCode?: string;
  message?: string;
  pathFile?: string;
}

type Props = {
  form: FormInstance<any>;
  searchParam: CommonTableParams<any>;
  setSearchParam: React.Dispatch<React.SetStateAction<CommonTableParams<any>>>;
  triggerCampaign: () => void;
};

const FilePdfList = ({
  form,
  searchParam,
  setSearchParam,
  triggerCampaign,
}: Props) => {
  const pageSizeRef = useRef(10);

  const { data: responseFilePdf, isFetching: isPdfFetching } =
    useGetFilePdfQuery(searchParam, { refetchOnMountOrArgChange: true });
  const { data: responseFilePdfCount, isFetching: isCountFetching } =
    useGetCountFilePdfQuery({ searchParam: searchParam?.searchParam });
  const [uploadFile, responseDataInfo] = useUploadFilePdfMutation();
  const [tableData, setTableData] = useState<DataListTable[]>();
  const [loadingFile, setLoadingFile] = useState(false);
  const [createCampaignVisible, setCreateCampaignVisible] = useState(false);
  const [uploadPdfVisible, setUploadPdfVisible] = useState(false);

  const canAddNew = checkPermission(
    PERMISSION_KEYS.DANH_SACH_FILE_PDF.THEM_MOI
  );
  const canEdit = checkPermission(PERMISSION_KEYS.DANH_SACH_FILE_PDF.SUA);

  useEffect(() => {
    if (responseFilePdf?.length) {
      let newTableData: DataListTable[] = [];
      responseFilePdf.forEach((item: any, index: number) => {
        newTableData.push({
          key: item.id,
          index: index + 1,
          districtCode: item.districtCode || "",
          communeCode: item.communeCode || "",
          // fileName: <Link to={`/data/file/detail/${item.id}`}>{item.fileName || ""}</Link>,
          fileName: item.fileName || "",
          numberCheck: item.numberCheck || 0,
          isChecked: item.isChecked
            ? "Đã nhập xong"
            : item.numberCheck
            ? "Chưa nhập xong"
            : "Chưa nhập",
          createBy: item.createdBy || "",
          createdDate: item.createdDate
            ? moment(new Date(item.createdDate)).format("HH:mm DD/MM/YYYY")
            : "",
          timeComplete: item.timeComplete
            ? moment(item.timeComplete).format("HH:mm DD/MM/YYYY")
            : "",
          status: item.status === 1 ? "Có lỗi" : "",
          message: item.message ? item.message.join(", ") : "",
          pathFile: item?.pathFile,
        });
      });
      setTableData(newTableData);
    } else {
      setTableData([]);
    }
  }, [responseFilePdf]);
  // console.log('responseFilePdfCount', responseFilePdfCount);

  const columns: ColumnsType<DataListTable> = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (value, record, index) =>
        (searchParam.page) * pageSizeRef.current + index + 1,
      width: 70,
    },
    {
      title: "Quận huyện",
      dataIndex: "districtCode",
      key: "districtCode",
    },
    {
      title: "Phường xã",
      dataIndex: "communeCode",
      key: "communeCode",
    },
    {
      title: "File pdf",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Số THHT",
      dataIndex: "numberCheck",
      key: "numberCheck",
    },
    {
      title: "Trạng thái",
      dataIndex: "isChecked",
      key: "isChecked",
    },
    // {
    //     title: "Có báo lỗi",
    //     dataIndex: "status",
    //     key: "status",
    // },
    {
      title: "Có báo lỗi",
      dataIndex: "message",
      key: "message",
    },
    // {
    //     title: "Thời gian hoàn thành",
    //     dataIndex: "timeComplete",
    //     key: "timeComplete",
    // },
    // {
    //     title: "Người tạo",
    //     dataIndex: "createBy",
    //     key: "createBy",
    // },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
    },
  ];

  const getData = () => {
    // triggerFilePdf({
    //   searchParam,
    //   page: 0,
    //   size: pageSizeRef.current,
    // });
    setSearchParam({ ...searchParam, page: 1 });
  };

  const changePage = (currentPage: number, pageSize: number) => {
    const pageSizeChange = pageSizeRef.current !== pageSize;
    let newPage = currentPage - 1;
    if (pageSizeChange) {
      newPage = 0;
    }
    // setSearchParam({ ...searchParam, page: newPage });
    pageSizeRef.current = pageSize;

    setSearchParam((prev) => ({
      searchParam: searchParam?.searchParam
        ? {
            ...searchParam?.searchParam,
            isCheck: searchParam?.searchParam?.isCheck,
          }
        : "",
      page: newPage,
      size: pageSizeRef.current,
    }));

    // refetchPdf();
    // triggerFilePdfCount({
    //   searchParam: searchParam?.searchParam
    //     ? {
    //         ...searchParam?.searchParam,
    //         isCheck: searchParam?.searchParam?.isCheck,
    //       }
    //     : "",
    // });
  };

  const beforeUpload = (files: File[]) => {
    let accepted = true;
    let fileList = [...files];
    fileList = fileList.slice(-1);
    Array.from(fileList).forEach((file) => {
      if (file.type !== "application/pdf") {
        accepted = false;
        notification.error({ message: `Định dạng file không hợp kệ` });
      }
    });
    return accepted || Upload.LIST_IGNORE;
  };

  const customUpload = (options: any) => {
    setLoadingFile(true);
    options.onProgress({ percent: 0 });
    let formData = new FormData();
    formData.append("file", options.file);
    uploadFile({
      file: formData,
    })
      .unwrap()
      .then((res: any) => {
        options.onProgress({ percent: 100 });
        options.onSuccess(res, options.file);
        setLoadingFile(false);
        notification.success({ message: "Tải file thành công!" });
        getData();
      })
      .catch((err: any) => {
        options.onError();
        setLoadingFile(false);
        if (err?.data?.message) {
          notification.error({
            message: err?.data?.message,
          });
        }
      });
  };

  const toggleModal = () => {
    setCreateCampaignVisible(!createCampaignVisible);
  };
  const createCampaignSuccess = () => {
    toggleModal();
    getData();
    triggerCampaign();
  };

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<DataListTable>();

  const toggleDetailModal = (record: DataListTable) => {
    setOpenDetailModal(true);
    setCurrentFile(record);
  };

  return (
    <div className="content-box">
      <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
        {/* <Col span={6}>
                </Col> */}
        <div>
          Tổng số: <strong>{responseFilePdfCount ?? 0}</strong> bản ghi
        </div>

        <div className="search-box-button">
          {canAddNew ? (
            // <Upload
            //   showUploadList={false}
            //   customRequest={(options) => customUpload(options)}
            //   accept=".pdf"
            //   beforeUpload={(file, fileList) => beforeUpload(fileList)}
            //   style={{ marginBottom: 0 }}
            //   multiple={true}
            //   listType="text"
            // >
            <Button
              type="primary"
              // loading={loadingFile}
              htmlType="button"
              onClick={() => setUploadPdfVisible(true)}
              style={{ marginBottom: 15, marginLeft: 0 }}
            >
              Tải file
            </Button>
          ) : (
            // </Upload>
            <></>
          )}

          <Button
            disabled={!responseFilePdf || responseFilePdf?.length <= 0}
            htmlType="button"
            type="primary"
            onClick={toggleModal}
          >
            Tạo chiến dịch
          </Button>
        </div>
      </Row>
      <Table<DataListTable>
        loading={isPdfFetching}
        dataSource={tableData}
        columns={columns}
        tableLayout="auto"
        pagination={false}
        scroll={{ x: "max-content" }}
        onRow={(record) => {
          return {
            onClick: () => toggleDetailModal(record),
          };
        }}
      />

      <Spin spinning={isCountFetching} tip="Đang tải...">
        <Pagination
          total={responseFilePdfCount || 0}
          defaultPageSize={pageSizeRef.current}
          defaultCurrent={1}
          locale={{ items_per_page: " dòng" }}
          pageSizeOptions={[10, 20, 50, 100]}
          onChange={changePage}
          showSizeChanger
          current={searchParam.page + 1}
        />
      </Spin>

      {createCampaignVisible ? (
        <CreateCampaign
          searchParam={searchParam}
          visible={createCampaignVisible}
          toggleModal={toggleModal}
          createCampaignSuccess={createCampaignSuccess}
        />
      ) : (
        <></>
      )}

      {openDetailModal ? (
        <DetailModal
          visible={openDetailModal}
          onClose={() => setOpenDetailModal(false)}
          currentFile={currentFile}
          refetch={getData}
        />
      ) : (
        <></>
      )}

      {uploadPdfVisible ? (
        <UploadPdf
          visible={uploadPdfVisible}
          handleClose={() => setUploadPdfVisible(false)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default FilePdfList;
