import { Button, Form, Input, Modal, notification, Select } from "antd";
import React, { useEffect } from "react";
import { helpers } from "../../../assets/js/helper";
import { useLazyGetGroupUserCompareQuery } from "../../../redux/api/commonApi";
import { useCreateCampaignMutation } from "../redux/compareApi";

type Props = {
  visible: boolean;
  onClose: () => void;
  filter: any;
};

const CreateCampaign = ({ visible, onClose, filter }: Props) => {
  const [form] = Form.useForm();
  const [triggerCreateCampaign, { isLoading }] = useCreateCampaignMutation();
  const [triggerGroupUserCheck, responseGroupUserCheck] =
    useLazyGetGroupUserCompareQuery();

  useEffect(() => {
    triggerGroupUserCheck({});
  }, [triggerGroupUserCheck]);

  const onSave = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    // console.log("values", values);
    const { page, size, ...rest } = filter;
    triggerCreateCampaign({ ...values, searchForm: JSON.stringify(rest) })
      .unwrap()
      .then((res) => {
        notification.success({ message: "Tạo chiến dịch thành công!" });
        onClose();
      })
      .catch((error) => {
        notification.error({
          message: error?.data?.message || "Có lỗi xảy ra",
          // message:
          //   error.message === "EXISTED"
          //     ? "Tên chiến dịch đã tồn tại!"
          //     : error.message === "FAILED"
          //     ? "Vui lòng chọn file pdf để tạo chiến dịch!"
          //     : "Có lỗi xảy ra!",
        });
      });
  };

  const onCloseModal = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={visible}
      title="Thêm mới chiến dịch"
      onCancel={onCloseModal}
      width={800}
      maskClosable={false}
      footer={[
        <React.Fragment key="footer">
          <Button htmlType="button" onClick={onCloseModal}>
            Đóng
          </Button>
          <Button
            loading={isLoading}
            htmlType="button"
            type="primary"
            onClick={onSave}
          >
            Thêm chiến dịch
          </Button>
        </React.Fragment>,
      ]}
    >
      <Form
        name="searchBox"
        autoComplete="off"
        form={form}
        labelWrap
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        labelAlign="left"
        requiredMark={false}
        colon={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="Tên chiến dịch"
          name="campaignName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Trường dữ liệu bắt buộc.",
            },
          ]}
        >
          <Input
            placeholder="Nhập tên chiến dịch"
            maxLength={100}
            style={{ minWidth: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Nhóm người dùng"
          name="groupUserIds"
          rules={[
            {
              required: true,
              message: "Trường dữ liệu bắt buộc.",
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Chọn nhóm người dùng"
            options={(responseGroupUserCheck?.data || [])
              .map((item) => ({
                label: item.name,
                value: item.id,
              }))
              .sort((item1, item2) =>
                helpers.compareListByName(item1, item2, "label")
              )}
            showSearch
            optionFilterProp="label"
            mode="multiple"
            maxTagCount="responsive"
          ></Select>
        </Form.Item>

        <Form.Item label="Mô tả" name="description">
          <Input.TextArea placeholder="Nhập mô tả" rows={3} maxLength={200} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCampaign;
