import { Button, Col, Form, Row, Select } from "antd";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as ZoomIn } from "../../../assets/logos/zoom-in-line.svg";
import { ReactComponent as ZoomOut } from "../../../assets/logos/zoom-out-line.svg";
import { TYPE_DATA, USER_ROLES } from "../../../assets/ts/constant";
import { DatePicker } from "../../../custom-components";
import { CommonTableParams, Commune, District } from "../../../redux/api/apiTypes";
import {
  useLazyGetCommuneQuery,
  useLazyGetDistrictQuery,
  useLazyGetManagerCompareQuery,
  useLazyGetProvinceQuery,
  useLazyGetUserCompareQuery,
} from "../../../redux/api/commonApi";
import { useAppSelector } from "../../../redux/Hooks";
import ReportList from "./components/ReportList";
const { RangePicker } = DatePicker;

export const TYPE_ERROR: Record<string, number> = {
  Không: 0,
  Có: 999,
};
export const TYPE_PDF: Record<string, string> = {
  A3: "A3",
  A4: "A4",
};

const NangSuatDoiChieuReport = () => {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const [searchParam, setSearchParam] = useState<CommonTableParams<any>>({
    searchParam: {},
    page: 0,
    size: 10,
  });
  const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
  const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
  const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
  const [districts, setDistricts] = useState<District[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [triggerUserCheck, responseUserCheck] = useLazyGetUserCompareQuery();
  const [triggerManagersCompare, responseManagerCompare] =
    useLazyGetManagerCompareQuery();
  const userState = useAppSelector((state) => state.user.user);

  useEffect(() => {
    triggerUserCheck({});
    // triggerProvince({});
    if (state && typeof state === "object") {
      let newSearchParam: any = { ...state };
      setSearchParam({...searchParam, searchParam: newSearchParam});
      form.setFieldsValue(newSearchParam);
    }
    // else {
    //     form.setFieldsValue({ dates: [new Date(), new Date()] });
    //     setSearchParam({ dates: [new Date(), new Date()] });
    // }
  }, [triggerUserCheck, state]);

  useEffect(() => {
    if (userState?.role === USER_ROLES.ADMIN) triggerManagersCompare({});
  }, [triggerManagersCompare, userState?.role]);

  const onValuesChanged = (changedValues: any) => {
    if (Object.keys(changedValues)[0] === "provinceId") {
      setCommunes([]);
      setDistricts([]);
      if (changedValues.provinceId) {
        districtTrigger(changedValues.provinceId)
          .unwrap()
          .then((res: any) => setDistricts(res));
      } else {
        setDistricts([]);
      }
      form.setFieldsValue({
        districtId: undefined,
        communeId: undefined,
      });
    } else if (Object.keys(changedValues)[0] === "districtId") {
      setCommunes([]);
      if (changedValues.districtId) {
        communeTrigger(changedValues.districtId)
          .unwrap()
          .then((res: any) => setCommunes(res));
      } else {
        setCommunes([]);
      }
      form.setFieldsValue({
        communeId: undefined,
      });
    }
  };

  const onFinish = (values: any) => {
    setSearchParam((prev) => ({ searchParam: values, page: 0, size: prev.size }));
  };

  const resetFormData = () => {
    form.resetFields();
  };
  const [isSearch, setIsSearch] = useState(false);

  return (
    <>
      {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
      {/*    <Breadcrumb.Item href="/home">*/}
      {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
      {/*    </Breadcrumb.Item>*/}
      {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
      {/*    <Breadcrumb.Item href="">*/}
      {/*        <span>Báo cáo hiệu năng</span>*/}
      {/*    </Breadcrumb.Item>*/}
      {/*</Breadcrumb>*/}

      {/* <div className='title-label' style={{ marginBottom: 20 }}>Báo cáo năng suất nhập liệu</div> */}

      <div className="content-box" style={{ marginBottom: 30 }}>
        <Form
          name="searchBox"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          labelWrap
          labelAlign="left"
          colon={false}
          onValuesChange={onValuesChanged}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setIsSearch(!isSearch)}
          >
            {isSearch ? (
              <ZoomOut style={{ width: 20, paddingTop: 8 }} />
            ) : (
              <ZoomIn style={{ width: 20, paddingTop: 8 }} />
            )}
            <label className="search-title" style={{ cursor: "pointer" }}>
              TÌM KIẾM
            </label>
          </div>
          {isSearch ? (
            <>
              <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                <Col span={8} xs={24} md={12} lg={12} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Ngày đối chiếu"
                    name="dates"
                  >
                    <RangePicker
                      format="DD/MM/Y"
                      placeholder={["Bắt đầu", "Kết thúc"]}
                      ranges={{
                        "Hôm nay": [new Date(), new Date()],
                        "Tháng này": [startOfMonth(new Date()), new Date()],
                        "Tháng trước": [
                          startOfMonth(subMonths(new Date(), 1)),
                          endOfMonth(subMonths(new Date(), 1)),
                        ],
                        "3 tháng gần nhất": [
                          startOfMonth(subMonths(new Date(), 2)),
                          new Date(),
                        ],
                        "6 tháng gần nhất": [
                          startOfMonth(subMonths(new Date(), 5)),
                          new Date(),
                        ],
                        "Năm nay": [
                          startOfYear(subMonths(new Date(), 5)),
                          new Date(),
                        ],
                        "Năm trước": [
                          startOfYear(subYears(new Date(), 1)),
                          endOfYear(subYears(new Date(), 1)),
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Từ tài khoản nhập liệu"
                                    name="createByStart"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Từ tài khoản nhập liệu"
                                        options={(responseUserCheck?.data || []).filter(item => item?.roles?.[0]?.authoritieLst?.find((i: any) => i?.authoritieName === "Thêm mới nhập liệu dữ liệu")
                                        ).map(item => ({
                                            label: `${item.fullName} (${item.username})`,
                                            value: item.username
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                        // mode="multiple"
                                        optionFilterProp='label'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Đến tài khoản nhập liệu"
                                    name="createByEnd"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Đến tài khoản nhập liệu"
                                        options={(responseUserCheck?.data || []).filter(item => item?.roles?.[0]?.authoritieLst?.find((i: any) => i?.authoritieName === "Thêm mới nhập liệu dữ liệu")
                                        ).map(item => ({
                                            label: `${item.fullName} (${item.username})`,
                                            value: item.username
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                        // mode="multiple"
                                        optionFilterProp='label'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col> */}

                {/* <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Nhóm người thực hiện"
                                    name="groupUserIds"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn nhóm người thực hiện"
                                        options={(responseGroupUserCheck?.data || []).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        mode="multiple"
                                        maxTagCount='responsive'
                                        optionFilterProp="label"
                                    >
                                    </Select>
                                </Form.Item>
                            </Col> */}

                <Col span={8} xs={24} md={12} lg={12} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Tài khoản đối chiếu"
                    name="userRecheck"
                  >
                    <Select
                      allowClear
                      placeholder="Nhập tài khoản đối chiếu"
                      options={(responseUserCheck?.data || []).map((item) => ({
                        label: `${item.fullName} (${item.username})`,
                        value: item.username,
                      }))}
                      showSearch
                      showArrow
                      maxTagCount="responsive"
                      mode="multiple"
                      optionFilterProp="label"
                    ></Select>
                  </Form.Item>
                </Col>

                {userState?.role === USER_ROLES.ADMIN ? (
                  <Col span={8} xs={24} md={12} lg={12} xl={8}>
                    <Form.Item
                      style={{ marginBottom: 10 }}
                      label="Tài khoản quản lý"
                      name="managers"
                    >
                      <Select
                        allowClear
                        placeholder="Nhập tài khoản quản lý"
                        options={(responseManagerCompare?.data || []).map(
                          (item) => ({
                            label: `${item.fullName} (${item.username})`,
                            value: item.username,
                          })
                        )}
                        showSearch
                        showArrow
                        maxTagCount="responsive"
                        mode="multiple"
                        optionFilterProp="label"
                      ></Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}

                <Col span={8} xs={24} md={12} lg={12} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Loại hộ tịch"
                    name="typeIds"
                  >
                    <Select
                      allowClear
                      placeholder="Chọn loại hộ tịch"
                      optionFilterProp="label"
                      options={Object.keys(TYPE_DATA).map((item) => ({
                        label: `${TYPE_DATA[item]} (${item})`,
                        value: item,
                      }))}
                      showSearch
                      showArrow
                      mode="multiple"
                      maxTagCount={"responsive"}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} md={12} lg={12} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Kích thước sổ"
                    name="typePdf"
                  >
                    <Select
                      allowClear
                      placeholder="Chọn kích thước sổ"
                      optionFilterProp="label"
                      options={Object.keys(TYPE_PDF).map((item) => ({
                        label: `${TYPE_PDF[item]}`,
                        value: item,
                      }))}
                      showSearch
                      showArrow
                      maxTagCount={"responsive"}
                    ></Select>
                  </Form.Item>
                </Col>
                {/* <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Số trường sai sót"
                                name="totalFieldError"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn số trường sai sót"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_ERROR).map(item => ({
                                        label: `${item}`,
                                        value: TYPE_ERROR[item]
                                    }))}
                                    showSearch
                                    showArrow
                                    maxTagCount={"responsive"}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Số trường đã chỉnh sửa"
                                name="totalFieldEdit"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn số trường đã chỉnh sửa"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_ERROR).map(item => ({
                                        label: `${item}`,
                                        value: TYPE_ERROR[item]
                                    }))}
                                    showSearch
                                    showArrow
                                    maxTagCount={"responsive"}
                                >
                                </Select>
                            </Form.Item>
                        </Col> */}
              </Row>
              <div className="search-box-button">
                <Button htmlType="button" onClick={() => resetFormData()}>
                  Xóa điều kiện
                </Button>
                <Button htmlType="submit" type="primary">
                  Tìm kiếm
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </Form>
      </div>
      <ReportList form={form} setSearchParam={setSearchParam} searchParam={searchParam} />
    </>
  );
};

export default NangSuatDoiChieuReport;
