import {Button, Col, Drawer, Form, Input, notification, Row, Select, Space, Tree, TreeProps} from "antd";
import React, { useEffect, useState } from "react";

import { useAddMutation, useEditMutation, useLazyGetDetailQuery, useLazyGetUserQuery, useLazyGetRoleQuery } from "../redux/groupUserApi";

import { ReactComponent as Close } from "../../../assets/logos/close-contained.svg";
import TextArea from "antd/lib/input/TextArea";
import { DataNode } from "antd/lib/tree";
import { arrayToTree } from "performant-array-to-tree";
import {Role} from "../../permissions/redux/apiTypes";

interface AddroleProps {
    visible: boolean;
    onCancel: () => void;
    currentRole: any | undefined;
    onAddSuccessful: () => void;
    canEdit: boolean;
}

const AddGroupUserModal = ({ visible, onCancel, currentRole, onAddSuccessful, canEdit }: AddroleProps) => {
    const [form] = Form.useForm();

    /* api */
    const [addTrigger] = useAddMutation();
    const [editTrigger] = useEditMutation();
    const [detailTrigger, detailResponse] = useLazyGetDetailQuery();
    const [triggerUser, responseUser] = useLazyGetUserQuery();
    const [triggerRole, responseRole] = useLazyGetRoleQuery();

    const [editing, setEditing] = useState<boolean>(false);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (currentRole) {
            form.setFieldsValue({
                ...currentRole
            });
            detailTrigger(currentRole.id);
            // console.log('currentRole', currentRole)
        }
        else {
            form.setFieldsValue({
                status: 0,
            })
        }
    }, [currentRole, detailTrigger, form]);

    const onFinish = (values: any) => {

        let data: any = {};
        let users: any[] = [];
        let roles: any[] = [];

        if (currentRole) {
            data = {
                ...currentRole,
                ...values
            }
        } else {
            data = {
                ...values
            }
        }

        if (values.users) {
            for (let i=0; i < values.users.length; i++) {
                users.push({
                    username: values.users[i]
                });
            }
        }
        if (values.roles) {
            for (let i=0; i < values.roles.length; i++) {
                roles.push({
                    id: values.roles[i]
                });
            }
        }

        data.users = users;
        data.roles = roles;

        // console.log('dataApi', data);

        if (!loadingBtnSubmit) {
            setLoadingBtnSubmit(true);
            if (currentRole) {
                editTrigger(data).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Cập nhật Nhóm người dùng thành công.'
                        });
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
            else {
                addTrigger(data).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Thêm mới Nhóm người dùng thành công.'
                        });
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
        }
    };

    useEffect(() => {
        triggerUser({});
        triggerRole({});
    }, [triggerUser, triggerRole]);

    return (
        <div>
            <Drawer
                closable={false}
                className="custom-drawer"
                width={'60%'}
                title={currentRole ?
                    <div>
                        <div className="drawer-title">
                            <span>{`${editing ? 'Chỉnh sửa' : 'Thông tin'}`} nhóm người dùng</span>
                            <Close className="title-edit-icon" style={{ cursor: 'pointer', width: 40, height: 40 }} onClick={() => onCancel()} />
                        </div>
                    </div>
                    : <div className="drawer-title">
                        <span>Thêm nhóm người dùng mới</span>
                        <Close style={{ cursor: 'pointer', width: 40, height: 40 }} onClick={() => onCancel()} />
                    </div>}
                placement="right"
                visible={visible}
                onClose={() => onCancel()}
                // mask={false}
                footer={[
                    <Row align="middle" justify="end" className='row-drawer' style={{ zIndex: 10 }}>
                        <Space>

                            <Button
                                htmlType='button'
                                // onClick={() => ischeckUpdate ? setQuitVisible(true) : resetState()}
                                className="drawer-button"
                                onClick={() => onCancel()}
                            >
                                Đóng
                            </Button>

                            {(editing) ? <Button
                                htmlType='button'
                                type='primary'
                                className='primary-blue-button drawer-button'
                                onClick={() => form.submit()}
                                // disabled={canEdit ? false : true}
                                loading={loadingBtnSubmit}
                            >
                                Lưu
                            </Button> : <></>}

                            {!currentRole ? <Button
                                htmlType='button'
                                type='primary'
                                className='primary-blue-button drawer-button'
                                onClick={() => form.submit()}
                                // disabled={canEdit ? false : true}
                                loading={loadingBtnSubmit}
                            >
                                Thêm mới
                            </Button> : <></>}

                            {(currentRole && canEdit && !editing) && <Button className="'primary-blue-button drawer-button" type="primary" onClick={() => setEditing(!editing)}>Chỉnh sửa</Button>}

                        </Space>
                    </Row>
                ]}
                maskStyle={{ backgroundColor: 'transparent' }}
            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    labelAlign="left"
                    form={form}
                    scrollToFirstError
                    onFinish={onFinish}
                >
                    <Row gutter={20}>
                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Tên nhóm người dùng"
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: "Vui lòng nhập tên nhóm người dùng" }
                                ]}
                            >
                                <Input maxLength={125} disabled={currentRole && !editing ? true : false} className="propNameInput" placeholder="Nhập tên nhóm người dùng" allowClear autoComplete="on"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Mô tả"
                                name="description"
                            >
                                <TextArea
                                    disabled={currentRole && !editing ? true : false}
                                    allowClear
                                    maxLength={500}
                                    placeholder="Nhập nội dung mô tả"
                                    // rows={3}
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Người dùng"
                                name="users"
                                rules={[
                                    { required: true, message: "Vui lòng thêm người dùng" }
                                ]}
                            >
                                <Select
                                    disabled={currentRole && !editing ? true : false}
                                    allowClear
                                    placeholder="Chọn người dùng"
                                    options={   (responseUser?.data || []).map(item => ({
                                        label: `${item.fullName} (${item.username})`,
                                        value: item.username
                                    }))}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Quản lý"
                                name="manage"
                                rules={[
                                    { required: true, whitespace: true, message: "Vui lòng nhập quản lý" }
                                ]}
                            >
                                <Select
                                    disabled={currentRole && !editing ? true : false}
                                    allowClear
                                    placeholder="Chọn quản lý"
                                    options={(responseUser?.data || []).map(item => ({
                                        label: `${item.fullName} (${item.username})`,
                                        value: item.username
                                    }))}
                                    showSearch
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Nhóm quyền"
                                name="roles"
                                rules={[
                                    { required: true, message: "Vui lòng chọn nhóm quyền" }
                                ]}
                            >
                                <Select
                                    disabled={currentRole && !editing ? true : false}
                                    allowClear
                                    placeholder="Chọn nhóm quyền"
                                    optionFilterProp='label'
                                    options={(responseRole?.data || []).map(item => ({
                                        label: `${item.roleName}`,
                                        value: item.id
                                    }))}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Trạng thái"
                                name="status"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn trạng thái"
                                    showArrow
                                >
                                    <Select.Option value={0}>Hoạt động</Select.Option>
                                    <Select.Option value={1}>Không hoạt động</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                </Form>
            </Drawer>
        </div>
    );
};

export default AddGroupUserModal;
