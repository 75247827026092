import { Button, Form, Input, InputNumber, Modal, notification, Select } from "antd"
import React, { useEffect, useState } from "react"
import { helpers } from "../../../assets/js/helper"
import { useCreateCampaignMutation, useLazyGetUserCheckQuery } from "../../../redux/api/commonApi"

type Props = {
    visible: boolean,
    toggleModal: () => void,
    createCampaignSuccess: () => void,
    searchParam: any
}

const CreateCampaign = ({ visible, toggleModal, searchParam, createCampaignSuccess }: Props) => {
    const [form] = Form.useForm();
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckQuery();
    const [triggerCreateCampaign, { isLoading }] = useCreateCampaignMutation();
    const [typeDivide, setTypeDivide] = useState(1);
    useEffect(() => {
        triggerUserCheck({});
    }, [triggerUserCheck])
    const onSave = () => {
        form.submit();
    }
    const onFinish = (value: any) => {
        triggerCreateCampaign({ ...value, searchForm: JSON.stringify(searchParam) }).unwrap().then(res => {
            notification.success({ message: "Tạo chiến dịch thành công!" });
            createCampaignSuccess();
        }).catch(err => {
            notification.error({ message: err?.data?.message || "Có lỗi xảy ra!" });
        })
    }
    const onChangeTypeDivide = (value: number) => {
        setTypeDivide(value);
        form.setFieldsValue({
            number: undefined,
            percent: undefined,
        })
    }

    const onCloseModal = () => {
        form.resetFields();
        toggleModal();
    }

    return (
        <Modal
            visible={visible}
            title="Thêm mới chiến dịch"
            onCancel={onCloseModal}
            width={800}
            maskClosable={false}
            footer={[
                <React.Fragment key="footer">
                    <Button htmlType='button' onClick={onCloseModal}>
                        Đóng
                    </Button>
                    <Button loading={isLoading} htmlType='button' type='primary' onClick={onSave}>
                        Thêm chiến dịch
                    </Button>
                </React.Fragment>
            ]}>
            <Form
                name="searchBox"
                autoComplete="off"
                form={form}
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                labelAlign="left"
                requiredMark={false}
                colon={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Tên chiến dịch"
                    name="campaignName"
                    rules={[
                        { required: true, whitespace: true, message: "Trường dữ liệu bắt buộc." }
                    ]}>
                    <Input placeholder="Nhập tên chiến dịch" style={{ minWidth: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Loại phân công"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <Select
                        placeholder="Chọn loại phân công"
                        value={typeDivide}
                        onChange={onChangeTypeDivide}
                    >
                        <Select.Option value={1}>Phân công tuyệt đối</Select.Option>
                        <Select.Option value={2}>Phân công tương đối</Select.Option>
                    </Select>
                </Form.Item>
                {typeDivide === 1 ?
                    <Form.Item
                        label="Số lượng bản ghi"
                        name="number"
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc." }
                        ]}
                    >
                        <InputNumber min={0} style={{ minWidth: '100%' }} precision={0} />
                    </Form.Item> : <></>}
                {typeDivide === 2 ? <Form.Item
                    label="Phần trăm dữ liệu"
                    name="percent"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <InputNumber min={0} max={100} style={{ minWidth: '100%' }} precision={0} />
                </Form.Item> : <></>}
                <Form.Item
                    label="Người thực hiện"
                    name="userIds"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <Select
                        allowClear
                        placeholder="Chọn người thực hiện"
                        options={(responseUserCheck?.data || []).map(item => ({
                            label: `${item.fullName} (${item.username})`,
                            value: item.id
                        })).sort((item1, item2) => helpers.compareListByName(item1, item2, "label"))}
                        showSearch
                        optionFilterProp="label"
                        mode="multiple"
                    >
                    </Select>
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                    <Input.TextArea placeholder="Nhập mô tả" rows={3} maxLength={200} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateCampaign