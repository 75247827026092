import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';
import moment from 'moment';
import { CommonListQuery, } from '../../../../redux/api/apiTypes';

export const nangXuatKiemTraReportApi = createApi({
    reducerPath: 'nangXuatKiemTraReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/reports",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getReport: builder.query<any, { searchParam?: any } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/report-nang-suat-kiem-tra?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}&sort=createdDate,desc`,
                method: 'GET',
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        getReportTotal: builder.query<number, { searchParam?: any } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/report-nang-suat-kiem-tra-count?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}&sort=createdDate,desc`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        exportData: builder.mutation<any, { searchParam?: any } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/report-nang-suat-kiem-tra/export?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
                responseHandler: async (response) => {
                    //Check dữ liệu = null k xuất excel
                    if(response.status === 204){
                        throw new Error("Không có dữ liệu để xuất excel!");
                    }
                    let filename = `${format(new Date(), "dd-MM-yyyy")}_RpNangSuatKiemTra.xlsx`;
                    let blob = await response.blob();
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    link.click();
                },
                cache: "no-cache",
            }),
        }),
    })
});

export const {
    useLazyGetReportQuery,
    useLazyGetReportTotalQuery,
    useExportDataMutation,
    useGetReportQuery
} = nangXuatKiemTraReportApi;

export default nangXuatKiemTraReportApi;
