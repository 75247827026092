import { Button, Form, Input, Modal, notification, Select, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { helpers } from "../../../assets/js/helper";
import { useLazyGetGroupUserCheckQuery } from "../../../redux/api/commonApi";
import { useEditCampaignMutation } from "../redux/campaignApi";

type Props = {
  visible: boolean;
  onClose: () => void;
  campaign?: any;
  resetState: () => void;
};

const UpdateCampaign = ({ visible, onClose, campaign, resetState }: Props) => {
  const [form] = Form.useForm();
  const [triggerGroupUserCheck, responseGroupUserCheck] =
    useLazyGetGroupUserCheckQuery();
  const [editable, setEditable] = useState<boolean>(false);

  const [triggerUpdateCampaign, { isLoading }] = useEditCampaignMutation();
  const typeDivideValue = Form.useWatch("typeDivide", form);

  useEffect(() => {
    triggerGroupUserCheck({});
  }, [triggerGroupUserCheck]);

  const isDataLoaded = useMemo(() => {
    return (
      responseGroupUserCheck &&
      !responseGroupUserCheck.isFetching &&
      responseGroupUserCheck.status !== "uninitialized"
    );
  }, [responseGroupUserCheck]);

  useEffect(() => {
    if (isDataLoaded) {
      console.log("campaign", campaign);
      form.setFieldValue("campaignName", campaign?.campaignName);
      form.setFieldValue(
        "groupUserIds",
        campaign?.groupUserIdsStr?.length > 0
          ? campaign?.groupUserIdsStr
          : undefined
      );
      form.setFieldValue("typeDivide", 2);
      form.setFieldValue("description", campaign?.description);
      form.setFieldValue("percent", campaign?.percent);
      form.setFieldValue("number", campaign?.number);
    }
  }, [
    campaign?.campaignName,
    campaign?.description,
    campaign?.groupUserIdsStr,
    campaign?.listGroupUsers,
    campaign?.number,
    campaign?.percent,
    form,
    isDataLoaded,
  ]);

  const handleFooterAction = () => {
    if (!editable) {
      setEditable(!editable);
    } else {
      form.submit();
    }
  };

  const onFinish = (values: any) => {
    triggerUpdateCampaign({ ...values, id: campaign?.key })
      .unwrap()
      .then(() => {
        notification.success({ message: "Chỉnh sửa chiến dịch thành công!" });
        handleClose();
        resetState();
      })
      .catch((error) => {
        notification.error({
          message: error.data.message || "Có lỗi xảy ra",
        });
      });
  };

  const titleModal = useMemo(() => {
    return editable ? "Chỉnh sửa chiến dịch" : "Thông tin chiến dịch";
  }, [editable]);

  const buttonLabel = useMemo(() => {
    return editable ? "Lưu" : "Chỉnh sửa";
  }, [editable]);

  const handleClose = () => {
    if (!onClose) return;
    onClose();
    setEditable(false);
  };

  return (
    <Modal
      open={visible}
      title={titleModal}
      onCancel={handleClose}
      width={800}
      maskClosable={false}
      footer={[
        <React.Fragment key="footer">
          <Button htmlType="button" onClick={handleClose}>
            Đóng
          </Button>
          <Button
            loading={isLoading}
            htmlType="button"
            type="primary"
            onClick={handleFooterAction}
            disabled={!isDataLoaded}
          >
            {buttonLabel}
          </Button>
        </React.Fragment>,
      ]}
    >
      <Spin spinning={responseGroupUserCheck.isFetching} tip="Đang tải...">
        <Form
          name="searchBox"
          autoComplete="off"
          form={form}
          labelWrap
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          labelAlign="left"
          requiredMark={false}
          colon={false}
          onFinish={onFinish}
        >
          <Form.Item
            label="Tên chiến dịch"
            name="campaignName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Trường dữ liệu bắt buộc.",
              },
            ]}
          >
            <Input
              disabled={!editable}
              placeholder="Nhập tên chiến dịch"
              maxLength={100}
              style={{ minWidth: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Nhóm người dùng"
            name="groupUserIds"
            rules={[
              {
                required: true,
                message: "Trường dữ liệu bắt buộc.",
              },
            ]}
          >
            <Select
              allowClear
              disabled={!editable}
              placeholder="Chọn nhóm người dùng"
              options={(responseGroupUserCheck?.data || [])
                .map((item) => ({
                  label: item.name,
                  value: item.id,
                }))
                .sort((item1, item2) =>
                  helpers.compareListByName(item1, item2, "label")
                )}
              showSearch
              optionFilterProp="label"
              mode="multiple"
              maxTagCount="responsive"
            ></Select>
          </Form.Item>

          <Form.Item
            label="Loại phân công"
            name="typeDivide"
            rules={[{ required: true, message: "Trường dữ liệu bắt buộc." }]}
          >
            <Select placeholder="Chọn loại phân công" disabled={true}>
              <Select.Option value={1}>Phân công tuyệt đối</Select.Option>
              <Select.Option value={2}>Phân công tương đối</Select.Option>
            </Select>
          </Form.Item>

          {typeDivideValue === 1 ? (
            <Form.Item
              label="Số lượng bản ghi"
              name="number"
              rules={[
                { required: true, message: "Trường dữ liệu bắt buộc." },
                () => ({
                  validator(_: any, value: string) {
                    if (value && parseInt(value) === 0) {
                      return Promise.reject(
                        new Error("Số lượng bản ghi lớn hơn 0")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input disabled={!editable} placeholder="Nhập số lượng bản ghi" />
            </Form.Item>
          ) : (
            <Form.Item
              label="Phần trăm dữ liệu"
              name="percent"
              rules={[
                { required: true, message: "Trường dữ liệu bắt buộc." },
                () => ({
                  validator(_: any, value: number) {
                    if (value && value <= 0) {
                      return Promise.reject(
                        new Error("Phần trăm dữ liệu lớn hơn 0")
                      );
                    }

                    if (value && value > 100) {
                      return Promise.reject(
                        new Error("Phần trăm dữ liệu nhỏ hơn 100%")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={!editable}
                  placeholder="Nhập phần trăm dữ liệu"
                  type="number"
              />
            </Form.Item>
          )}

          <Form.Item label="Mô tả" name="description">
            <Input.TextArea
              disabled={!editable}
              placeholder="Nhập mô tả"
              rows={3}
              maxLength={500}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateCampaign;
