import { Button, Form, Input, Modal, notification, Select, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { helpers } from "../../../assets/js/helper";
import { useLazyGetGroupUserCompareQuery } from "../../../redux/api/commonApi";
import { useAppSelector } from "../../../redux/Hooks";
import { checkPermission } from "../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { DataListCampaignTable } from "../redux/apiTypes";
import { useUpdateCampaignMutation } from "../redux/recheckCampaignApi";

type Props = {
  visible: boolean;
  onClose: () => void;
  filter?: {
    name?: string;
    description?: string;
    page: number;
    size: number;
  };
  campaign?: DataListCampaignTable;
  resetState: () => void;
};

const UpdateCampaign = ({ visible, onClose, campaign, resetState }: Props) => {
  const [form] = Form.useForm();
  const isAdmin = useAppSelector((state) => state.user.isAdmin);
  // const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckQuery();
  const [triggerGroupUserCheck, responseGroupUserCheck] =
    useLazyGetGroupUserCompareQuery();
  const [triggerUpdateCampaign, { isLoading }] = useUpdateCampaignMutation();
  const [editable, setEditable] = useState<boolean>(false);

  const canEdit = checkPermission(
    PERMISSION_KEYS.CHIEN_DICH_DOI_CHIEU.CHINH_SUA
  );

  useEffect(() => {
    // triggerUserCheck({});
    triggerGroupUserCheck({});
  }, [triggerGroupUserCheck]);

  const isDataLoaded = useMemo(() => {
    return (
      responseGroupUserCheck &&
      !responseGroupUserCheck.isFetching &&
      responseGroupUserCheck.status !== "uninitialized"
    );
  }, [responseGroupUserCheck]);

  useEffect(() => {
    if (isDataLoaded) {
      form.setFieldValue("campaignName", campaign?.campaignName);
      form.setFieldValue("groupUserIds", campaign?.groupUserIdsStr);
      form.setFieldValue("description", campaign?.description);
      form.setFieldValue("userIds", campaign?.userIdsStr);
    }
  }, [
    campaign?.campaignName,
    campaign?.description,
    campaign?.groupUserIdsStr,
    campaign?.userIdsStr,
    form,
    isDataLoaded,
  ]);

  // const userIdsValue = Form.useWatch("userIds", form);
  // const groupsUserIdsValue = Form.useWatch("groupUserIds", form);

  const handleFooterAction = () => {
    if (!editable) {
      setEditable(!editable);
    } else {
      form.submit();
    }
  };

  const onFinish = (values: any) => {
    triggerUpdateCampaign({ ...values, id: campaign?.id })
      .unwrap()
      .then(() => {
        notification.success({ message: "Chỉnh sửa chiến dịch thành công!" });
        handleClose();
        resetState();
      })
      .catch((error) => {
        notification.error({
          message: error.data.message || "Có lỗi xảy ra",
          // error.data.message === "EXISTED"
          //   ? "Tên chiến dịch đã tồn tại!"
          //   : "Có lỗi xảy ra!",
        });
      });
  };

  const onChangeSelect = (resetKey: string) => {
    form.setFields([
      {
        name: resetKey,
        errors: undefined,
      },
    ]);
  };

  const titleModal = useMemo(() => {
    return editable ? "Chỉnh sửa chiến dịch" : "Thông tin chiến dịch";
  }, [editable]);

  const buttonLabel = useMemo(() => {
    return editable ? "Lưu" : "Chỉnh sửa";
  }, [editable]);

  const handleClose = () => {
    if (!onClose) return;
    onClose();
    setEditable(false);
  };

  return (
    <Modal
      open={visible}
      title={titleModal}
      onCancel={handleClose}
      width={800}
      maskClosable={false}
      footer={[
        <React.Fragment key="footer">
          <Button htmlType="button" onClick={handleClose}>
            Đóng
          </Button>
          {canEdit && (
            <Button
              loading={isLoading}
              htmlType="button"
              type="primary"
              onClick={handleFooterAction}
              disabled={!isDataLoaded}
            >
              {buttonLabel}
            </Button>
          )}
        </React.Fragment>,
      ]}
    >
      <Spin spinning={responseGroupUserCheck.isFetching} tip="Đang tải...">
        <Form
          name="searchBox"
          autoComplete="off"
          form={form}
          labelWrap
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          labelAlign="left"
          requiredMark={false}
          colon={false}
          onFinish={onFinish}
        >
          <Form.Item
            label="Tên chiến dịch"
            name="campaignName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Trường dữ liệu bắt buộc.",
              },
            ]}
          >
            <Input
              placeholder="Nhập tên chiến dịch"
              disabled={!editable}
              style={{ minWidth: "100%" }}
            />
          </Form.Item>
          {isAdmin && (
            <Form.Item
              label="Nhóm người dùng"
              name="groupUserIds"
              rules={[
                {
                  required: true,
                  // required: !userIdsValue || userIdsValue?.length === 0,
                  message: "Trường dữ liệu bắt buộc.",
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Chọn nhóm người dùng"
                options={(responseGroupUserCheck?.data || [])
                  .map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                  .sort((item1, item2) =>
                    helpers.compareListByName(item1, item2, "label")
                  )}
                showSearch
                optionFilterProp="label"
                mode="multiple"
                maxTagCount="responsive"
                onChange={() => onChangeSelect("userIds")}
                disabled={!editable}
              ></Select>
            </Form.Item>
          )}

          {/* <Form.Item
            label="Người thực hiện"
            name="userIds"
            rules={[
              {
                required:
                  !groupsUserIdsValue || groupsUserIdsValue?.length === 0,
                message: "Trường dữ liệu bắt buộc.",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Chọn người thực hiện"
              options={(responseUserCheck?.data || [])
                .map((item) => ({
                  label: `${item.fullName} (${item.username})`,
                  value: item.id,
                }))
                .sort((item1, item2) =>
                  helpers.compareListByName(item1, item2, "label")
                )}
              showSearch
              optionFilterProp="label"
              mode="multiple"
              onChange={() => onChangeSelect("groupUserIds")}
            ></Select>
          </Form.Item> */}
          <Form.Item label="Mô tả" name="description">
            <Input.TextArea
              placeholder="Nhập mô tả"
              rows={3}
              maxLength={200}
              disabled={!editable}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateCampaign;
