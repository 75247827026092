export const PERMISSION_KEYS = {
    // chien dich kiểm tra
    CHIEN_DICH_KIEM_TRA: {
        DANH_SACH: "ROLE_SYSTEM_DATACAMPAIGN_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATACAMPAIGN_ADD",
        SUA: "ROLE_SYSTEM_DATACAMPAIGN_EDIT",
        XOA: "ROLE_SYSTEM_DATACAMPAIGN_DELETE",
    },
    
    // chien dich nhập lieu
    CHIEN_DICH_NHAP_LIEU: {
        DANH_SACH: "ROLE_SYSTEM_DATA_ENTRY_CAMPAIGN_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATA_ENTRY_CAMPAIGN_ADD",
        SUA: "ROLE_SYSTEM_DATA_ENTRY_CAMPAIGN_EDIT",
        XOA: "ROLE_SYSTEM_DATA_ENTRY_CAMPAIGN_DELETE",
    },

    // Cau hinh truong thong tin
    THAM_SO_HE_THONG: {
        DANH_SACH: "ROLE_SYSTEM_PARAM_VIEW",
        THEM_MOI: "ROLE_SYSTEM_PARAM_ADD",
        SUA: "ROLE_SYSTEM_PARAM_EDIT",
        XOA: "ROLE_SYSTEM_PARAM_DELETE",
    },
    // Danh sách file pdf
    DANH_SACH_FILE_PDF: {
        DANH_SACH: "ROLE_SYSTEM_DATA_PDF_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATA_PDF_ADD",
        SUA: "ROLE_SYSTEM_DATA_PDF_EDIT",
        XOA: "ROLE_SYSTEM_DATA_PDF_DELETE",
    },
    // 

    // nhap lieu du lieu
    NHAP_LIEU_DU_LIEU: {
        DANH_SACH: "ROLE_SYSTEM_DATA_ENTRY_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATA_ENTRY_ADD",
        SUA: "ROLE_SYSTEM_DATA_ENTRY_EDIT",
        XOA: "ROLE_SYSTEM_DATA_ENTRY_DELETE",
        ADD_BO_SUNG: "ROLE_SYSTEM_DATA_ENTRY_ADDITIONAL",
    },
    // kho du lieu nhap lieu 
    KHO_DU_LIEU_NHAP_LIEU: {
        DANH_SACH: "ROLE_SYSTEM_DATA_ENTRY_DATA_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATA_ENTRY_DATA_ADD",
        SUA: "ROLE_SYSTEM_DATA_ENTRY_DATA_EDIT",
        XOA: "ROLE_SYSTEM_DATA_ENTRY_DATA_DELETE",
    },

    // kiem tra du lieu / du lieu so hoa
    KIEM_TRA_DU_LIEU: {
        DANH_SACH: "ROLE_DATA_CHECK_VIEW",
    },
    DONG_BO_DU_LIEU: {
        DANH_SACH: "ROLE_DATA_INIT_COMPARE_VIEW",
        ADD: "ROLE_DATA_INIT_COMPARE_ADD",
        THEM_MOI: "ROLE_DATA_INIT_COMPARE_ADD",
        SUA: "ROLE_DATA_INIT_COMPARE_EDIT",
        XOA: "ROLE_DATA_INIT_COMPARE_DELETE",
    },

    // kho du lieu kiem tra
    KHO_DU_LIEU_KIEM_TRA: {
        DANH_SACH: "ROLE_ARCHIVE_DATA_CHECK_VIEW",
    },

    // Doi chieu dư lieu
    DOI_CHIEU_DU_LIEU: {
        ROOT: "ROLE_SYSTEM_DATA_RECHECK",
        VIEW: "ROLE_SYSTEM_DATA_RECHECK_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATA_RECHECK_ADD",
        XOA: "ROLE_SYSTEM_DATA_RECHECK_DELETE",
    },
    KHO_DU_LIEU_DOI_CHIEU: {
        ROOT: "ROLE_SYSTEM_DATA_RECHECK_DATA",
        VIEW: "ROLE_SYSTEM_DATA_RECHECK_DATA_VIEW",
        CHINH_SUA: "ROLE_SYSTEM_DATA_RECHECK_DATA_EDIT",
        THEM_MOI: "ROLE_SYSTEM_DATA_RECHECK_DATA_ADD",
        XOA: "ROLE_SYSTEM_DATA_RECHECK_DATA_DELETE",
    },
    CHIEN_DICH_DOI_CHIEU: {
        ROOT: "ROLE_SYSTEM_DATA_RECHECK_CAMPAIGN",
        VIEW: "ROLE_SYSTEM_DATA_RECHECK_CAMPAIGN_VIEW",
        CHINH_SUA: "ROLE_SYSTEM_DATA_RECHECK_CAMPAIGN_EDIT",
        THEM_MOI: "ROLE_SYSTEM_DATA_RECHECK_CAMPAIGN_ADD",
        XOA: "ROLE_SYSTEM_DATA_RECHECK_CAMPAIGN_DELETE"
    },
    
    // kho dữ liệu 
    KHO_DU_LIEU: {
        DANH_SACH: "ROLE_DATA_ARCHIVE_VIEW",
        THEM_MOI: "ROLE_DATA_ARCHIVE_ADD",
        SUA: "ROLE_DATA_ARCHIVE_EDIT",
        XOA: "ROLE_DATA_ARCHIVE_DELETE",
    },

    // kho dư liệu hộ tịch
    KHO_DU_LIEU_HO_TICH: {
        DANH_SACH: "ROLE_KHO_DATA_HO_TICH",
        KS: "ROLE_KHO_DATA_HO_TICH_KS",
        KT: "ROLE_KHO_DATA_HO_TICH_KT",
        KH: "ROLE_KHO_DATA_HO_TICH_KH",
        HN: "ROLE_KHO_DATA_HO_TICH_HN",
        CMC: "ROLE_KHO_DATA_HO_TICH_CMC",
        GH: "ROLE_KHO_DATA_HO_TICH_CH",
        LH: "ROLE_KHO_DATA_HO_TICH_LH",
        CC: "ROLE_KHO_DATA_HO_TICH_CC",
        CN: "ROLE_KHO_DATA_HO_TICH_CN",
    },

    // báo cáo 
    BAO_CAO: {
        DANH_SACH: "ROLE_REPORT",
        BAO_CAO_NHAP_LIEU: "ROLE_REPORT_DATA_ENTRY",
        BAO_CAO_HIEU_NANG_CHINH_SUA: "ROLE_REPORT_PERFORMANCE_EDIT",
        BAO_CAO_KIEM_TRA: "ROLE_REPORT_CHECK",
        BAO_CAO_THEO_TRUONG_DU_LIEU: "ROLE_REPORT_FIELD",
        BAO_CAO_THOI_GIAN_LAM_VIEC: "ROLE_REPORT_WORKING_TIME",
        TIEN_DO_KIEM_TRA: "ROLE_REPORT_TIEN_DO_KIEM_TRA",
        NANG_XUAT_NHAP_LIEU: "ROLE_REPORT_NANG_XUAT_NHAP_LIEU",
        NANG_XUAT_KIEM_TRA: "ROLE_REPORT_NANG_XUAT_KIEM_TRA",
        NANG_SUAT_DOI_CHIEU: "ROLE_REPORT_NANG_SUAT_DOI_CHIEU",
        UPLOAD_DATA: "ROLE_REPORT_UPLOAD_DATA",
        UPLOAD_PDF: "ROLE_REPORT_UPLOAD_PDF",
        CAC_THHT_ERROR: "ROLE_REPORT_CAC_THHT_ERROR",
        NS_BAO_LOI: "ROLE_REPORT_NS_BAO_LOI"
    },

    // quan tri he thong
    QUAN_TRI_HE_THONG: {
        NGUOI_DUNG: {
            DANH_SACH: "ROLE_SYSTEM_USER_VIEW",
            THEM_MOI: "ROLE_SYSTEM_USER_ADD",
            SUA: "ROLE_SYSTEM_USER_EDIT",
            XOA: "ROLE_SYSTEM_USER_DELETE",
        },
        NHOM_NGUOI_DUNG: {
            DANH_SACH: "ROLE_SYSTEM_GROUP_USER",
            THEM_MOI: "ROLE_SYSTEM_GROUP_USER_ADD",
            SUA: "ROLE_SYSTEM_GROUP_USER_EDIT",
            XOA: "ROLE_SYSTEM_GROUP_USER_DELETE",
        },
        NHOM_QUYEN: {
            DANH_SACH: "ROLE_SYSTEM_GROUP_VIEW",
            THEM_MOI: "ROLE_SYSTEM_GROUP_ADD",
            SUA: "ROLE_SYSTEM_GROUP_EDIT",
            XOA: "ROLE_SYSTEM_GROUP_DELETE",
        },
        LICH_SU_TRUY_CAP: "ROLE_LOG_USER_ACCESS",
    },
    // danh muc
    DANH_MUC: {
        TINH_THANH: {
            DANH_SACH: "ROLE_SYSTEM_PROVINCE_VIEW",
            THEM_MOI: "ROLE_SYSTEM_PROVINCE_ADD",
            SUA: "ROLE_SYSTEM_PROVINCE_EDIT",
            XOA: "ROLE_SYSTEM_PROVINCE_DELETE",
        },
        QUAN_HUYEN: {
            DANH_SACH: "ROLE_SYSTEM_DISTRICT_VIEW",
            THEM_MOI: "ROLE_SYSTEM_DISTRICT_ADD",
            SUA: "ROLE_SYSTEM_DISTRICT_EDIT",
            XOA: "ROLE_SYSTEM_DISTRICT_DELETE",
        },
        PHUONG_XA: {
            DANH_SACH: "ROLE_SYSTEM_COMMUNE_VIEW",
            THEM_MOI: "ROLE_SYSTEM_COMMUNE_ADD",
            SUA: "ROLE_SYSTEM_COMMUNE_EDIT",
            XOA: "ROLE_SYSTEM_COMMUNE_DELETE",
        },
    },
    CHUC_NANG: {
        DANH_SACH: "ROLE_SYSTEM_AUTHORITY_VIEW",
        THEM_MOI: "ROLE_SYSTEM_AUTHORITY_ADD",
        SUA: "ROLE_SYSTEM_AUTHORITY_EDIT",
        XOA: "ROLE_SYSTEM_AUTHORITY_DELETE",
    },
    SAP_XEP_DU_LIEU_SO_HOA: {
        DANH_SACH: "ROLE_SYSTEM_DATAKEYMAPSORT_VIEW",
        THEM_MOI: "ROLE_SYSTEM_DATAKEYMAPSORT_ADD",
        SUA: "ROLE_SYSTEM_DATAKEYMAPSORT_EDIT",
        XOA: "ROLE_SYSTEM_DATAKEYMAPSORT_DELETE",
    },
}


interface DataType {
    key: React.Key;
    id: string;
    title: string;
    view: string;
    add: string;
    edit: string;
    delete: string;
    children?: DataType[];
    disable?: string[];
}

export const data: DataType[] = [
        {
            key: '2',
            id: '',
            title: 'Chiến dịch',
            view: '',
            add: '',
            edit: '',
            delete: '',
        },
        {
            key: '63db69a3bcdb90514c3aa30d',
            id: '63db69a3bcdb90514c3aa30d',
            title: "Cấu hình trường thông tin",
            add: '63db69a3bcdb90514c3aa30e',
            edit: '63db69a3bcdb90514c3aa30f',
            delete: '63db69a3bcdb90514c3aa310',
            view: '63db69a3bcdb90514c3aa311',
        },
        {
            title: "Nhập liệu dữ liệu",
            key: "nldl1",
            id: "63db69a3bcdb90514c3aa321",
            add: '63db69a3bcdb90514c3aa322',
            edit: '63db69a3bcdb90514c3aa323',
            delete: '63db69a3bcdb90514c3aa324',
            view: '63db69a3bcdb90514c3aa325',
            disable: ['add', 'delete'],
        },
        {
            title: "Kho dữ liệu nhập liệu",
            key: "nldl2",
            id: "63db69a3bcdb90514c3aa321",
            add: '63db69a3bcdb90514c3aa322',
            edit: '63db69a3bcdb90514c3aa323',
            delete: '63db69a3bcdb90514c3aa324',
            view: '63db69a3bcdb90514c3aa325',
            disable: ['add', 'delete'],
        },
        {
            title: "Kiểm tra dữ liệu",
            key: "ktdl1",
            id: "63db69a3bcdb90514c3aa321",
            add: '63db69a3bcdb90514c3aa322',
            edit: '63db69a3bcdb90514c3aa323',
            delete: '63db69a3bcdb90514c3aa324',
            view: '63db69a3bcdb90514c3aa325',
            disable: ['add', 'delete'],
        },
        {
            title: "Kho dữ liệu kiểm tra",
            key: "ktdl2",
            id: "63db69a3bcdb90514c3aa321",
            add: '63db69a3bcdb90514c3aa322',
            edit: '63db69a3bcdb90514c3aa323',
            delete: '63db69a3bcdb90514c3aa324',
            view: '63db69a3bcdb90514c3aa325',
            disable: ['add', 'delete'],
        },
        {
            title: "Kho dữ liệu",
            key: "khodl",
            id: "khodl",
            add: 'khodladd',
            edit: 'khodledit',
            delete: 'khodldelete',
            view: 'khodlview',
            disable: ['add', 'delete'],
        },
        {
            title: "Báo cáo",
            key: "baocao",
            id: "baocao",
            add: 'baocaoadd',
            edit: 'baocaoedit',
            delete: 'baocaodelete',
            view: 'baocaoview',
            disable: ['add', 'edit', 'delete'],
        },
        {
            title: 'Quản trị hệ thống',
            key: 'quanTriHeThong',
            id: 'quanTriHeThong',
            add: 'addQTHT',
            edit: 'editQTHT',
            delete: 'deleteQTHT',
            view: 'viewQTHT',
            children: [
                 {
                    title: "Quản lý người dùng",
                    key: "63db69a3bcdb90514c3aa2fe",
                    id: "63db69a3bcdb90514c3aa2fe",
                    add: '63db69a3bcdb90514c3aa2ff',
                    edit: '63db69a3bcdb90514c3aa300',
                    delete: '63db69a3bcdb90514c3aa301',
                    view: '63db69a3bcdb90514c3aa302',
                },
                {
                    title: "Phân quyền",
                    key: "63db69a3bcdb90514c3aa303",
                    id: "63db69a3bcdb90514c3aa303",
                    add: '63db69a3bcdb90514c3aa304',
                    edit: '63db69a3bcdb90514c3aa305',
                    delete: '63db69a3bcdb90514c3aa306',
                    view: '63db69a3bcdb90514c3aa307',
                },
            ]
        },
        {
            title: 'Quản lý Danh mục',
            key: 'danhmuc',
            id: 'danhmuc',
            add: 'addDM',
            edit: 'editDM',
            delete: 'deleteDM',
            view: 'viewDM',
            children: [
                {
                    title: 'Danh mục Tỉnh/Thành phố',
                    key: "63db69a3bcdb90514c3aa312",
                    id: "63db69a3bcdb90514c3aa312",
                    add: '63db69a3bcdb90514c3aa313',
                    edit: '63db69a3bcdb90514c3aa314',
                    delete: '63db69a3bcdb90514c3aa315',
                    view: '63db69a3bcdb90514c3aa316',
                },
                {
                    title: "Danh mục Quận/Huyện",
                    key: "63db69a3bcdb90514c3aa317",
                    id: "63db69a3bcdb90514c3aa317",
                    add: '63db69a3bcdb90514c3aa318',
                    edit: '63db69a3bcdb90514c3aa319',
                    delete: '63db69a3bcdb90514c3aa31a',
                    view: '63db69a3bcdb90514c3aa31b',
                },
                {
                    title: "Danh mục Phường/Xã",
                    key: "63db69a3bcdb90514c3aa31c",
                    id: "63db69a3bcdb90514c3aa31c",
                    add: '63db69a3bcdb90514c3aa31d',
                    edit: '63db69a3bcdb90514c3aa31e',
                    delete: '63db69a3bcdb90514c3aa31f',
                    view: '63db69a3bcdb90514c3aa320',
                },

            ]
        },
        

    ];
