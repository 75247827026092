import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campaign, Commune, District, Province, User } from './apiTypes';

export const commonApi = createApi({
    reducerPath: 'commonApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getProvince: builder.query<Province[], any>({
            query: () => '/common/province/all',
            transformResponse: (response: { data: Province[] }, meta, arg) => {
                return response.data;
            },
        }),
        getDistrictAll: builder.query<District[], any>({
            query: () => '/common/district/all',
            transformResponse: (response: { data: District[] }, meta, arg) => {
                return response.data;
            },
        }),
        getCommuneAll: builder.query<Commune[], any>({
            query: () => '/common/commune/all',
            transformResponse: (response: { data: Commune[] }, meta, arg) => {
                return response.data;
            },
        }),
        getDistrict: builder.query<District[], any>({
            query: (provinceId) => `/common/district-by-province/${provinceId}`,
            transformResponse: (response: { data: District[] }, meta, arg) => {
                return response.data;
            },
        }),
        getCommune: builder.query<Commune[], any>({
            query: (districtId) => `/common/commune-by-district/${districtId}`,
            transformResponse: (response: { data: Commune[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhân viên có quyền nhập dữ liệu theo nhóm người dùng
        getUserInput: builder.query<User[], any>({
            query: () => `/users/user-input/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhân viên có quyền đối chiếu theo nhóm người dùng
        getUserCompare: builder.query<User[], any>({
            query: () => `/users/user-compare/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhân viên có quyền kiểm tra theo nhóm người dùng
        getUserCheck: builder.query<User[], any>({
            query: () => `/users/user-check/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhóm người dùng thực hiện nhập dữ liệu
        getGroupUserInput: builder.query<User[], any>({
            query: () => `/group-user/group-user-input/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhóm người dùng thực hiện đối chiếu
        getGroupUserCompare: builder.query<User[], any>({
            query: () => `/group-user/group-user-compare/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách nhóm người dùng thực hiện kiểm tra
        getGroupUserCheck: builder.query<User[], any>({
            query: () => `/group-user/group-user-check/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách quản lý có quyền nhập dữ liệu
        getManagerInput: builder.query<User[], any>({
            query: () => `/users/user-input/managers/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách quản lý có quyền đối chiếu
        getManagerCompare: builder.query<User[], any>({
            query: () => `/users/user-compare/managers/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách quản lý có quyền kiểm tra
        getManagerCheck: builder.query<User[], any>({
            query: () => `/users/user-check/managers/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách tất cả quản lý
        getManagerAll: builder.query<User[], any>({
            query: () => `/users/managers/all`,
            transformResponse: (response: { data: User[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Thêm mới chiến dịch
        createCampaign: builder.mutation<any, Campaign>({
            query: (body) => ({
                url: `/common/data-campaign/add`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            }
        }),
        // Lấy danh sách chiến dịch
        getAllCampaign: builder.query<Campaign[], any>({
            query: (body) => ({
                url: `/common/data-campaign/all`,
                method: 'GET',
            }),
            transformResponse: (response: { data: Campaign[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách chiến dịch theo trang
        getCampaignByPage: builder.query<{ content: Campaign[] }, any>({
            query: (body) => ({
                url: `/common/data-campaign/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: { content: Campaign[] } }, meta, arg) => {
                return response.data;
            },
        }),

        getAllRoles: builder.query<any[], any>({
            query: () => '/common/all-roles',
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        getSyncReport: builder.query<any, any>({
            query: (rpName) => `/reports/report-sync?rpName=${rpName.rpName}`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
    })
});

export const {
    useLazyGetAllCampaignQuery,
    useLazyGetCampaignByPageQuery,
    useLazyGetProvinceQuery,
    useLazyGetDistrictQuery,
    useLazyGetDistrictAllQuery,
    useLazyGetCommuneQuery,
    useLazyGetCommuneAllQuery,
    useLazyGetUserInputQuery,
    useLazyGetUserCompareQuery,
    useLazyGetUserCheckQuery,
    useLazyGetGroupUserInputQuery,
    useLazyGetGroupUserCompareQuery,
    useLazyGetGroupUserCheckQuery,
    useLazyGetManagerInputQuery,
    useLazyGetManagerCompareQuery,
    useLazyGetManagerCheckQuery,
    useLazyGetManagerAllQuery,
    useCreateCampaignMutation,
    useLazyGetAllRolesQuery,
    useLazyGetSyncReportQuery,

    useGetGroupUserInputQuery,
    useGetDistrictQuery
} = commonApi;
export default commonApi;
