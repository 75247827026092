import { Button, Form, Input, Modal, notification, Select } from "antd";
import React, { useEffect } from "react";
import { helpers } from "../../../assets/js/helper";
import {
  useCreateCampaignMutation,
  useLazyGetGroupUserCheckQuery,
} from "../../../redux/api/commonApi";

type Props = {
  visible: boolean;
  onClose: () => void;
  filter: any;
};

const CreateCampaign = ({ visible, onClose, filter }: Props) => {
  const [form] = Form.useForm();
  const [triggerCreateCampaign, { isLoading }] = useCreateCampaignMutation();
  const [triggerGroupUserCheck, responseGroupUserCheck] =
    useLazyGetGroupUserCheckQuery();

  useEffect(() => {
    triggerGroupUserCheck({});
    form.setFieldValue("typeDivide", 2);
  }, [form, triggerGroupUserCheck]);

  const typeDivideValue = Form.useWatch("typeDivide", form);

  const onSave = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    // console.log("values", values);
    const { page, size, ...rest } = filter;
    const { percent, ...other } = values;
    let payload = {
      ...other,
      percent: percent ? +percent : undefined,
    };
    triggerCreateCampaign({ ...payload, searchForm: JSON.stringify(rest) })
      .unwrap()
      .then((res) => {
        notification.success({ message: "Tạo chiến dịch thành công!" });
        onClose();
      })
      .catch((error) => {
        notification.error({
          message: error?.data?.message || "Có lỗi xảy ra",
        });
      });
  };

  const onCloseModal = () => {
    form.resetFields();
    form.setFieldValue("typeDivide", 2);
    onClose();
  };

  return (
    <Modal
      open={visible}
      title="Thêm mới chiến dịch"
      onCancel={onCloseModal}
      width={800}
      maskClosable={false}
      footer={[
        <React.Fragment key="footer">
          <Button htmlType="button" onClick={onCloseModal}>
            Đóng
          </Button>
          <Button
            loading={isLoading}
            htmlType="button"
            type="primary"
            onClick={onSave}
          >
            Thêm chiến dịch
          </Button>
        </React.Fragment>,
      ]}
    >
      <Form
        name="searchBox"
        autoComplete="off"
        form={form}
        labelWrap
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        labelAlign="left"
        requiredMark={false}
        colon={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="Tên chiến dịch"
          name="campaignName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Trường dữ liệu bắt buộc.",
            },
          ]}
        >
          <Input
            placeholder="Nhập tên chiến dịch"
            maxLength={100}
            style={{ minWidth: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Nhóm người dùng"
          name="groupUserIds"
          rules={[
            {
              required: true,
              message: "Trường dữ liệu bắt buộc.",
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Chọn nhóm người dùng"
            options={(responseGroupUserCheck?.data || [])
              .map((item) => ({
                label: item.name,
                value: item.id,
              }))
              .sort((item1, item2) =>
                helpers.compareListByName(item1, item2, "label")
              )}
            showSearch
            optionFilterProp="label"
            mode="multiple"
            maxTagCount="responsive"
          ></Select>
        </Form.Item>

        <Form.Item
          label="Loại phân công"
          name="typeDivide"
          rules={[{ required: true, message: "Trường dữ liệu bắt buộc." }]}
        >
          <Select placeholder="Chọn loại phân công" disabled={true}>
            <Select.Option value={1}>Phân công tuyệt đối</Select.Option>
            <Select.Option value={2}>Phân công tương đối</Select.Option>
          </Select>
        </Form.Item>

        {typeDivideValue === 1 ? (
          <Form.Item
            label="Số lượng bản ghi"
            name="number"
            rules={[
              { required: true, message: "Trường dữ liệu bắt buộc." },
              () => ({
                validator(_: any, value: string) {
                  if (value && parseInt(value) === 0) {
                    return Promise.reject(
                      new Error("Số lượng bản ghi lớn hơn 0")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập số lượng bản ghi" />
          </Form.Item>
        ) : (
          <Form.Item
            label="Phần trăm dữ liệu"
            name="percent"
            rules={[
              { required: true, message: "Trường dữ liệu bắt buộc." },
              () => ({
                validator(_: any, value: number) {
                  if (value && value <= 0) {
                    return Promise.reject(
                      new Error("Phần trăm dữ liệu lớn hơn 0")
                    );
                  }

                  if (value && value > 100) {
                    return Promise.reject(
                      new Error("Phần trăm dữ liệu nhỏ hơn 100%")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Nhập phần trăm dữ liệu" type="number" />
          </Form.Item>
        )}

        <Form.Item label="Mô tả" name="description">
          <Input.TextArea placeholder="Nhập mô tả" rows={3} maxLength={500} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCampaign;
