import { Col, Layout, Menu, Row } from "antd";

import HomeIcon from "../../assets/img/HomeIcon2.png";
import FileScanIcon from "../../assets/img/FileScanIcon2.png";
import CreateIcon from "../../assets/img/CreateIcon2.png";
import CreateLackIcon from "../../assets/img/create_lack.png";
import CheckDataIcon from "../../assets/img/CheckDataIcon2.png";
import WarehouseIcon from "../../assets/img/WarehouseIcon2.png";
import CompareIcon from "../../assets/img/CompareIcon2.png";
import CompareDataIcon from "../../assets/img/CompareData.svg";
import WarehouseCheckIcon from "../../assets/img/WarehouseCheckIcon2.png";
import WarehousePXIcon from "../../assets/img/DL_PX.png";
import CampaignEnterIcon from "../../assets/img/CampaignEnterIcon2.png";
import CampaignCheckIcon from "../../assets/img/campaign-enter.png";
import CampaignsIcon from "../../assets/img/CampaignsIcon2.png";
import ReportIcon from "../../assets/img/ReportIcon2.png";
import ConfigFieldIcon from "../../assets/img/ConfigFieldIcon2.png";
import CategoryIcon from "../../assets/img/CategoryIcon2.png";
import SettingIcon from "../../assets/img/SettingIcon2.png";
import DataSyncIcon from "../../assets/img/data-sync.png";
import Icon, {
  DoubleRightOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./assets/css/index.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/Hooks";
import { useLocation } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import { PERMISSION_KEYS } from "../../modules/permissions/ts/Contants";
import {
  checkPermission,
  checkHasOneOfPermissions,
} from "../../modules/common/assets/CommonFunctions";
import CreateData from "../../modules/field_config/components/CreateData";
import CloudDatabase from "../../assets/img/Cloud Database.png";

const { Sider } = Layout;

type Props = {
  headerHeight: number;
};

const Sidebar = ({ headerHeight }: Props) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const userState = useAppSelector((state) => state.user.user);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const openKeys = () => {
    let openKey = [""];
    // if (location?.pathname?.includes("report/perform")) openKey = [...openKey, "report"];
    // if (location?.pathname?.includes("report/field")) openKey = [...openKey, "report"];
    // if (location?.pathname?.includes("report/empl")) openKey = [...openKey, "report"];

    // if (location?.pathname?.includes("report-data-info-init/perform")) openKey = [...openKey, "report-data-info-init"];
    // if (location?.pathname?.includes("report-data-info-init/field")) openKey = [...openKey, "report-data-info-init"];
    // if (location?.pathname?.includes("report-data-info-init/empl")) openKey = [...openKey, "report-data-info-init"];
    // if (location?.pathname?.includes("report-data-info-init/create")) openKey = [...openKey, "report-data-info-init"];

    return openKey;
  };

  const selectedKeys = () => {
    let activeKey = "home";
    if (location?.pathname?.includes("data/list")) activeKey = "data";
    if (location?.pathname?.includes("data/import")) activeKey = "data";
    if (location?.pathname?.includes("data/detail")) activeKey = "data";
    if (location?.pathname?.includes("data/campaign"))
      activeKey = "data/campaign";
    if (location?.pathname?.includes("data/data-entry-campaign"))
      activeKey = "data/data-entry-campaign";
    if (location?.pathname?.includes("data/check")) activeKey = "data/check";
    // if (location?.pathname?.includes("report/perform")) activeKey = "perform";
    // if (location?.pathname?.includes("report/field")) activeKey = "field";
    // if (location?.pathname?.includes("report/empl")) activeKey = "empl";

    if (location?.pathname?.includes("report-data-info-init/perform"))
      activeKey = "data-info-init-perform";
    if (location?.pathname?.includes("report-data-info-init/field"))
      activeKey = "data-info-init-field";
    if (location?.pathname?.includes("report-data-info-init/empl"))
      activeKey = "data-info-init-empl";
    if (location?.pathname?.includes("report-data-info-init/import"))
      activeKey = "data-info-init-import";
    if (location?.pathname?.includes("report-data-info-init/checked"))
      activeKey = "data-info-init-checked";

    if (location?.pathname?.includes("info_init/create"))
      activeKey = "info_init/create";
    if (location?.pathname?.includes("info_init/createv2"))
      activeKey = "info_init/createv2";
    if (location?.pathname?.includes("info_init/createv3"))
      activeKey = "info_init/createv3";
    if (location?.pathname?.includes("info_init/createLack"))
      activeKey = "info_init/createLack";
    if (location?.pathname?.includes("data/info_init"))
      activeKey = "data/info_init";

    if (location?.pathname?.includes("data/recheck"))
      activeKey = "data/recheck";
    if (location?.pathname?.includes("data/recheck/detail"))
      activeKey = "data/recheck-list";

    if (location?.pathname?.includes("data/recheck-list"))
      activeKey = "data/recheck-list";
    if (location?.pathname?.includes("data/recheck-campaign"))
      activeKey = "data/recheck-campaign";

    if (location?.pathname?.includes("data/field")) activeKey = "data/field";
    if (location?.pathname?.includes("data/file")) activeKey = "data/file";
    if (location?.pathname?.includes("data/check-data"))
      activeKey = "data/check-data";
    if (location?.pathname?.includes("data/info_init_history"))
      activeKey = "data/info_init_history";
    if (location?.pathname?.includes("data/user-list"))
      activeKey = "data/user-list";
    if (location?.pathname?.includes("category/province"))
      activeKey = "province";
    if (location?.pathname?.includes("category/district"))
      activeKey = "district";
    if (location?.pathname?.includes("category/commune")) activeKey = "commune";
    if (location?.pathname?.includes("data/box-data"))
      activeKey = "data/box-data";
    if (location?.pathname?.includes("data/sync-data"))
      activeKey = "data/sync-data";

    if (location?.pathname?.includes("setting/user"))
      activeKey = "setting/user";
    if (location?.pathname?.includes("setting/role"))
      activeKey = "setting/role";
    if (location?.pathname?.includes("setting/group-user"))
      activeKey = "setting/group-user";
    if (location?.pathname?.includes("setting/access-history"))
      activeKey = "setting/access-history";

    if (location?.pathname?.includes("data/du-lieu-khai-sinh"))
      activeKey = "data/du-lieu-khai-sinh";
    if (location?.pathname?.includes("data/du-lieu-khai-tu"))
      activeKey = "data/du-lieu-khai-tu";
    if (location?.pathname?.includes("data/du-lieu-ket-hon"))
      activeKey = "data/du-lieu-ket-hon";
    if (location?.pathname?.includes("data/du-lieu-hon-nhan"))
      activeKey = "data/du-lieu-hon-nhan";
    if (location?.pathname?.includes("data/du-lieu-CMC"))
      activeKey = "data/du-lieu-CMC";
    if (location?.pathname?.includes("data/du-lieu-giam-ho"))
      activeKey = "data/du-lieu-giam-ho";
    if (location?.pathname?.includes("data/du-lieu-CDGH"))
      activeKey = "data/du-lieu-CDGH";
    if (location?.pathname?.includes("data/du-lieu-ly-hon"))
      activeKey = "data/du-lieu-ly-hon";
    if (location?.pathname?.includes("data/du-lieu-cai-chinh"))
      activeKey = "data/du-lieu-cai-chinh";
    if (location?.pathname?.includes("data/du-lieu-con-nuoi"))
      activeKey = "data/du-lieu-con-nuoi";

    if (location?.pathname?.includes("report/tien-do"))
      activeKey = "report/tien-do";
    if (location?.pathname?.includes("report/nang-suat-nhap-lieu"))
      activeKey = "report/nang-suat-nhap-lieu";
    if (location?.pathname?.includes("report/nang-suat-kiem-tra"))
      activeKey = "report/nang-suat-kiem-tra";
    if (location?.pathname?.includes("report/upload"))
      activeKey = "report/upload";
    // if (location?.pathname?.includes("report/upload-Excel")) activeKey = "report/upload-Excel";
    // if (location?.pathname?.includes("report/upload-PDF")) activeKey = "report/upload-PDF";
    if (location?.pathname?.includes("report/cac-truong-hop-loi"))
      activeKey = "report/cac-truong-hop-loi";
    if (location?.pathname?.includes("report/nhan-su-bao-loi"))
      activeKey = "report/nhan-su-bao-loi";

    return [activeKey];
  };

  // const checkPermission = (path: string) => {
  //     if (userState.username === "admin" || (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
  //     return false;
  // }

  const checkRoleCheck = () => {
    if (
      userState.username === "admin" ||
      (userState.username || "").toLowerCase().includes("check")
    )
      return true;
    return false;
  };

  return (
    <Sider
      width={300}
      className="sidebar"
      collapsible
      collapsed={collapsed}
      trigger={null}
    >
      <Row
        align="middle"
        justify={collapsed ? "center" : "space-between"}
        className="sidebar-header text-center"
      >
        <Col style={{ display: collapsed ? "none" : "block" }}>MENU</Col>
        <Col>
          {!collapsed ? (
            <MenuFoldOutlined onClick={onCollapse} style={{ fontSize: 18 }} />
          ) : (
            <MenuUnfoldOutlined onClick={onCollapse} style={{ fontSize: 18 }} />
          )}
        </Col>
      </Row>
      <Menu
        selectedKeys={selectedKeys()}
        defaultOpenKeys={openKeys()}
        mode="inline"
        style={{
          height: `calc(100vh - 42px)`,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/*height: `calc(100vh - 88px)`*/}
        <Menu.Item
          key="home"
          icon={
            <img
              src={HomeIcon}
              style={{ width: 25, height: 25, color: "black" }}
            />
          }
        >
          <Link to="/home">Trang chủ</Link>
        </Menu.Item>

        {checkPermission(PERMISSION_KEYS.DANH_SACH_FILE_PDF.DANH_SACH) ? (
          <Menu.Item
            key="data/file"
            icon={
              <img
                src={FileScanIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/file">Danh sách file scan</Link>
          </Menu.Item>
        ) : (
          <></>
        )}

        {checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.THEM_MOI) ? (
          <Menu.Item
            key="info_init/create"
            icon={
              <img
                src={CreateIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="info_init/create">Nhập mới dữ liệu</Link>
          </Menu.Item>
        ) : (
          <></>
        )}

        {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.DANH_SACH) ? (
          <Menu.Item
            key="data/info_init"
            icon={
              <img
                src={WarehouseIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/info_init">Kho dữ liệu nhập liệu</Link>
          </Menu.Item>
        ) : (
          <></>
        )}

        {checkPermission(PERMISSION_KEYS.CHIEN_DICH_NHAP_LIEU.DANH_SACH) ? (
          <Menu.Item
            key="data/data-entry-campaign"
            icon={
              <img
                src={CampaignEnterIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/data-entry-campaign">Chiến dịch nhập liệu</Link>
          </Menu.Item>
        ) : (
          <></>
        )}

        {checkPermission(PERMISSION_KEYS.DOI_CHIEU_DU_LIEU.VIEW) && (
          <Menu.Item
            key="data/recheck"
            icon={
              <img
                src={CompareIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/recheck">Đối chiếu dữ liệu</Link>
          </Menu.Item>
        )}

        {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_DOI_CHIEU.VIEW) && (
          <Menu.Item
            key="data/recheck-list"
            icon={
              <img
                src={CompareDataIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/recheck-list">Kho dữ liệu đối chiếu</Link>
          </Menu.Item>
        )}

        {checkPermission(PERMISSION_KEYS.CHIEN_DICH_DOI_CHIEU.VIEW) && (
          <Menu.Item
            key="data/recheck-campaign"
            icon={
              <img
                src={CampaignEnterIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/recheck-campaign">Chiến dịch đối chiếu</Link>
          </Menu.Item>
        )}

        {/* } */}

        {/* {checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.ADD_BO_SUNG) ? <Menu.Item key='info_init/createLack' icon={<img src={CreateLackIcon} style={{ width: 25, height:25, color: 'black' }}/>}>
                    <Link to="info_init/createLack">
                        Nhập liệu bổ sung
                    </Link>
                </Menu.Item> : <></>} */}
        {checkPermission(PERMISSION_KEYS.KIEM_TRA_DU_LIEU.DANH_SACH) ? (
          <Menu.Item
            key="data/check-data"
            icon={
              <img
                src={CheckDataIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/check-data">Kiểm tra dữ liệu</Link>
          </Menu.Item>
        ) : (
          <></>
        )}

        {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_KIEM_TRA.DANH_SACH) ? (
          <Menu.Item
            key="data/info_init_history"
            icon={
              <img
                src={WarehouseCheckIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/info_init_history">Kho dữ liệu kiểm tra</Link>
          </Menu.Item>
        ) : (
          <></>
        )}
        {/* {checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.ADD) ?  <Menu.Item key='data/sync-data' icon={<img src={DataSyncIcon} style={{ width: 25, height:25, color: 'black' }}/>}>
                        <Link to="data/sync-data">
                            Đồng bộ dữ liệu
                        </Link>
                    </Menu.Item>
                    : <></>} */}
        {/* {checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.ADD) ?  <Menu.Item key='data/list-sync-data' icon={<img src={CampaignsIcon} style={{ width: 25, height:25, color: 'black' }}/>}>
                        <Link to="data/list-sync-data">
                            Danh sách đồng bộ
                        </Link>
                    </Menu.Item>
                    : <></>} */}
        {/* Kho dữ liệu */}
        {/* {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU.DANH_SACH) ? <Menu.Item key='data/box-data' icon={<img src={WarehousePXIcon} style={{ width: 25, height:25, color: 'black' }}/>}>
                    <Link to='/data/box-data' title='Danh sách dữ liệu'>
                        Kho dữ liệu PX
                    </Link>
                </Menu.Item> : <></>} */}

        {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.DANH_SACH) ? (
          <SubMenu
            key="kho-du-lieu-ho-tich"
            title={
              collapsed ? (
                ""
              ) : (
                <span className={"mgl10"}>Kho dữ liệu hộ tịch</span>
              )
            }
            icon={
              <img
                src={CloudDatabase}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.KS) ? (
              <Menu.Item key="data-du-lieu-khai-sinh">
                <Link
                  to="/data/du-lieu-khai-sinh"
                  title="Quản lý dữ liệu khai sinh"
                >
                  <DoubleRightOutlined /> Quản lý dữ liệu khai sinh
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.KT) ? (
              <Menu.Item key="data-du-lieu-khai-tu">
                <Link
                  to="/data/du-lieu-khai-tu"
                  title="Quản lý dữ liệu khai tử "
                >
                  <DoubleRightOutlined /> Quản lý dữ liệu khai tử
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.KH) ? (
              <Menu.Item key="data-du-lieu-ket-hon">
                <Link
                  to="/data/du-lieu-ket-hon"
                  title="Quản lý dữ liệu kết hôn "
                >
                  <DoubleRightOutlined /> Quản lý dữ liệu kết hôn
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.HN) ? (
              <Menu.Item key="data-du-lieu-hon-nhan">
                <Link
                  to="/data/du-lieu-hon-nhan"
                  title="Quản lý dữ liệu hôn nhân "
                >
                  <DoubleRightOutlined /> Quản lý dữ liệu hôn nhân
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.CMC) ? (
              <Menu.Item key="data-du-lieu-CMC">
                <Link to="/data/du-lieu-CMC" title="Quản lý dữ liệu CMC">
                  <DoubleRightOutlined /> Quản lý dữ liệu CMC
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.GH) ? (
              <Menu.Item key="data-du-lieu-giam-ho">
                <Link
                  to="/data/du-lieu-giam-ho"
                  title="Quản lý dữ liệu giám hộ"
                >
                  <DoubleRightOutlined /> Quản lý dữ liệu giám hộ
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.GH) ? (
              <Menu.Item key="data-du-lieu-CDGH">
                <Link to="/data/du-lieu-CDGH" title="Quản lý dữ liệu CDGH">
                  <DoubleRightOutlined /> Quản lý dữ liệu CDGH
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.LH) ? (
              <Menu.Item key="data-du-lieu-ly-hon">
                <Link to="/data/du-lieu-ly-hon" title="Quản lý dữ liệu ly hôn">
                  <DoubleRightOutlined /> Quản lý dữ liệu ly hôn
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.CC) ? (
              <Menu.Item key="data-du-lieu-cai-chinh">
                <Link
                  to="/data/du-lieu-cai-chinh"
                  title="Quản lý dữ liệu cải chính"
                >
                  <DoubleRightOutlined /> Quản lý dữ liệu cải chính
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_HO_TICH.CN) ? (
              <Menu.Item key="data-du-lieu-con-nuoi">
                <Link
                  to="/data/du-lieu-con-nuoi"
                  title="Quản lý dữ liệu con nuôi "
                >
                  <DoubleRightOutlined /> Quản lý dữ liệu con nuôi
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
          </SubMenu>
        ) : (
          <></>
        )}

        {/* {checkPermission("data/campaign") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? */}

        {/* {checkPermission("data/data-entry-campaign") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? */}

        {checkPermission(PERMISSION_KEYS.CHIEN_DICH_KIEM_TRA.DANH_SACH) ? (
          <Menu.Item
            key="data/campaign"
            icon={
              <img
                src={CampaignsIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/campaign">Chiến dịch kiểm tra</Link>
          </Menu.Item>
        ) : (
          <></>
        )}
        {checkHasOneOfPermissions([
          PERMISSION_KEYS.BAO_CAO.DANH_SACH,
          PERMISSION_KEYS.BAO_CAO.NANG_SUAT_DOI_CHIEU,
          PERMISSION_KEYS.BAO_CAO.NANG_XUAT_KIEM_TRA,
          PERMISSION_KEYS.BAO_CAO.NANG_XUAT_NHAP_LIEU,
        ], userState) ? (
          <SubMenu
            key="report-data-info-init"
            title={collapsed ? "" : <span className={"mgl10"}>Báo cáo</span>}
            icon={
              <img
                src={ReportIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            {/*{checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_NHAP_LIEU) ? <Menu.Item key='data-info-init-import'>
                            <Link to='/report-data-info-init/import' title='Báo cáo nhập liệu'>
                                <DoubleRightOutlined /> Báo cáo nhập liệu
                            </Link>
                        </Menu.Item> : <></>}
                        {checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_HIEU_NANG_CHINH_SUA) ? <Menu.Item key='data-info-init-perform'>
                            <Link to='/report-data-info-init/perform' title='Báo cáo hiệu năng chỉnh sửa dữ liệu'>
                                <DoubleRightOutlined /> Báo cáo hiệu năng chỉnh sửa dữ liệu
                            </Link>
                        </Menu.Item> : <></>}
                        {checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA) ? <Menu.Item key='data-info-init-checked'>
                            <Link to='/report-data-info-init/checked' title='Báo cáo kiểm tra'>
                                <DoubleRightOutlined /> Báo cáo kiểm tra
                            </Link>
                        </Menu.Item> : <></>}
                        {checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_THEO_TRUONG_DU_LIEU) ? <Menu.Item key='data-info-init-field'>
                            <Link to='/report-data-info-init/field' title='Báo cáo theo trường dữ liệu'>
                                <DoubleRightOutlined /> Báo cáo theo trường dữ liệu
                            </Link>
                        </Menu.Item> : <></>}
                        {checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_THOI_GIAN_LAM_VIEC) ? <Menu.Item key='data-info-init-empl'>
                            <Link to='/report-data-info-init/empl' title='Báo cáo thời gian làm việc'>
                                <DoubleRightOutlined /> Báo cáo thời gian làm việc
                            </Link>
                        </Menu.Item> : <></>}*/}
            {/* {checkPermission(PERMISSION_KEYS.BAO_CAO.TIEN_DO_KIEM_TRA) ? <Menu.Item key='report/tien-do'>
                            <Link to='/report/tien-do' title='Báo cáo tiến độ kiểm tra'>
                                <DoubleRightOutlined /> Báo cáo tiến độ kiểm tra
                            </Link>
                        </Menu.Item> : <></>} */}
            {checkPermission(PERMISSION_KEYS.BAO_CAO.NANG_XUAT_NHAP_LIEU) ? (
              <Menu.Item key="report/nang-suat-nhap-lieu">
                <Link
                  to="/report/nang-suat-nhap-lieu"
                  title="Báo cáo năng suất nhập liệu"
                >
                  <DoubleRightOutlined /> Báo cáo năng suất nhập liệu
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.BAO_CAO.NANG_SUAT_DOI_CHIEU) ? (
              <Menu.Item key="report/nang-suat-doi-chieu">
                <Link
                  to="/report/nang-suat-doi-chieu"
                  title="Báo cáo năng suất đối chiếu"
                >
                  <DoubleRightOutlined /> Báo cáo năng suất đối chiếu
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.BAO_CAO.NANG_XUAT_KIEM_TRA) ? (
              <Menu.Item key="report/nang-suat-kiem-tra">
                <Link
                  to="/report/nang-suat-kiem-tra"
                  title="Báo cáo năng suất kiểm tra"
                >
                  <DoubleRightOutlined /> Báo cáo năng suất kiểm tra
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {/* {checkPermission(PERMISSION_KEYS.BAO_CAO.UPLOAD_DATA) ? <Menu.Item key='report/upload'>
                            <Link to='/report/upload' title='Báo cáo upload dữ liệu'>
                                <DoubleRightOutlined /> Báo cáo upload dữ liệu
                            </Link>
                        </Menu.Item> : <></>} */}
            {/* {checkPermission(PERMISSION_KEYS.BAO_CAO.UPLOAD_DATA) ? <Menu.Item key='report/upload-Excel'>
                            <Link to='/report/upload-Excel' title='Báo cáo upload dữ liệu Excel'>
                                <DoubleRightOutlined /> Báo cáo upload dữ liệu Excel
                            </Link>
                        </Menu.Item> : <></>}
                        {checkPermission(PERMISSION_KEYS.BAO_CAO.UPLOAD_DATA) ? <Menu.Item key='report/upload-PDF'>
                            <Link to='/report/upload-PDF' title='Báo cáo upload dữ liệu PDF'>
                                <DoubleRightOutlined /> Báo cáo upload dữ liệu PDF
                            </Link>
                        </Menu.Item> : <></>} */}
            {/*{checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA) ? <Menu.Item key='report-tien-do-nhap-lieu'>
                            <Link to='/report/tien-do-nhap-lieu' title='Báo cáo tiến độ nhập liệu'>
                                <DoubleRightOutlined /> Báo cáo tiến độ nhập liệu
                            </Link>
                        </Menu.Item> : <></>}*/}
            {/* {checkPermission(PERMISSION_KEYS.BAO_CAO.CAC_THHT_ERROR) ? <Menu.Item key='report/cac-truong-hop-loi'>
                            <Link to='/report/cac-truong-hop-loi' title='Báo cáo các trường hợp lỗi'>
                                <DoubleRightOutlined /> Báo cáo các trường hợp lỗi
                            </Link>
                        </Menu.Item> : <></>} */}
            {/* {checkPermission(PERMISSION_KEYS.BAO_CAO.NS_BAO_LOI) ? <Menu.Item key='report/nhan-su-bao-loi'>
                            <Link to='/report/nhan-su-bao-loi' title='Báo cáo nhân sự báo lỗi dữ liệu'>
                                <DoubleRightOutlined /> Báo cáo nhân sự báo lỗi dữ liệu
                            </Link>
                        </Menu.Item> : <></>} */}
            {/*{checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA) ? <Menu.Item key='report-thoi-gian-thao-tac-nhan-su'>
                            <Link to='/report/thoi-gian-thao-tac-nhan-su' title='Báo cáo theo dõi thời gian thao tác của nhân sự'>
                                <DoubleRightOutlined /> Báo cáo theo dõi thời gian thao tác của nhân sự
                            </Link>
                        </Menu.Item> : <></>}*/}
          </SubMenu>
        ) : (
          <></>
        )}
        {/* {checkPermission("data/field") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ?  */}
        {checkPermission(PERMISSION_KEYS.THAM_SO_HE_THONG.DANH_SACH) ? (
          <Menu.Item
            key="data/field"
            icon={
              <img
                src={ConfigFieldIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            <Link to="data/field">Cấu hình trường thông tin</Link>
          </Menu.Item>
        ) : (
          <></>
        )}

        {/* {checkPermission("data/file")  */}

        {/* {!checkRoleCheck() && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ?  */}

        {/* <Menu.Item key='info_init/createv2' icon={<FolderIcon />}>
                    <Link to="info_init/createv2">
                        Nhập liệu có gợi ý
                    </Link>
                </Menu.Item> */}

        {/* <Menu.Item key='info_init/createv2' icon={<img src={ConfigFieldIcon} style={{ width: 25, height:25, color: 'black' }}/>}
                >
                    <Link to="info_init/createv2">
                        Nhập liệu dữ liệu nhiều
                    </Link>
                </Menu.Item> */}

        {/* <Menu.Item key='info_init/createv3' icon={<FolderIcon />}>
                    <Link to="info_init/createv3">
                        Nhập liệu dữ resize
                    </Link>
                </Menu.Item> */}
        {/* {!checkRoleCheck() && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ?  */}

        {/* {checkPermission("data/list") && userState.username === "admin" ? <Menu.Item key='data' icon={<FolderIcon />}>
                    <Link to="data/list">
                        Kho dữ liệu
                    </Link>
                </Menu.Item> : <></>} */}
        {/* <Menu.Item key='data/check' icon={<FileMenu />}>
                    <Link to="data/check">
                        Kiểm tra dữ liệu
                    </Link>
                </Menu.Item> */}
        {/* Kiểm tra dữ liệu ver2 */}
        {/* {checkRoleCheck() && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ?  */}

        {/* {checkRoleCheck() && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? <Menu.Item key='data/info_init_history' icon={<FolderIcon />}>
                    <Link to="data/info_init_history">
                        Kho dữ liệu kiểm tra
                    </Link>
                </Menu.Item> : <></>}  */}
        {/* {(userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? <Menu.Item key='data/info_init_history' icon={<FolderIcon />}> */}

        {/* {checkPermission("report") && userState.username === "admin" ? <SubMenu key='report' title='Báo cáo (Dữ liệu excel)' icon={<ClipboardIcon />}>
                    <Menu.Item key='perform'>
                        <Link to='/report/perform' title='Báo cáo hiệu năng chỉnh sửa dữ liệu'>
                            <DoubleRightOutlined /> Báo cáo hiệu năng chỉnh sửa dữ liệu
                        </Link>
                    </Menu.Item>
                    <Menu.Item key='field'>
                        <Link to='/report/field' title='Báo cáo theo trường dữ liệu'>
                            <DoubleRightOutlined /> Báo cáo theo trường dữ liệu
                        </Link>
                    </Menu.Item>
                    <Menu.Item key='empl'>
                        <Link to='/report/empl' title='Báo cáo thời gian làm việc'>
                            <DoubleRightOutlined /> Báo cáo thời gian làm việc
                        </Link>
                    </Menu.Item>
                </SubMenu> : <></>} */}

        {/* {checkPermission("report-data-info-init") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? */}

        {checkPermission(PERMISSION_KEYS.DANH_MUC.TINH_THANH.DANH_SACH) ||
        checkPermission(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.DANH_SACH) ||
        checkPermission(PERMISSION_KEYS.DANH_MUC.PHUONG_XA.DANH_SACH) ? (
          <SubMenu
            key="category"
            title={
              collapsed ? "" : <span className={"mgl10"}>Quản lý danh mục</span>
            }
            icon={
              <img
                src={CategoryIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            {checkPermission(PERMISSION_KEYS.DANH_MUC.TINH_THANH.DANH_SACH) ? (
              <Menu.Item key="province">
                <Link to="/category/province" title="Danh mục Tỉnh thành">
                  <DoubleRightOutlined /> Danh mục Tỉnh thành
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.DANH_SACH) ? (
              <Menu.Item key="district">
                <Link to="/category/district" title="Danh mục Quận huyện">
                  <DoubleRightOutlined /> Danh mục Quận huyện
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(PERMISSION_KEYS.DANH_MUC.PHUONG_XA.DANH_SACH) ? (
              <Menu.Item key="commune">
                <Link to="/category/commune" title="Danh mục Phường xã">
                  <DoubleRightOutlined /> Danh mục Phường xã
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
          </SubMenu>
        ) : (
          <></>
        )}

        {/* Quản trị hệ thống */}

        {/* {checkPermission("setting") && (userState.username === "admin" || userState.username === "hiepdeptrai" || userState.username === "huynv" || userState.username === "thanhTest") ? */}
        {checkPermission(
          PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH
        ) ||
        checkPermission(
          PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.DANH_SACH
        ) ? (
          <SubMenu
            key="setting"
            title={
              collapsed ? (
                ""
              ) : (
                <span className={"mgl10"}>Quản trị hệ thống</span>
              )
            }
            icon={
              <img
                src={SettingIcon}
                style={{ width: 25, height: 25, color: "black" }}
              />
            }
          >
            {checkPermission(
              PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH
            ) ? (
              <Menu.Item key="setting/user">
                <Link to="/setting/user" title="Quản lý người dùng">
                  <DoubleRightOutlined /> Quản lý người dùng
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(
              PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.DANH_SACH
            ) ? (
              <Menu.Item key="setting/role">
                <Link to="/setting/role" title="Nhóm quyền">
                  <DoubleRightOutlined /> Nhóm quyền
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {checkPermission(
              PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_NGUOI_DUNG.DANH_SACH
            ) ? (
              <Menu.Item key="setting/group-user">
                <Link to="/setting/group-user" title="Nhóm người dùng">
                  <DoubleRightOutlined /> Nhóm người dùng
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}
            {/* {checkPermission(
              PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_NGUOI_DUNG.DANH_SACH
            ) ? (
              <Menu.Item key="setting/access-history">
                <Link to="/setting/access-history" title="Lịch sử truy cập">
                  <DoubleRightOutlined /> Lịch sử truy cập
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )} */}
          </SubMenu>
        ) : (
          <></>
        )}

        {/* {checkPermission("category") && (userState.username === "admin" || userState.username === "huynv" || userState.username === "thanhTest") ? */}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
