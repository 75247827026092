import { Breadcrumb, Button, Col, Form, Input, Row, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import GroupUserList from "./components/GroupUserList";
import { ReactComponent as ZoomOut } from "../../assets/logos/zoom-out-line.svg";
import { ReactComponent as ZoomIn } from "../../assets/logos/zoom-in-line.svg";
import { ReactComponent as Home } from "../../assets/logos/home.svg";
import { useLazyGetManagerAllQuery } from "../../redux/api/commonApi";
import { CommonTableParams } from "../../redux/api/apiTypes";

const GroupUser = () => {
  const [form] = Form.useForm();
  const [searchParam, setSearchParam] = useState<CommonTableParams<any>>({
    searchParam: {},
    page: 0,
    size: 10,
  });
  const [triggerManager, responseManager] = useLazyGetManagerAllQuery();

  useEffect(() => {
    triggerManager({});
  }, [triggerManager]);

  const onFinish = (values: any) => {
    setSearchParam((prev) => ({ searchParam: values, page: 0, size: prev.size }));
  };
  const [isSearch, setIsSearch] = useState(false);
  return (
    <>
      {/* <div className='title-label' style={{ marginBottom: 20 }}>Danh sách Nhóm người dùng</div> */}

      <div className="content-box" style={{ marginBottom: 30 }}>
        <Form
          name="searchBox"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
          labelWrap
          labelAlign="left"
          colon={false}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setIsSearch(!isSearch)}
          >
            {isSearch ? (
              <ZoomOut style={{ width: 20, paddingTop: 8 }} />
            ) : (
              <ZoomIn style={{ width: 20, paddingTop: 8 }} />
            )}
            <label className="search-title" style={{ cursor: "pointer" }}>
              TÌM KIẾM
            </label>
          </div>
          {isSearch ? (
            <>
              <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                <Col span={8} xs={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Tên nhóm người dùng"
                    name="name"
                  >
                    <Input
                      allowClear
                      placeholder="Nhập tên nhóm người dùng"
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Quản lý"
                    name="managers"
                  >
                    <Select
                      allowClear
                      placeholder="Chọn quản lý"
                      options={(responseManager?.data || []).map((item) => ({
                        label: `${item.fullName} (${item.username})`,
                        value: item.username,
                      }))}
                      showSearch
                      mode="multiple"
                      maxTagCount="responsive"
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Mô tả"
                    name="description"
                  >
                    <Input
                      allowClear
                      placeholder="Nhập mô tả"
                      style={{ minWidth: "100%" }}
                      autoComplete="on"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    label="Trạng thái"
                    name="status"
                  >
                    <Select allowClear placeholder="Chọn trạng thái" showArrow>
                      <Select.Option value={0}>Hoạt động</Select.Option>
                      <Select.Option value={1}>Không hoạt động</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <div className="search-box-button">
                <Button
                  htmlType="button"
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  Xóa điều kiện
                </Button>
                <Button htmlType="submit" type="primary">
                  Tìm kiếm
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </Form>
      </div>
      <GroupUserList form={form} searchParam={searchParam} setSearchParam={setSearchParam}/>
    </>
  );
};

export default GroupUser;
